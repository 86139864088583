/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { Box, CircularProgress } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { Status } from '../../api/enumerations';
import { GetWorkOrder } from '../../api/workOrders';
import { WorkOrderData } from '../../api/workOrders/types';
import { AllAttachments } from '../../components/AllAttachments';
import ConfirmationDeleteDialog from '../../components/ConfirmationDeleteDialog';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { MessagesDialog } from '../../components/Messages';
import { AccordionTitle } from '../../components/Sections/AccordionTitle';
import { ClientData } from '../../components/Sections/ClientData';
import { PropertyAddressWithMaps } from '../../components/Sections/PropertyAddressWithMaps';
import { PropertyData } from '../../components/Sections/PropertyData';
import { PropertyInfo } from '../../components/Sections/PropertyInfo';
import { Title } from '../../components/Sections/Title';
import { ToogleAccordion } from '../../components/Sections/ToogleAccordion';
import Snackbar from '../../components/Snackbar';
import {
  BoxContainer,
  CancelOsBox,
  FlexSpaceBetweenBox,
  LoadingBox,
} from '../../components/UI/Box';
import { GridContainer } from '../../components/UI/Grid';
import {
  IconApartmentMS,
  IconArrowCircleLeftMS,
  IconEditNoteMS,
  IconLocationCityMS,
  IconLocationOnMS,
} from '../../constants/icons';
import { Constants } from '../../constants/pept';
import { GlobalContext } from '../../context/global';
import { useAccordion } from '../../hooks/useAccordion';
import { useCancelWorkOrder } from '../../hooks/useCancelWorkOrder';
import { useChangeStatus } from '../../hooks/useChangeStatus';
import useErrorMessage from '../../hooks/useErrorMessage';
import useGeneral from '../../hooks/useGeneral';
import {
  BackButton,
  PeptText,
  PeptTitle,
  SectionBox,
  SubmitBox,
} from './styles';

export function Pept(): JSX.Element {
  const [propertyData, setPropertyData] = useState<WorkOrderData>();
  const [loadingPage, setLoadingPage] = useState(true);

  const isBank = process.env.REACT_APP_IS_BANK === 'true';

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { handleCancelWorkOrder } = useCancelWorkOrder();
  const { getErrorMessage } = useErrorMessage();
  const { osId, navigateHome } = useGeneral();
  const { handleStatus, loadingApprove } = useChangeStatus();
  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandAll,
    setExpandAll,
    toggleAccordions,
  } = useAccordion();

  useEffect(() => {
    if (expandOne && expandTwo && expandThree) {
      setExpandAll(true);
    } else {
      setExpandAll(false);
    }
  }, [expandOne, expandTwo, expandThree]);

  const getDataCallback = useCallback(async () => {
    try {
      const response = await GetWorkOrder(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data || response.data.status !== Status.PEPT) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setPropertyData(response.data);
      setLoadingPage(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error, true));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, []);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  return (
    <GridContainer>
      <BackButton onClick={navigateHome}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer>
        <Title
          osNumber={propertyData?.reference_number || 0}
          title={Constants.pept}
          createdAt={propertyData?.created_at}
        />
        {loadingPage ? (
          <LoadingBox>
            <CircularProgress />
          </LoadingBox>
        ) : (
          <>
            <FlexSpaceBetweenBox>
              <CancelOsBox>
                <ConfirmationDeleteDialog
                  iconCancel
                  title={Constants.cancelOs}
                  text={Constants.cancelOsText}
                  modalCallback={handleCancelWorkOrder}
                />
              </CancelOsBox>
              <Box display="flex" gap={1.2}>
                {isBank && propertyData?.worker_company_id !== null && (
                  <MessagesDialog
                    osId={osId}
                    hiveRequest={propertyData?.hive_request || undefined}
                  />
                )}
                <AllAttachments propertyData={propertyData} osId={osId} />
              </Box>
            </FlexSpaceBetweenBox>
            <Box>
              <PeptTitle>
                {IconEditNoteMS}
                {Constants.reason}
              </PeptTitle>
              <PeptText>
                {propertyData?.observation && propertyData.observation}
              </PeptText>
            </Box>
            <ClientData propertyData={propertyData} />
            <Box>
              <ToogleAccordion expand={expandAll} toogle={toggleAccordions} />
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyData}
                  icon={IconApartmentMS}
                  openAccordion={expandOne}
                  setOpenAccordion={setExpandOne}
                />
                {expandOne && <PropertyData propertyData={propertyData} />}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyAddress}
                  icon={IconLocationOnMS}
                  openAccordion={expandTwo}
                  setOpenAccordion={setExpandTwo}
                />
                {expandTwo && (
                  <PropertyAddressWithMaps propertyData={propertyData} />
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyDetails}
                  icon={IconLocationCityMS}
                  openAccordion={expandThree}
                  setOpenAccordion={setExpandThree}
                />
                {expandThree && <PropertyInfo propertyData={propertyData} />}
              </SectionBox>
              <SubmitBox>
                <ConfirmationDialog
                  title={Constants.approve}
                  loading={loadingApprove}
                  text={Constants.changeStatus.replace(
                    '**',
                    `${propertyData?.reference_number}`
                  )}
                  modalCallback={() =>
                    handleStatus(osId, propertyData?.reference_number)
                  }
                  pept
                />
              </SubmitBox>
            </Box>
          </>
        )}
      </BoxContainer>
      {openSnackbar && <Snackbar />}
    </GridContainer>
  );
}
