import { CircularProgress } from '@mui/material';
import { useContext, useState } from 'react';

import { GlobalContext } from '../../../context/global';
import {
  AlertTypography,
  ButtonBox,
  DialogButton,
  DialogContainer,
  FilledButton,
  OutlinedButton,
  StyledDialog,
  TextArea,
  TextTypography,
} from './styles';

interface ObservationDialogProps {
  modalCallback: (obs: string) => Promise<void>;
  loading: boolean;
}

export function ObservationDialog({
  modalCallback,
  loading,
}: ObservationDialogProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const [observation, setObservation] = useState('');

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => {
    setObservation('');
    setOpen(false);
  };

  const handleConfirm = (): void => {
    if (!observation) {
      setSnackbarMessage('Adicione uma observação');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }
    modalCallback(observation);
    handleClose();
  };

  return (
    <>
      <DialogButton onClick={handleOpen} disabled={loading}>
        {loading ? (
          <CircularProgress color="error" size={22} />
        ) : (
          'Rejeitar finalização'
        )}
      </DialogButton>
      <StyledDialog open={open} onClose={handleClose}>
        <DialogContainer>
          <TextTypography>Observações:</TextTypography>
          <TextArea
            value={observation}
            onChange={(e) => setObservation(e.target.value)}
          />
          <ButtonBox>
            <OutlinedButton onClick={handleClose}>Cancelar</OutlinedButton>
            <FilledButton onClick={handleConfirm}>Rejeitar</FilledButton>
          </ButtonBox>
          <AlertTypography>
            Atenção! Esta ação não poderá ser desfeita
          </AlertTypography>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
