import { Grid } from '@mui/material';

import { StyledTextField } from '../styles';

interface FinalRemarkstProps {
  inputs: string;
  readOnly: boolean;
}

export function FinalRemarks({
  inputs,
  readOnly,
}: FinalRemarkstProps): JSX.Element {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StyledTextField label="" disabled={readOnly} value={inputs} />
      </Grid>
    </Grid>
  );
}
