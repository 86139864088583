/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress } from '@mui/material';
import CharacterCount from '@tiptap/extension-character-count';
import Color from '@tiptap/extension-color';
import Placeholder from '@tiptap/extension-placeholder';
import TextStyle from '@tiptap/extension-text-style';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useCallback, useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { StatusCode, WebsocketTypes } from '../../api/enumerations';
import {
  getUnreadMessagesCount,
  getWorkOrderMessages,
  sendWorkOrderMessage,
  updateMessagesAsRead,
} from '../../api/theHive/workOrders';
import { MessageData } from '../../api/theHive/workOrders/types';
import { HiveRequest } from '../../api/workOrders/types';
import { IconCloseMS, IconMessageMS } from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import { WebsocketContext } from '../../context/websocketMessage';
import { useStoragedJwt } from '../../hooks/useDecodedJwt';
import useErrorMessage from '../../hooks/useErrorMessage';
import useGeneral from '../../hooks/useGeneral';
import { DialogTitle } from '../UI/Typography';
import { Constants } from './Constants';
import { MessageField } from './MessageField';
import { MessageReader } from './MessageReader';
import {
  Background,
  ButtonContainer,
  CloseIcon,
  DialogContainer,
  MessageBox,
  MessagesContainer,
  MessagesNumber,
  OpenButton,
  SenderTitle,
  StyledDate,
  StyledDialog,
} from './styles';

interface MessagesDialogProps {
  osId: number;
  hiveRequest?: HiveRequest | undefined;
}

export function MessagesDialog({
  osId,
  hiveRequest,
}: MessagesDialogProps): JSX.Element {
  const [reload, setReload] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const [messagesData, setMessagesData] = useState<MessageData[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [workerCompanyName, setWorkerCompanyName] = useState<
    string | undefined
  >('');

  const messagesPerPage = 5;

  const dialog = useGeneral();
  const decoded = useStoragedJwt();
  const { getErrorMessage } = useErrorMessage();
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { websocketMessage } = useContext(WebsocketContext);

  const editorEditable = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: 'Digite uma mensagem',
      }),
      CharacterCount.configure({
        limit: 500,
      }),
      TextStyle,
      Color,
    ],
    content: '',
    editable: true,
  });

  const resetData = (): void => {
    setFirstLoading(true);
    setPage(1);
    setLastCalledPage(0);
    setMessagesData([]);
  };

  const getUnreadCount = useCallback(async () => {
    try {
      const response = await getUnreadMessagesCount(osId);
      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data) {
        setUnreadCount(response.data.count);
        setReload(true);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [osId]);

  useEffect(() => {
    getUnreadCount();
  }, [getUnreadCount]);

  useEffect(() => {
    if (websocketMessage) {
      const messageData = JSON.parse(websocketMessage.data);
      if (
        messageData.type === WebsocketTypes.NEW_MESSAGE &&
        messageData.payload.work_order_id === osId &&
        messageData.payload.unread_messages_count > 0
      ) {
        getUnreadCount();
      }
    }
  }, [websocketMessage, osId]);

  const updateUnreadMessages = useCallback(async () => {
    try {
      const response = await updateMessagesAsRead(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }
      setUnreadCount(0);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [osId]);

  useEffect(() => {
    if (dialog.open && unreadCount > 0) {
      updateUnreadMessages();
    }
  }, [dialog.open, unreadCount]);

  const getRequestsCallback = useCallback(async () => {
    if (page === lastCalledPage) {
      setFirstLoading(false);
      setLoadingMore(false);
      setReload(false);
      return;
    }

    try {
      setLoadingMore(true);
      const response = await getWorkOrderMessages(osId, page, messagesPerPage);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setTotalPages(response.detail.total_pages);
      }

      if (response.data) {
        setWorkerCompanyName(response.data.work_order.worker_company?.name);
        setMessagesData([...messagesData, ...response.data.messages]);
        setLastCalledPage(page);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setLoadingMore(false);
      setFirstLoading(false);
      setReload(false);
    }
  }, [page]);

  useEffect(() => {
    if (page !== 1) {
      getRequestsCallback();
    }
  }, [page]);

  useEffect(() => {
    if (reload) {
      resetData();
      getRequestsCallback();
    }
  }, [reload]);

  const formatDateAndTime = (date: string): string => {
    dayjs.extend(utc);
    dayjs.extend(tz);
    dayjs.locale('pt-br');

    const dateTime = dayjs.utc(date).tz('America/Sao_Paulo');
    const formattedDateTime = dateTime.format('DD [de] MMMM [às] HH:mm');

    return formattedDateTime;
  };

  const handleMessage = (): {
    rawText: string | undefined;
    richText: string;
  } => {
    const jsonText = editorEditable?.getJSON();
    const richText = JSON.stringify(jsonText);
    const rawText = editorEditable?.getText();
    return {
      rawText,
      richText,
    };
  };

  const handleSubmitMessage = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setLoadingSubmit(true);

      const messageText = handleMessage();

      if (!messageText.rawText) {
        setLoadingSubmit(false);
        setSnackbarMessage('Mensagem inválida');
        setOpenSnackbar(true);
        setErrorMessage(true);
        return;
      }

      const data = {
        message: messageText.richText,
        raw_content: messageText.rawText,
      };

      try {
        const response = await sendWorkOrderMessage(osId, data);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setSnackbarMessage('Mensagem enviada com sucesso!');
        setErrorMessage(false);
        setOpenSnackbar(true);
        editorEditable?.commands.setContent('');
        resetData();
        getRequestsCallback();
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      } finally {
        setLoadingSubmit(false);
      }
    },
    [editorEditable]
  );

  const closeDialog = (): void => {
    dialog.handleClose();
    editorEditable?.commands.setContent('');
  };

  return (
    <>
      <ButtonContainer>
        <OpenButton disableTouchRipple onClick={dialog.handleOpen}>
          {IconMessageMS}
          {Constants.messages}
        </OpenButton>
        {unreadCount > 0 && <MessagesNumber>{unreadCount}</MessagesNumber>}
      </ButtonContainer>

      <StyledDialog open={dialog.open} onClose={closeDialog}>
        <DialogContainer>
          <DialogTitle> {IconMessageMS} Mensagens</DialogTitle>
          <CloseIcon onClick={closeDialog}>{IconCloseMS}</CloseIcon>
          <MessagesContainer id="scrollableDiv">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height={400}
            >
              {firstLoading && messagesData.length === 0 && (
                <CircularProgress size={22} />
              )}
            </Box>
            <InfiniteScroll
              scrollableTarget="scrollableDiv"
              dataLength={messagesData.length}
              next={() => setPage((prev) => prev + 1)}
              hasMore={page < totalPages}
              inverse
              style={{
                display: 'flex',
                flexDirection: 'column-reverse',
                overflowY: 'hidden',
                gap: 12,
              }}
              loader={
                <Box textAlign="center">
                  {loadingMore && <CircularProgress size={22} />}
                </Box>
              }
            >
              {messagesData.map((message) => {
                const messageFromAccredited =
                  message.sender.company !== decoded?.user?.companies[0].name;
                return (
                  <MessageBox
                    key={message.id}
                    alignItems={messageFromAccredited ? 'start' : 'end'}
                  >
                    <Background>
                      <StyledDate
                        textAlign={messageFromAccredited ? 'start' : 'end'}
                      >
                        {formatDateAndTime(message.created_at)}
                      </StyledDate>
                      <SenderTitle
                        textAlign={messageFromAccredited ? 'start' : 'end'}
                      >
                        {message.sender.user !== null
                          ? message.sender.user
                          : ''}
                      </SenderTitle>
                      <MessageReader
                        messageRichText={message.message}
                        messageRawContent={message.raw_content}
                      />
                    </Background>
                  </MessageBox>
                );
              })}
              {hiveRequest?.observations &&
                hiveRequest.acceptance_status === 'rejected' && (
                  <MessageBox alignItems="start">
                    <Background>
                      <StyledDate alignItems="start">
                        {hiveRequest?.created_at &&
                          formatDateAndTime(hiveRequest?.created_at)}
                      </StyledDate>
                      <SenderTitle alignItems="start">
                        {workerCompanyName}
                      </SenderTitle>
                      <MessageReader
                        messageObservation={hiveRequest?.observations}
                      />
                    </Background>
                  </MessageBox>
                )}
            </InfiniteScroll>
          </MessagesContainer>
          <MessageField
            handleSubmit={handleSubmitMessage}
            editor={editorEditable}
            loadingSubmit={loadingSubmit}
          />
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
