import { useCallback, useState } from 'react';

import { StatisticalReportData } from '../../../api/theHive/statisticalReport/types';

export type ReportCompletionState = {
  [K in keyof StatisticalReportData]-?: boolean;
} & {
  attachments: boolean;
  images: boolean;
};

const initialReportCompletion: ReportCompletionState = {
  evaluation_methodology: false,
  variables: false,
  evaluation_summary: false,
  evaluation_result: false,
  attachments: false,
  images: false,
  appraisal_information: false,
  appraisal_microregion_information: false,
  land_information: false,
  condominium_information: false,
  construction_information: false,
  improvements_information: false,
  market_diagnosis: false,
  general_conditions: false,
  inspection_information: false,
  warranty_statements: false,
  independent_unities_complementary_information: false,
  observations: false,
};

interface ReportCompletionHook {
  reportCompletion: ReportCompletionState;
  toggleCompletion: (
    section: keyof ReportCompletionState,
    value?: boolean
  ) => void;
}

export function useReportCompletion(): ReportCompletionHook {
  const [reportCompletion, setReportCompletion] = useState(
    initialReportCompletion
  );

  const toggleCompletion = useCallback(
    (section: keyof ReportCompletionState, value?: boolean) => {
      setReportCompletion((prevState) => ({
        ...prevState,
        [section]: value !== undefined ? value : !prevState[section],
      }));
    },
    []
  );

  return {
    reportCompletion,
    toggleCompletion,
  };
}
