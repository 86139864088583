/* eslint-disable react/require-default-props */
import { CircularProgress, Grid } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getInspectionPictures } from '../../../api/inspections';
import { GalleryPictureData } from '../../../api/pictures/types';
import { FlexSpaceBetweenBox } from '../../../components/UI/Box';
import {
  IconAddPhotoAlternateMS,
  IconCloseMS,
  IconDownloadMS,
} from '../../../constants/icons';
import { Constants } from '../../../constants/report';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useGeneral from '../../../hooks/useGeneral';
import {
  StyledCardMedia,
  SelectPhotoBox,
  UploadIcon,
  UploadSubtitle,
  FlexReverseBox,
  DeleteCircleButton,
  StyledTypography,
  OutlineButton,
} from './styles';

type InspectionSelectedPhotosProps = {
  selectedPhotos: GalleryPictureData[];
  inspectionId: number;
  removeSelection?: (pictureId: number) => Promise<void>;
};

export function InspectionSelectedPhotos({
  selectedPhotos,
  inspectionId,
  removeSelection,
}: InspectionSelectedPhotosProps): JSX.Element {
  const [downloading, setDownloading] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();
  const { osId, handleDownloadFile } = useGeneral();
  const navigate = useNavigate();

  const isBank = process.env.REACT_APP_IS_BANK === 'true';
  const selectionLimit = 12;

  const navigateInspectionsPhotosGallery = (): void => {
    navigate(`/home/property/${osId}/report/${inspectionId}/inspection-photos`);
  };

  const handleDownloadPictures = async (): Promise<void> => {
    setDownloading(true);
    try {
      const response = await getInspectionPictures(inspectionId);

      if (response.ok) {
        const contentDisposition = response.headers
          .get('Content-Disposition')
          ?.split('filename=')[1];
        const filename = contentDisposition || 'file.zip';
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          handleDownloadFile(url, filename);
        });
        setDownloading(false);
      } else {
        throw new Error('Não foi possível fazer o download, tente novamente.');
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setDownloading(false);
    }
  };

  return (
    <>
      <FlexSpaceBetweenBox alignItems="center" mb="20px">
        <StyledTypography>
          {selectedPhotos.length}/{selectionLimit}
          {Constants.selectedImages}
        </StyledTypography>
        <OutlineButton onClick={handleDownloadPictures} disabled={downloading}>
          {downloading ? <CircularProgress size={22} /> : IconDownloadMS}
          {Constants.downloadImages}
        </OutlineButton>
      </FlexSpaceBetweenBox>
      <Grid container spacing={2}>
        {selectedPhotos.map((photo) => (
          <Grid item xs={2} key={photo.id}>
            {!isBank && removeSelection && (
              <FlexReverseBox>
                <DeleteCircleButton onClick={() => removeSelection(photo.id)}>
                  {IconCloseMS}
                </DeleteCircleButton>
              </FlexReverseBox>
            )}
            <StyledCardMedia image={photo.file} />
          </Grid>
        ))}
        {!isBank && selectedPhotos.length < selectionLimit && (
          <Grid item xs={2}>
            <SelectPhotoBox onClick={navigateInspectionsPhotosGallery}>
              <UploadIcon>{IconAddPhotoAlternateMS}</UploadIcon>
              <UploadSubtitle>{Constants.selectFromGallery}</UploadSubtitle>
            </SelectPhotoBox>
          </Grid>
        )}
      </Grid>
    </>
  );
}
