/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, ReactNode, useState } from 'react';

interface WebsocketMessageProps {
  websocketMessage: MessageEvent<any> | undefined;
  setWebsocketMessage: (message: MessageEvent<any> | undefined) => void;
}

export const WebsocketContext = createContext({} as WebsocketMessageProps);

interface WebsocketProviderProps {
  children: ReactNode;
}

export function WebsocketProvider({
  children,
}: WebsocketProviderProps): JSX.Element {
  const [websocketMessage, setWebsocketMessage] = useState<MessageEvent<any>>();

  return (
    <WebsocketContext.Provider
      value={{
        websocketMessage,
        setWebsocketMessage,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
}
