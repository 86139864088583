import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { PropertyType, Status, StatusCode } from '../../api/enumerations';
import {
  QuestionFormData,
  QuestionResponseData,
} from '../../api/inspections/types';
import { RoomData } from '../../api/rooms/types';
import { editWorkOrder, GetWorkOrder } from '../../api/workOrders';
import { WorkOrderData } from '../../api/workOrders/types';
import { GlobalContext } from '../../context/global';
import { validateFiles } from '../../helpers';
import { useAttachment } from '../../hooks/useAttachmentFiles';
import { useChangeStatus } from '../../hooks/useChangeStatus';
import useErrorMessage from '../../hooks/useErrorMessage';
import useGeneral from '../../hooks/useGeneral';
import { useRooms } from '../../hooks/useRooms';
import useSearchCep from '../../hooks/useSearchCep';

type LatLngLiteral = google.maps.LatLngLiteral;

interface UseInspectionHook {
  handleProgress: (questions: QuestionFormData[]) => void;
  handleSubmit: (e: React.FormEvent) => Promise<void>;
  verifyCep: (cep: string) => Promise<void>;
  handleFileUpload: (
    e: ChangeEvent<HTMLInputElement>,
    value: string
  ) => Promise<void>;
  address: string;
  setAddress: (value: string) => void;
  addressNumber: string;
  setAddressNumber: (value: string) => void;
  age: number;
  setAge: (value: number) => void;
  buildingStandard: number;
  setBuildingStandard: (value: number) => void;
  builtArea: number;
  setBuiltArea: (value: number) => void;
  cep: string;
  city: string;
  setCity: (value: string) => void;
  client: string;
  setClient: (value: string) => void;
  complement: string;
  setComplement: (value: string) => void;
  concept: number;
  setConcept: (value: number) => void;
  conservation: number;
  setConservation: (value: number) => void;
  district: string;
  setDistrict: (value: string) => void;
  goal: number;
  setGoal: (value: number) => void;
  judicialDistrict: string;
  setJudicialDistrict: (value: string) => void;
  parkingLots: number;
  setParkingLots: (value: number) => void;
  propertyFunction: number;
  setPropertyFunction: (value: number) => void;
  propertyUse: number;
  setPropertyUse: (value: number) => void;
  registerNumber: string;
  setRegisterNumber: (value: string) => void;
  registrationUf: number;
  setRegistrationUf: (value: number) => void;
  propertyRooms: number;
  setPropertyRooms: (value: number) => void;
  rooms: RoomData[];
  solicitor: string;
  setSolicitor: (value: string) => void;
  suites: number;
  setSuites: (value: number) => void;
  toilets: number;
  setToilets: (value: number) => void;
  totalArea: number;
  setTotalArea: (value: number) => void;
  uf: string;
  setUf: (value: string) => void;
  zone: number;
  setZone: (value: number) => void;
  propertyData: WorkOrderData | undefined;
  registerFileName: string;
  iptuFileName: string;
  searchMap: boolean;
  setSearchMap: (value: boolean) => void;
  setIsAddressEdited: (value: boolean) => void;
  pinPlace: LatLngLiteral;
  setPinPlace: (value: LatLngLiteral) => void;
  questionForm: QuestionResponseData | undefined;
  progressValue: number;
  inspectionId: number;
  openAddFacadeModal: boolean;
  setOpenAddFacadeModal: (value: boolean) => void;
  enableEditing: boolean;
  setEnableEditing: (value: boolean) => void;
  handleCheckLocation(): Promise<void>;
  checkLocationDate: string | undefined;
  handleApprove: () => void;
  loadingPage: boolean;
  submitLoading: boolean;
  downloadIptu: string;
  downloadRegister: string;
  getDataCallback: () => Promise<void>;
}

const useInspection = (): UseInspectionHook => {
  const [propertyData, setPropertyData] = useState<WorkOrderData>();
  const [questionForm, setQuestionForm] = useState<QuestionResponseData>();
  const [progressValue, setProgressValue] = useState(0);
  const [inspectionId, setInspectionId] = useState(0);
  const [downloadIptu, setDownloadIptu] = useState('');
  const [downloadRegister, setDownloadRegister] = useState('');
  const [checkLocationDate, setCheckLocationDate] = useState<
    string | undefined
  >(undefined);
  const [loadingPage, setLoadingPage] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isAddressEdited, setIsAddressEdited] = useState(false);

  // Client data
  const [goal, setGoal] = useState(0);
  const [solicitor, setSolicitor] = useState('');
  const [propertyUse, setPropertyUse] = useState(0);
  const [propertyFunction, setPropertyFunction] = useState(0);
  const [client, setClient] = useState('');
  const [openAddFacadeModal, setOpenAddFacadeModal] = useState(false);
  const [enableEditing, setEnableEditing] = useState(false);

  // Property data
  const [registerFileName, setRegisterFileName] = useState('');
  const [iptuFileName, setIptuFileName] = useState('');
  const [iptuFile, setIptuFile] = useState<File | string>();
  const [registerFile, setRegisterFile] = useState<File | string>();
  const [registerNumber, setRegisterNumber] = useState('');
  const [concept, setConcept] = useState(0);
  const [zone, setZone] = useState(0);
  const [judicialDistrict, setJudicialDistrict] = useState('');
  const [registrationUf, setRegistrationUf] = useState(0);

  // Property address
  const [address, setAddress] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [cep, setCep] = useState('');
  const [city, setCity] = useState('');
  const [complement, setComplement] = useState('');
  const [district, setDistrict] = useState('');
  const [uf, setUf] = useState('');
  const [searchMap, setSearchMap] = useState(true);
  const [pinPlace, setPinPlace] = useState<LatLngLiteral>({
    lat: -23.56162,
    lng: -46.65591,
  });

  // Property informations
  const [age, setAge] = useState(0);
  const [buildingStandard, setBuildingStandard] = useState(0);
  const [builtArea, setBuiltArea] = useState(0);
  const [conservation, setConservation] = useState(0);
  const [parkingLots, setParkingLots] = useState(0);
  const [propertyRooms, setPropertyRooms] = useState(0);
  const [suites, setSuites] = useState(0);
  const [toilets, setToilets] = useState(0);
  const [totalArea, setTotalArea] = useState(0);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { iptuLink, setIptuLink, registerLink, setRegisterLink } =
    useAttachment();
  const { getErrorMessage } = useErrorMessage();
  const { osId } = useGeneral();
  const { handleRooms, rooms } = useRooms();
  const { handleSearchCep } = useSearchCep();
  const { handleStatus } = useChangeStatus();

  const handleProgress = (questions: QuestionFormData[]): void => {
    const totalQuestions = questions.length;

    const answeredOptions = questions
      .filter((data) => data.answer)
      .map((data) => data.answer);

    const progress = (answeredOptions.length * 100) / totalQuestions;

    setProgressValue(progress);
  };

  const getDataCallback = useCallback(async () => {
    try {
      const response = await GetWorkOrder(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data || response.data.status !== Status.INSPECTION) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setPropertyData(response.data);
      setLoadingPage(false);

      if (response.data.inspection) {
        setInspectionId(response.data.inspection.id);
        setQuestionForm(response.data.inspection.form_response);
        handleProgress(response.data.inspection.form_response.json);
        handleRooms(response.data.inspection.id);

        setGoal(response.data.report_goal);
        setSolicitor(response.data.requester_name);
        setPropertyFunction(response.data.report_function);
        setPropertyUse(response.data.use);
        setClient(response.data.client_name);

        setRegisterNumber(response.data.registration_number);
        setConcept(response.data.communities_kind);
        setZone(response.data.zone);
        setJudicialDistrict(response.data.judicial_district);
        setRegistrationUf(response.data.uf);
        setDownloadIptu(response.data.iptu_document || '');
        setDownloadRegister(response.data.real_estate_registry || '');

        setAddress(response.data.street);
        setAddressNumber(response.data.number);
        setCep(response.data.zip_code);
        setCity(response.data.city);
        setComplement(response.data.address_complement);
        setDistrict(response.data.district);
        setUf(response.data.state);
        if (response.data.latitude && response.data.longitude) {
          setPinPlace({
            lat: Number(response.data.latitude),
            lng: Number(response.data.longitude),
          });
        }

        setAge(response.data.age || 0);
        setBuildingStandard(response.data.constructive_standard || 0);
        setBuiltArea(response.data.constructed_area || 0);
        setConservation(response.data.preservation_state || 0);
        setParkingLots(response.data.parking_spaces || 0);
        setPropertyRooms(response.data.bedrooms || 0);
        setSuites(response.data.suites || 0);
        setToilets(response.data.bathrooms || 0);
        setTotalArea(response.data.total_area);
        setCheckLocationDate(undefined);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error, true));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iptuLink, registerLink]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback, iptuLink, registerLink]);

  const handleCheckLocation = async (): Promise<void> => {
    const updateCoordinate = true;
    const formData = new FormData();
    formData.append('latitude', pinPlace.lat.toString());
    formData.append('longitude', pinPlace.lng.toString());

    try {
      const response = await editWorkOrder(osId, formData, updateCoordinate);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Localização confirmada com sucesso!');
      setCheckLocationDate(new Date().toISOString());
      setErrorMessage(false);
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const verifyCep = async (cep: string): Promise<void> => {
    const addressData = await handleSearchCep(cep);
    if (addressData) {
      setCep(addressData.cep);
      setUf(addressData.uf);
      setCity(addressData.city);
      setDistrict(addressData.district);
      setAddress(addressData.address);
    }
    setIsAddressEdited(true);
  };

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>,
    fileType: string
  ): Promise<void> => {
    if (!e.target.files?.item(0) || !validateFiles(e.target.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem ou pdf');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const file = e.target.files[0];

    const formData = new FormData();
    if (fileType === 'register') {
      formData.append('real_estate_registry', file);
    }
    if (fileType === 'iptu') {
      formData.append('iptu_document', file);
    }

    try {
      const response = await editWorkOrder(osId, formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Dados alterados com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      if (fileType === 'register') {
        setRegisterLink(file.name);
        setRegisterFileName(file.name);
        setRegisterFile(file);
      }
      if (fileType === 'iptu') {
        setIptuLink(file.name);
        setIptuFileName(file.name);
        setIptuFile(file);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    if (!cep) {
      setSnackbarMessage('Adicione a localização do imóvel');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (isAddressEdited) {
      setSnackbarMessage('Pesquise a localização no mapa antes de salvar.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (
      propertyData?.real_estate_kind !== PropertyType.LOT &&
      (buildingStandard === 0 || conservation === 0)
    ) {
      setSnackbarMessage('Os dados de seleção são obrigatórios.');
      setOpenSnackbar(true);
      setErrorMessage(true);
    }

    if (
      concept === 0 ||
      goal === 0 ||
      propertyFunction === 0 ||
      propertyUse === 0 ||
      registrationUf === 0
    ) {
      setSnackbarMessage('Os dados de seleção são obrigatórios');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const formData = new FormData();
    formData.append('street', address);
    formData.append('number', addressNumber);
    formData.append('zip_code', cep);
    formData.append('city', city);
    formData.append('client_name', client);
    formData.append('address_complement', complement);
    formData.append('communities_kind', concept.toString());
    formData.append('district', district);
    formData.append('report_goal', goal.toString());
    formData.append('judicial_district', judicialDistrict);
    formData.append('report_function', propertyFunction.toString());
    formData.append('use', propertyUse.toString());
    formData.append('registration_number', registerNumber);
    formData.append('requester_name', solicitor);
    formData.append('uf', registrationUf.toString());
    formData.append('total_area', totalArea.toString());
    formData.append('state', uf);
    formData.append('zone', zone.toString());
    formData.append('latitude', pinPlace.lat.toString());
    formData.append('longitude', pinPlace.lng.toString());

    if (propertyData?.real_estate_kind !== PropertyType.LOT) {
      formData.append('constructed_area', builtArea.toString());
      formData.append('bedrooms', propertyRooms.toString());
      formData.append('bathrooms', toilets.toString());
      formData.append('suites', suites.toString());
      formData.append('parking_spaces', parkingLots.toString());
      formData.append('age', age.toString());
      formData.append('constructive_standard', buildingStandard.toString());
      formData.append('preservation_state', conservation.toString());
    }

    if (registerFile) {
      formData.append('real_estate_registry', registerFile);
    }
    if (iptuFile) {
      formData.append('iptu_document', iptuFile);
    }

    try {
      setSubmitLoading(true);
      const response = await editWorkOrder(osId, formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Dados alterados com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setEnableEditing(false);
      setSubmitLoading(false);
      getDataCallback();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setSubmitLoading(false);
    }
  };

  const checkRequiredQuestions = (): boolean => {
    const requiredQuestions =
      questionForm?.json?.filter((question) => question.required === true) ||
      [];
    if (requiredQuestions && requiredQuestions.length > 0) {
      return requiredQuestions.some(
        (question) => !question.answer || question.answer === null
      );
    }
    return false;
  };

  const handleApprove = (): void => {
    const pendingRequiredQuestions = checkRequiredQuestions();

    if (!pendingRequiredQuestions) {
      handleStatus(osId, propertyData?.reference_number);
    } else {
      setSnackbarMessage(
        'Responda todas as perguntas obrigatórias antes de prosseguir.'
      );
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  };

  return {
    handleProgress,
    handleSubmit,
    verifyCep,
    handleFileUpload,
    address,
    setAddress,
    addressNumber,
    setAddressNumber,
    age,
    setAge,
    buildingStandard,
    setBuildingStandard,
    builtArea,
    setBuiltArea,
    cep,
    city,
    setCity,
    client,
    setClient,
    complement,
    setComplement,
    concept,
    setConcept,
    conservation,
    setConservation,
    district,
    setDistrict,
    goal,
    setGoal,
    judicialDistrict,
    setJudicialDistrict,
    parkingLots,
    setParkingLots,
    propertyFunction,
    setPropertyFunction,
    propertyUse,
    setPropertyUse,
    registerNumber,
    setRegisterNumber,
    registrationUf,
    setRegistrationUf,
    propertyRooms,
    setPropertyRooms,
    rooms,
    solicitor,
    setSolicitor,
    suites,
    setSuites,
    toilets,
    setToilets,
    totalArea,
    setTotalArea,
    uf,
    setUf,
    zone,
    setZone,
    propertyData,
    registerFileName,
    iptuFileName,
    searchMap,
    setSearchMap,
    setIsAddressEdited,
    pinPlace,
    setPinPlace,
    questionForm,
    progressValue,
    inspectionId,
    openAddFacadeModal,
    setOpenAddFacadeModal,
    enableEditing,
    setEnableEditing,
    handleCheckLocation,
    checkLocationDate,
    handleApprove,
    loadingPage,
    submitLoading,
    downloadIptu,
    downloadRegister,
    getDataCallback,
  };
};

export default useInspection;
