import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { ParkingHouseData } from '../../../../../../api/theHive/statisticalReport/types';
import { StyledTableCellBody, TableTextField } from '../../../styles';

interface TableHouseParkingProps {
  inputs: ParkingHouseData;
  readOnly: boolean;
}

export function TableHouseParking({
  inputs,
  readOnly,
}: TableHouseParkingProps): JSX.Element {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            sx={{ backgroundColor: '#F4EEF6', border: '1px solid #ccc' }}
          >
            <TableCell align="center" colSpan={3}>
              total de vagas vinculadas à matrícula do imóvel
            </TableCell>
          </TableRow>
          <TableRow
            sx={{ backgroundColor: '#E9E1ED', border: '1px solid #ccc' }}
          >
            <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
              cobertas
            </TableCell>
            <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
              descobertas
            </TableCell>
            <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
              total
            </TableCell>
          </TableRow>
          <TableRow>
            <StyledTableCellBody>
              <TableTextField
                type="number"
                value={inputs.covered}
                disabled={readOnly}
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TableTextField
                type="number"
                value={inputs.not_covered}
                disabled={readOnly}
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TableTextField type="number" value={inputs.total} disabled />
            </StyledTableCellBody>
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
}
