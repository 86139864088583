import { Box, Grid } from '@mui/material';

import { StatisticalReportAttachmentData } from '../../../../api/theHive/statisticalReport/types';
import { IconAttachFileMS, IconDownloadMS } from '../../../../constants/icons';
import useGeneral from '../../../../hooks/useGeneral';
import {
  DateTypography,
  FileContainer,
  FileNameTypography,
  StyledIcon,
} from '../../../AllAttachments/styles';

interface AttachmentsProps {
  files: StatisticalReportAttachmentData[];
}

export function Attachments({ files }: AttachmentsProps): JSX.Element {
  const { handleDownloadFile } = useGeneral();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {files.length > 0
          ? files.map((file) => (
              <FileContainer container key={file.id}>
                <Box>
                  <FileNameTypography>
                    {IconAttachFileMS}
                    {file.filename}
                  </FileNameTypography>
                  <DateTypography sx={{ paddingLeft: '21px' }}>
                    {file.description}
                  </DateTypography>
                </Box>
                <Box>
                  <StyledIcon
                    onClick={() => handleDownloadFile(file.url, file.filename)}
                  >
                    {IconDownloadMS}
                  </StyledIcon>
                </Box>
              </FileContainer>
            ))
          : 'Nenhum anexo salvo'}
      </Grid>
    </Grid>
  );
}
