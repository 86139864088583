import { Box, Divider, Grid, Rating, Typography } from '@mui/material';

import { LicensedCompanyByDoc } from '../../../../../api/theHive/licensedCompanies/types';
import {
  formatCep,
  formatCnpjCpf,
  formatOnlyDate,
  formatPhoneNumber,
} from '../../../../../helpers';
import {
  GridSection,
  SectionTitle,
  StyledInput,
  GridItem,
  LicenseButton,
  LicenseTitle,
} from './styles';

interface CompanyDataProps {
  company: LicensedCompanyByDoc;
  isLicensedCompany: boolean;
  isActive: boolean;
  handleEditCompany: () => Promise<void>;
  handleCompany: () => Promise<void>;
}

export function CompanyData({
  company,
  isLicensedCompany,
  isActive,
  handleEditCompany,
  handleCompany,
}: CompanyDataProps): JSX.Element {
  return (
    <GridSection container m="20px 0">
      <GridItem item xs={6}>
        <SectionTitle>{company.name}</SectionTitle>
      </GridItem>
      <GridItem item xs={2} />
      <Grid item xs={4}>
        {isLicensedCompany ? (
          <Box>
            {isActive ? (
              <LicenseTitle>empresa credenciada</LicenseTitle>
            ) : (
              <LicenseButton onClick={handleEditCompany}>ativar</LicenseButton>
            )}
          </Box>
        ) : (
          <LicenseButton onClick={handleCompany}>
            credenciar empresa
          </LicenseButton>
        )}
      </Grid>
      <GridItem item xs={12}>
        Data do credenciamento:
        <StyledInput>{formatOnlyDate(company.created_at)}</StyledInput>
      </GridItem>
      <GridItem item xs={6} xl={4}>
        CNPJ:
        <StyledInput>{formatCnpjCpf(company.document)}</StyledInput>
      </GridItem>
      <GridItem item xs={6} xl={4}>
        Telefone:
        <StyledInput>{formatPhoneNumber(company.phone_number)}</StyledInput>
      </GridItem>
      <GridItem item xs={6} xl={4}>
        Responsável:
        <StyledInput>{company.responsible_user.name}</StyledInput>
      </GridItem>
      <GridItem item xs={6} xl={4}>
        {company.license_type.toUpperCase()}:
        <StyledInput>{company.license_document}</StyledInput>
      </GridItem>
      <GridItem item xs={6} xl={4}>
        CEP:
        <StyledInput>{formatCep(company.zipcode)}</StyledInput>
      </GridItem>
      <GridItem item xs={6} xl={4}>
        Cidade:
        <StyledInput>{`${company.city} - ${company.uf}`}</StyledInput>
      </GridItem>
      <GridItem item xs={12}>
        Logradouro:
        <StyledInput>{`${company.address}, ${company.address_number}`}</StyledInput>
      </GridItem>
      <GridItem item xs={6}>
        Email:
        <StyledInput>{company.email}</StyledInput>
      </GridItem>
      <GridItem item xs={12}>
        <Divider sx={{ width: '100%' }} />
      </GridItem>
      <GridItem item xs={12} sx={{ alignItems: 'center', mb: '20px' }}>
        <Rating value={4} readOnly size="large" />
        <Typography>4</Typography>
      </GridItem>
    </GridSection>
  );
}
