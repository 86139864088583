import { IconButton, MenuItem, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const OptionIcon = styled(Typography)(() => ({
  marginRight: '10px',
}));

export const OptionCloseIcon = styled(IconButton)(() => ({
  height: '40px',
  marginLeft: '10px',
}));

export const TitleTextField = styled(TextField)(() => ({
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
}));

export const JumpTextField = styled(TextField)(() => ({
  width: '200px',
  marginLeft: '20px',
  '& .MuiInputBase-root': { borderRadius: '16px' },
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  '&:hover': { backgroundColor: '#00B39B66' },
}));
