import { BaseUrl, myHeadersWithToken } from '../../global';
import { GetLastMessagesResponse } from './types';

export const getLastMessages = (
  page: number,
  size: number
): Promise<GetLastMessagesResponse> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  return fetch(
    `${BaseUrl}/api/V1/hive-messages?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
