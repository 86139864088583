import { Box, Grid, Tooltip } from '@mui/material';

import { EvaluationSummaryData } from '../../../../api/theHive/statisticalReport/types';
import { ReportNumericTextField } from '../../../CustomInput';
import { StyledMenuItem, StyledSelect, StyledTextField } from '../styles';

interface EvaluationSummaryProps {
  inputs: EvaluationSummaryData;
  readOnly: boolean;
}

export function EvaluationSummary({
  inputs,
  readOnly,
}: EvaluationSummaryProps): JSX.Element {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <ReportNumericTextField
          id="atributed-value"
          label="valor atribuído"
          value={inputs.value}
          disable={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <Tooltip
          arrow
          placement="top"
          title="o custo unitário básico da construção foi atribuída como?"
        >
          <StyledTextField
            disabled={readOnly}
            label="o custo unitário básico da construção foi atribuída como?"
            value={inputs.basic_unitary_cost_calculus_reasoning}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          disabled={readOnly}
          select
          label="grau de fundamentação"
          value={String(inputs.reasoning_level)}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="I">I</StyledMenuItem>
          <StyledMenuItem value="II">II</StyledMenuItem>
          <StyledMenuItem value="III">III</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          disabled={readOnly}
          select
          label="o BDI utilizado foi?"
          value={String(inputs.bdi_reasoning)}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="calculado">calculado</StyledMenuItem>
          <StyledMenuItem value="justificado">justificado</StyledMenuItem>
          <StyledMenuItem value="arbitrado">arbitrado</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          disabled={readOnly}
          label="observações sobre o BDI utilizado"
          value={inputs.bdi_reasoning_observations}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          disabled={readOnly}
          label="percentual de BDI utilizado"
          value={inputs.bdi_percentage}
          type="number"
        />
      </Grid>
      <Grid item xs={3}>
        <Tooltip
          arrow
          placement="top"
          title="valor total das benfeitorias depreciadas"
        >
          <Box>
            <ReportNumericTextField
              id="improvement-total-value"
              label="valor total das benfeitorias depreciadas"
              value={inputs.depreciated_improvements_value}
              disable={readOnly}
            />
          </Box>
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <Tooltip arrow placement="top" title="fator de comercialização">
          <StyledTextField
            disabled={readOnly}
            label="fator de comercialização"
            value={inputs.commercialization_factor}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <Tooltip
          arrow
          placement="top"
          title="Resultado da avaliação – Método Evolutivo"
        >
          <Box>
            <ReportNumericTextField
              id="method-evaluation-result"
              disable={readOnly}
              label="Resultado da avaliação – Método Evolutivo"
              value={inputs.evolutionary_method_evaluation_result}
            />
          </Box>
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          disabled={readOnly}
          label="incluir observações relevantes"
          value={inputs.observations}
        />
      </Grid>
    </Grid>
  );
}
