import { Box, Button, Dialog, IconButton, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

export interface ICloseIcon {
  hover?: 'true';
}

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '16px',
    backgroundColor: theme.palette.common.white,
    maxWidth: 'none',
  },
  '& .MuiDialog-paperScrollPaper': {
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.dark,
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '&::-webkit-scrollbar-track': {
      margin: '60px 0',
    },
  },
}));

export const CloseIcon = styled(IconButton)<ICloseIcon>(({ theme, hover }) => ({
  right: '20px',
  top: '20px',
  position: 'absolute',
  '& span': {
    fontSize: '24px',
    fontWeight: '600',
    color: theme.palette.primary.main,
  },
  ...(hover && {
    '& span': {
      color: theme.palette.common.white,
    },
    backgroundColor: '#414141',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
}));

export const ButtonContainer = styled(Box)(() => ({
  position: 'relative',
}));

export const DialogContainer = styled(Box)(() => ({
  padding: '21px 28px',
  width: 600,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  overflow: 'hidden',
}));

export const MessagesContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  height: '500px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
    marginLeft: 8,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.7),
    },
  },
  '@media (max-height: 820px)': {
    height: '400px',
  },
}));

export const MessagesNumber = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: -8,
  right: -8,
  width: 28,
  height: 28,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 30,
  fontSize: 14,
  backgroundColor: 'red',
  color: theme.palette.common.white,
}));

export const OpenButton = styled(Button)(({ theme }) => ({
  cursor: 'pointer',
  width: 210,
  height: 32,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: 10,
  color: theme.palette.primary.main,
  fontWeight: 600,
  lineHeight: '20px',
  backgroundColor: '#FAFCFF',
  display: 'flex',
  gap: 8,
  textTransform: 'lowercase',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1rem',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [theme.breakpoints.down('lg')]: {
    width: '180px',
  },
}));

export const MessageBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginRight: 12,
}));

export const Background = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  width: 'fit-content',
  maxWidth: '70%',
  padding: '12px',
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}));

export const SenderTitle = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 600,
  color: '#595959',
}));

export const StyledDate = styled(Typography)(() => ({
  fontSize: '12px',
  color: '#595959',
}));
