import { Grid } from '@mui/material';

import { EvaluationResultData } from '../../../../api/theHive/statisticalReport/types';
import { ReportNumericTextField } from '../../../CustomInput';
import { StyledMenuItem, StyledSelect, StyledTextField } from '../styles';

interface EvaluationResultProps {
  inputs: EvaluationResultData;
  readOnly: boolean;
}

export function EvaluationResult({
  inputs,
  readOnly,
}: EvaluationResultProps): JSX.Element {
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <StyledSelect
          disabled={readOnly}
          select
          label="objetivo da avaliação"
          value={inputs.objective}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="Valor de mercado">
            valor de mercado
          </StyledMenuItem>
          <StyledMenuItem value="Valor de mercado + Valor de liquidação imediata">
            valor de mercado + valor de liquidação imediata
          </StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={4}>
        {inputs.objective ===
          'Valor de mercado + Valor de liquidação imediata' && (
          <StyledTextField
            disabled={readOnly}
            label="VLI máximo / Prazo mínimo"
            value={inputs.maximum_imediate_liquidation_value_or_minimum_term}
          />
        )}
      </Grid>
      <Grid item xs={4}>
        <StyledTextField
          disabled={readOnly}
          label="prazo mínimo (meses)"
          value={inputs.minimum_term_in_months}
          type="number"
        />
      </Grid>
      <Grid item xs={4}>
        <StyledTextField
          disabled={readOnly}
          label="VLI mínimo / Prazo máximo"
          value={inputs.minimum_imediate_liquidation_value_or_maximum_term}
        />
      </Grid>
      <Grid item xs={4}>
        <StyledTextField
          disabled={readOnly}
          label="prazo máximo (meses)"
          value={inputs.maximum_term_in_months}
          type="number"
        />
      </Grid>
      <Grid item xs={4}>
        <ReportNumericTextField
          id="total-market-value"
          label="valor de mercado total do imóvel"
          value={inputs.total_market_value}
          disable={readOnly}
        />
      </Grid>
      <Grid item xs={4}>
        <ReportNumericTextField
          id="interval-result-upper"
          label="resultado intervalar - limite superior"
          value={inputs.interval_result_upper_bound}
          disable={readOnly}
        />
      </Grid>
      <Grid item xs={4}>
        <ReportNumericTextField
          id="interval-result-lower"
          label="resultado intervalar - limite inferior"
          value={inputs.interval_result_lower_bound}
          disable={readOnly}
        />
      </Grid>
      <Grid item xs={4}>
        <StyledTextField
          disabled={readOnly}
          label="área de referência (m²)"
          value={inputs.reference_area_in_squared_meters}
          type="number"
        />
      </Grid>
      <Grid item xs={4}>
        <ReportNumericTextField
          id="used-unitary-value"
          disable={readOnly}
          label="valor unitário adotado"
          value={inputs.used_unitary_value}
        />
      </Grid>
      <Grid item xs={4}>
        <ReportNumericTextField
          id="assigned-appraisal-value"
          label="valor atribuído ao imóvel"
          value={inputs.assigned_appraisal_value}
          disable={readOnly}
        />
      </Grid>
      <Grid item xs={4}>
        <StyledSelect
          disabled={readOnly}
          select
          label="foi adotado o valor médio"
          value={String(inputs.use_mean_value)}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="true">sim</StyledMenuItem>
          <StyledMenuItem value="false">não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={8}>
        {inputs.use_mean_value === 'false' && (
          <StyledTextField
            label="justifique"
            value={inputs.unuse_mean_value_reasoning}
            disabled={readOnly}
          />
        )}
      </Grid>
    </Grid>
  );
}
