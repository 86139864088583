import {
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { ParkingApartmentData } from '../../../../../../api/theHive/statisticalReport/types';
import {
  StyledTableCellBody,
  StyledTextField,
  TableTextField,
} from '../../../styles';

interface TableApartmentParkingProps {
  inputs: ParkingApartmentData;
  readOnly: boolean;
}

export function TableApartmentParking({
  inputs,
  readOnly,
}: TableApartmentParkingProps): JSX.Element {
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ border: '1px solid #ccc' }}>
              <TableCell rowSpan={2} align="center">
                estacionamento
              </TableCell>
              <TableCell
                colSpan={6}
                align="center"
                sx={{ backgroundColor: '#F4EEF6', border: '1px solid #ccc' }}
              >
                quantidade
              </TableCell>
            </TableRow>
            <TableRow
              sx={{ backgroundColor: '#E9E1ED', border: '1px solid #ccc' }}
            >
              <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
                cobertas
              </TableCell>
              <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
                descobertas
              </TableCell>
              <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
                total
              </TableCell>
              <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
                livres
              </TableCell>
              <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
                presa
              </TableCell>
              <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
                total
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
                vaga(s) vinculada(s)
              </TableCell>
              <StyledTableCellBody>
                <TableTextField
                  type="number"
                  value={inputs.linked_spots.covered}
                  disabled={readOnly}
                />
              </StyledTableCellBody>
              <StyledTableCellBody>
                <TableTextField
                  type="number"
                  value={inputs.linked_spots.not_covered}
                  disabled={readOnly}
                />
              </StyledTableCellBody>
              <StyledTableCellBody>
                <TableTextField
                  type="number"
                  value={inputs.linked_spots.total_covered_and_not_covered}
                  disabled
                />
              </StyledTableCellBody>
              <StyledTableCellBody>
                <TableTextField
                  type="number"
                  value={inputs.linked_spots.free}
                  disabled={readOnly}
                />
              </StyledTableCellBody>
              <StyledTableCellBody>
                <TableTextField
                  type="number"
                  value={inputs.linked_spots.locked}
                  disabled={readOnly}
                />
              </StyledTableCellBody>
              <StyledTableCellBody>
                <TableTextField
                  value={inputs.linked_spots.total_free_and_locked}
                  disabled
                />
              </StyledTableCellBody>
            </TableRow>
            <TableRow>
              <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
                vaga(s) autônoma(s)
              </TableCell>
              <StyledTableCellBody>
                <TableTextField
                  type="number"
                  value={inputs.independent_spots.covered}
                  disabled={readOnly}
                />
              </StyledTableCellBody>
              <StyledTableCellBody>
                <TableTextField
                  type="number"
                  value={inputs.independent_spots.not_covered}
                  disabled={readOnly}
                />
              </StyledTableCellBody>
              <StyledTableCellBody>
                <TableTextField
                  value={inputs.independent_spots.total_covered_and_not_covered}
                  disabled
                />
              </StyledTableCellBody>
              <StyledTableCellBody>
                <TableTextField
                  value={inputs.independent_spots.free}
                  disabled={readOnly}
                />
              </StyledTableCellBody>
              <StyledTableCellBody>
                <TableTextField
                  value={inputs.independent_spots.locked}
                  disabled={readOnly}
                />
              </StyledTableCellBody>
              <StyledTableCellBody>
                <TableTextField
                  value={inputs.independent_spots.total_free_and_locked}
                  disabled
                />
              </StyledTableCellBody>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <Grid container spacing={2} marginTop={2}>
        <Grid item xs={6}>
          <StyledTextField
            type="number"
            label="total vagas de uso comum"
            value={inputs.total_public_spots}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledTextField
            type="number"
            label="quantidade total de vagas privativas"
            value={inputs.total_private_spots}
            disabled={readOnly}
          />
        </Grid>
      </Grid>
    </>
  );
}
