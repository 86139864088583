/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Status, convertStatus } from '../../api/enumerations';
import { getAllWorkOrders } from '../../api/workOrders';
import {
  GetAllWorkOrderDataProps,
  GetWorkOrdersParams,
} from '../../api/workOrders/types';
import Snackbar from '../../components/Snackbar';
import { Constants } from '../../constants/home';
import { IconAssignmentMS, IconDesktopWindowsMS } from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import useErrorMessage from '../../hooks/useErrorMessage';
import { Card } from '../Home/Column/Card';
import {
  GridContainer,
  PipelineBox,
  PipelineContainer,
  PipelinePaper,
  PipelineTitle,
  TitleBox,
  TitleTypography,
  DesktopMode,
} from './styles';

export function PresentationMode(): JSX.Element {
  const [pipelineData, setPipelineData] = useState<GetAllWorkOrderDataProps>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startScroll, setStartScroll] = useState(false);

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();

  const navigate = useNavigate();

  const getDataCallback = useCallback(async () => {
    const params: GetWorkOrdersParams = {};
    try {
      const response = await getAllWorkOrders(params);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data) {
        setPipelineData(response.data);
        setStartScroll(true);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, []);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  const pipelineColumn = [
    {
      name: convertStatus(Status.ENTRANCE),
      status: pipelineData?.[Status.ENTRANCE],
      link: 'edit',
    },
    {
      name: convertStatus(Status.PEPT),
      status: pipelineData?.[Status.PEPT],
      link: 'pept',
    },
    {
      name: convertStatus(Status.SCHEDULE),
      status: pipelineData?.[Status.SCHEDULE],
      link: 'schedule',
    },
    {
      name: convertStatus(Status.INSPECTION),
      status: pipelineData?.[Status.INSPECTION],
      link: 'inspection',
    },
    {
      name: convertStatus(Status.SAMPLE_CREATION),
      status: pipelineData?.[Status.SAMPLE_CREATION],
      link: 'sample',
    },
    {
      name: convertStatus(Status.REPORT),
      status: pipelineData?.[Status.REPORT],
      link: 'report',
    },
    {
      name: convertStatus(Status.REVISION),
      status: pipelineData?.[Status.REVISION],
      link: 'revision',
    },
    {
      name: convertStatus(Status.ARCHIVED),
      status: pipelineData?.[Status.ARCHIVED],
      link: 'archived',
    },
  ];

  const switchToDesktopMode = (): void => {
    navigate('/home');
  };

  useEffect(() => {
    let scrollInterval: NodeJS.Timeout | null = null;
    const scrollIntervalTime = 30;
    const scrollAmountPixels = 1;

    if (startScroll) {
      scrollInterval = setInterval(() => {
        const columnsElement = document.querySelectorAll('.column');
        const currentColumn = columnsElement.item(currentIndex);
        if (!currentColumn) return;

        currentColumn.classList.add('active');

        if (currentColumn.clientHeight === currentColumn.scrollHeight) {
          setTimeout(() => {
            setCurrentIndex((currentIndex + 1) % pipelineColumn.length);
            currentColumn.classList.remove('active');
          }, 5000);
        } else {
          currentColumn.scrollTop += scrollAmountPixels;

          const scrolledToBottom =
            currentColumn.scrollTop + currentColumn.clientHeight ===
            currentColumn.scrollHeight;

          if (scrolledToBottom) {
            setTimeout(() => {
              setCurrentIndex((currentIndex + 1) % pipelineColumn.length);
              currentColumn.scrollTop = 0;
              currentColumn.classList.remove('active');
            }, 3000);
          }
        }
        currentColumn.scrollIntoView({
          behavior: 'auto',
          inline: 'nearest',
        });
      }, scrollIntervalTime);
    }

    return () => {
      if (scrollInterval) {
        clearInterval(scrollInterval);
      }
    };
  }, [startScroll, currentIndex, pipelineColumn.length]);

  return (
    <GridContainer>
      <TitleBox>
        <TitleTypography>
          {IconAssignmentMS}
          {Constants.title}
        </TitleTypography>
        <DesktopMode onClick={switchToDesktopMode}>
          {IconDesktopWindowsMS}
          {Constants.DesktopMode}
        </DesktopMode>
      </TitleBox>
      <PipelineContainer>
        {pipelineColumn.map((column) => (
          <PipelineBox key={column.name}>
            <PipelineTitle>{column.name}</PipelineTitle>
            <PipelinePaper className="column">
              {column.status?.map((data) => (
                <Card cardData={data} key={data.id} />
              ))}
            </PipelinePaper>
          </PipelineBox>
        ))}
      </PipelineContainer>
      {openSnackbar && <Snackbar />}
    </GridContainer>
  );
}
