import { Box, Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BackButton = styled(IconButton)(() => ({
  '& span': {
    fontSize: 40,
    color: '#00B39B',
  },
}));

export const SectionBox = styled(Box)(() => ({
  margin: '40px 0',
}));

export const SubmitBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '60px 0',

  '& .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const DialogButton = styled(Button)(({ theme }) => ({
  minWidth: 100,
  border: '1px solid #004D43',
  color: '#004D43',
  backgroundColor: 'transparent',
  marginRight: 20,
  [theme.breakpoints.down('lg')]: {
    minWidth: '180px',
    maxWidth: '200px',
  },
  [theme.breakpoints.down('xl')]: {
    width: 200,
    padding: 16,
  },
}));

export const DownloadButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  borderRadius: '10px',
  width: ' 250px',
  border: `2px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,
  fontWeight: 600,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
  '&:disabled': {
    color: theme.palette.primary.main,
  },
}));
