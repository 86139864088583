/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { ChangeEvent, useContext, useEffect } from 'react';
import { PatternFormat } from 'react-number-format';

import {
  ClientKind,
  EvaluationType,
  PropertyType,
} from '../../api/enumerations';
import { AllAttachments } from '../../components/AllAttachments';
import ConfirmationDeleteDialog from '../../components/ConfirmationDeleteDialog';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import {
  CustomTextField,
  NumericTextField,
  SelectTextField,
} from '../../components/CustomInput';
import StandardMap from '../../components/Maps/StandardMap';
import { MessagesDialog } from '../../components/Messages';
import { PeptDialog } from '../../components/PeptDialog';
import { AccordionTitle } from '../../components/Sections/AccordionTitle';
import { ClientData } from '../../components/Sections/ClientData';
import { PropertyAddressWithMaps } from '../../components/Sections/PropertyAddressWithMaps';
import { PropertyData } from '../../components/Sections/PropertyData';
import { PropertyInfo } from '../../components/Sections/PropertyInfo';
import { Title } from '../../components/Sections/Title';
import { ToogleAccordion } from '../../components/Sections/ToogleAccordion';
import Snackbar from '../../components/Snackbar';
import {
  BoxContainer,
  CancelOsBox,
  FlexSpaceBetweenBox,
} from '../../components/UI/Box';
import { GridContainer } from '../../components/UI/Grid';
import { Constants } from '../../constants/entrance';
import {
  IconApartmentMS,
  IconArrowCircleLeftMS,
  IconDownloadMS,
  IconLocationCityMS,
  IconLocationOnMS,
  IconPersonMS,
  IconUploadMS,
} from '../../constants/icons';
import {
  selectClientKind,
  selectConcept,
  selectConservation,
  selectEvaluationType,
  selectEvaluationTypeBank,
  selectEvaluationTypeLot,
  selectPropertyType,
  selectPropertyUse,
  SelectProps,
  selectRegistrationUf,
  selectReportFunction,
  selectReportGoal,
  selectStandard,
} from '../../constants/selectOptions';
import { GlobalContext } from '../../context/global';
import { useAccordion } from '../../hooks/useAccordion';
import { useCancelWorkOrder } from '../../hooks/useCancelWorkOrder';
import { useChangeStatus } from '../../hooks/useChangeStatus';
import useGeneral from '../../hooks/useGeneral';
import {
  BackButton,
  BoldTypography,
  ButtonBox,
  ButtonContainer,
  ButtonTitle,
  CoordGrid,
  DownloadButton,
  EditButton,
  EditSubmitBox,
  InputBox,
  OutlineButton,
  SectionBox,
  SectionTitle,
  StyledTextField,
  SubmitBox,
  SubmitButton,
  UploadButton,
} from './styles';
import useEntrance from './useEntrance';

export function Entrance(): JSX.Element {
  const isBank = process.env.REACT_APP_IS_BANK === 'true';

  const { openSnackbar } = useContext(GlobalContext);
  const { handleCancelWorkOrder } = useCancelWorkOrder();
  const { handleStatus, loadingApprove } = useChangeStatus();
  const { osId, navigateHome, handleDownloadFile } = useGeneral();
  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandAll,
    setExpandAll,
    toggleAccordions,
  } = useAccordion();
  const {
    handleSubmit,
    verifyCep,
    handleFileUpload,
    handleRemoveRequest,
    address,
    setAddress,
    addressNumber,
    setAddressNumber,
    age,
    setAge,
    buildingStandard,
    setBuildingStandard,
    builtArea,
    setBuiltArea,
    cep,
    setCep,
    cepError,
    city,
    setCity,
    client,
    setClient,
    clientType,
    setClientType,
    complement,
    setComplement,
    concept,
    setConcept,
    conservation,
    setConservation,
    district,
    setDistrict,
    evaluationType,
    setEvaluationType,
    goal,
    setGoal,
    judicialDistrict,
    setJudicialDistrict,
    parkingLots,
    setParkingLots,
    propertyFunction,
    setPropertyFunction,
    propertyType,
    setPropertyType,
    propertyUse,
    setPropertyUse,
    registerNumber,
    setRegisterNumber,
    registrationUf,
    setRegistrationUf,
    setRequiresInspection,
    rooms,
    setRooms,
    solicitor,
    setSolicitor,
    suites,
    setSuites,
    toilets,
    setToilets,
    totalArea,
    setTotalArea,
    uf,
    setUf,
    zone,
    setZone,
    propertyData,
    registerFileName,
    iptuFileName,
    createdAt,
    downloadIptu,
    downloadRegister,
    referenceNumber,
    newProperty,
    searchMap,
    setSearchMap,
    setIsAddressEdited,
    pinPlace,
    setPinPlace,
    submitLoading,
    removeRequestLoading,
  } = useEntrance();

  useEffect(() => {
    if (expandOne && expandTwo) {
      setExpandAll(true);
    } else {
      setExpandAll(false);
    }
  }, [expandOne, expandTwo, setExpandAll]);

  useEffect(() => {
    setTimeout(() => {
      setExpandOne(true);
    }, 2000);
  }, []);

  const handleEvaluationOptions = (): SelectProps[] => {
    if (
      clientType === ClientKind.BRASIL_BANK ||
      clientType === ClientKind.CAIXA
    ) {
      return selectEvaluationTypeBank();
    }
    if (propertyType === PropertyType.LOT) {
      return selectEvaluationTypeLot();
    }
    return selectEvaluationType();
  };

  return (
    <GridContainer>
      <BackButton onClick={navigateHome}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer component="form" id="entrance" onSubmit={handleSubmit}>
        <Title
          osNumber={referenceNumber}
          title={newProperty ? Constants.dataEntrance : Constants.dataEdition}
          newOs={newProperty}
          createdAt={createdAt}
        />

        <FlexSpaceBetweenBox>
          <CancelOsBox>
            {!newProperty && (
              <ConfirmationDeleteDialog
                iconCancel
                title={Constants.cancelOs}
                text={Constants.cancelOsText}
                modalCallback={handleCancelWorkOrder}
              />
            )}
          </CancelOsBox>
          <Box display="flex" gap={1.2}>
            {!newProperty && isBank && propertyData && (
              <MessagesDialog
                osId={osId}
                hiveRequest={propertyData.hive_request || undefined}
              />
            )}
            <AllAttachments propertyData={propertyData} osId={osId} />
          </Box>{' '}
        </FlexSpaceBetweenBox>
        {isBank && propertyData?.worker_company_id !== null && !newProperty ? (
          <ClientData propertyData={propertyData} />
        ) : (
          <SectionBox>
            <SectionTitle>
              {IconPersonMS}
              {Constants.reportData}
            </SectionTitle>
            <InputBox>
              <CustomTextField
                required
                id="client"
                label="proprietário do imóvel"
                value={client}
                setValue={setClient}
              />
              <SelectTextField
                id="client-type"
                label="tipo do cliente"
                value={clientType}
                setValue={(value) => {
                  setClientType(value);
                  setEvaluationType(0);
                  if (value === ClientKind.BRASIL_BANK) {
                    setSolicitor('Banco do Brasil');
                  } else if (value === ClientKind.CAIXA) {
                    setSolicitor('Caixa Econômica');
                  } else setSolicitor('');
                }}
                selectOptions={selectClientKind()}
              />
              <SelectTextField
                id="os-goal"
                label="objetivo"
                value={goal}
                setValue={setGoal}
                selectOptions={selectReportGoal()}
              />
            </InputBox>
            <InputBox>
              <CustomTextField
                id="solicitor"
                label="solicitante"
                value={solicitor}
                setValue={setSolicitor}
              />
              <SelectTextField
                id="os-function"
                label="finalidade"
                value={propertyFunction}
                setValue={setPropertyFunction}
                selectOptions={selectReportFunction()}
              />
              <SelectTextField
                id="property-use"
                label="uso do imóvel"
                value={propertyUse}
                setValue={setPropertyUse}
                selectOptions={selectPropertyUse()}
              />
              <SelectTextField
                id="evaluation-type"
                label="tipo do cálculo"
                value={evaluationType}
                setValue={(value) => {
                  setEvaluationType(value);
                  if (
                    value === EvaluationType.AUTOFACTORS ||
                    value === EvaluationType.AUTOINFERENCES
                  ) {
                    setRequiresInspection(false);
                  } else {
                    setRequiresInspection(true);
                  }
                }}
                selectOptions={handleEvaluationOptions()}
              />
            </InputBox>
          </SectionBox>
        )}
        <SectionBox>
          <SectionTitle>
            {IconApartmentMS}
            {Constants.propertyData}
          </SectionTitle>
          {isBank && propertyData?.worker_company_id && !newProperty ? (
            <PropertyData propertyData={propertyData} />
          ) : (
            <>
              <Typography>{Constants.uploadText}</Typography>
              <ButtonContainer>
                <ButtonBox>
                  <ButtonTitle>{Constants.propertyRegister}</ButtonTitle>
                  {downloadRegister.length > 0 ? (
                    <DownloadButton
                      onClick={() => {
                        if (downloadRegister)
                          handleDownloadFile(downloadRegister, 'matricula');
                      }}
                    >
                      {IconDownloadMS}
                      {Constants.download}
                    </DownloadButton>
                  ) : (
                    <>
                      <label htmlFor="uploadRegister">
                        <input
                          accept="image/*,application/pdf"
                          id="uploadRegister"
                          type="file"
                          style={{ display: 'none' }}
                          onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                            handleFileUpload(e, 'register')
                          }
                        />
                        <UploadButton variant="contained" component="span">
                          {IconUploadMS}
                          {Constants.upload}
                        </UploadButton>
                      </label>
                      {registerFileName && <Box mt={1}>{registerFileName}</Box>}
                    </>
                  )}
                </ButtonBox>
                <ButtonBox>
                  <ButtonTitle>{Constants.iptu}</ButtonTitle>
                  {downloadIptu.length > 0 ? (
                    <DownloadButton
                      onClick={() => {
                        if (downloadIptu)
                          handleDownloadFile(downloadIptu, 'iptu');
                      }}
                    >
                      {IconDownloadMS}
                      {Constants.download}
                    </DownloadButton>
                  ) : (
                    <>
                      <label htmlFor="uploadIptu">
                        <input
                          accept="image/*,application/pdf"
                          id="uploadIptu"
                          type="file"
                          style={{ display: 'none' }}
                          onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                            handleFileUpload(e, 'iptu')
                          }
                        />
                        <UploadButton variant="contained" component="span">
                          {IconUploadMS}
                          {Constants.upload}
                        </UploadButton>
                      </label>
                      {iptuFileName && <Box mt={1}>{iptuFileName}</Box>}
                    </>
                  )}
                </ButtonBox>
              </ButtonContainer>
              <InputBox>
                <CustomTextField
                  required
                  id="register-number"
                  label="nº da matrícula"
                  value={registerNumber}
                  setValue={setRegisterNumber}
                />
                <SelectTextField
                  id="property-type"
                  label="tipo do imóvel"
                  value={propertyType}
                  setValue={(value) => {
                    setPropertyType(value);
                    if (
                      (value === PropertyType.LOT &&
                        evaluationType === EvaluationType.AUTOFACTORS) ||
                      evaluationType === EvaluationType.SIMPFACTORS
                    ) {
                      setEvaluationType(0);
                    }
                  }}
                  selectOptions={selectPropertyType()}
                />
                <SelectTextField
                  id="concept"
                  label="conceito do espaço"
                  value={concept}
                  setValue={setConcept}
                  selectOptions={selectConcept()}
                />
              </InputBox>
              <InputBox>
                <NumericTextField
                  id="zone"
                  label="ofício/zona"
                  suffix=""
                  maxLength={12}
                  value={zone}
                  setValue={setZone}
                />
                <CustomTextField
                  required
                  id="judicial-district"
                  label="comarca"
                  value={judicialDistrict}
                  setValue={setJudicialDistrict}
                />
                <SelectTextField
                  id="registration-uf"
                  label="UF"
                  value={registrationUf}
                  setValue={setRegistrationUf}
                  selectOptions={selectRegistrationUf()}
                />
              </InputBox>
            </>
          )}
        </SectionBox>
        <Box>
          <ToogleAccordion expand={expandAll} toogle={toggleAccordions} />
          <SectionBox>
            <AccordionTitle
              title={Constants.propertyAddress}
              icon={IconLocationOnMS}
              openAccordion={expandOne}
              setOpenAccordion={setExpandOne}
            />
            {expandOne &&
              (isBank &&
              propertyData?.worker_company_id !== null &&
              !newProperty ? (
                <PropertyAddressWithMaps propertyData={propertyData} />
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Grid container spacing={4}>
                      <Grid item xs={4}>
                        <PatternFormat
                          required
                          format="#####-###"
                          mask="_"
                          customInput={StyledTextField}
                          id="cep"
                          label="CEP"
                          color="secondary"
                          value={cep}
                          onInvalid={(e: ChangeEvent<HTMLInputElement>) =>
                            e.target.setCustomValidity('Preencha este campo')
                          }
                          onInput={(e: ChangeEvent<HTMLInputElement>) =>
                            e.target.setCustomValidity('')
                          }
                          error={cepError}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setCep(e.target.value);
                            verifyCep(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <CustomTextField
                          required
                          id="address"
                          label="logradouro"
                          value={address}
                          setValue={setAddress}
                          onChange={setIsAddressEdited}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomTextField
                          required
                          id="address-number"
                          label="número"
                          value={addressNumber}
                          setValue={setAddressNumber}
                          onChange={setIsAddressEdited}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <CustomTextField
                          id="complement"
                          label="complemento"
                          value={complement}
                          setValue={setComplement}
                          onChange={setIsAddressEdited}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomTextField
                          required
                          id="district"
                          label="bairro"
                          value={district}
                          setValue={setDistrict}
                          onChange={setIsAddressEdited}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomTextField
                          required
                          id="city"
                          label="cidade"
                          value={city}
                          setValue={setCity}
                          onChange={setIsAddressEdited}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <SelectTextField
                          id="uf"
                          label="estado"
                          value={uf}
                          setValue={setUf}
                          selectOptions={selectRegistrationUf()}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <OutlineButton
                          onClick={() => {
                            setSearchMap(!searchMap);
                            setIsAddressEdited(false);
                          }}
                        >
                          {Constants.searchMap}
                        </OutlineButton>
                      </Grid>
                      <CoordGrid item xs={6}>
                        <BoldTypography>{Constants.lat}</BoldTypography>
                        <Typography>{pinPlace.lat}</Typography>
                      </CoordGrid>
                      <CoordGrid item xs={6}>
                        <BoldTypography>{Constants.lng}</BoldTypography>
                        <Typography>{pinPlace.lng}</Typography>
                      </CoordGrid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <StandardMap
                      searchMap={searchMap}
                      address={`${address} ${addressNumber} ${district} ${city}`}
                      pinPlace={pinPlace}
                      setPinPlace={setPinPlace}
                    />
                  </Grid>
                </Grid>
              ))}
          </SectionBox>
          <SectionBox>
            <AccordionTitle
              title={Constants.propertyDetails}
              icon={IconLocationCityMS}
              openAccordion={expandTwo}
              setOpenAccordion={setExpandTwo}
            />
            {expandTwo &&
              (isBank &&
              propertyData?.worker_company_id !== null &&
              !newProperty ? (
                <PropertyInfo propertyData={propertyData} />
              ) : propertyType !== PropertyType.LOT ? (
                <>
                  <InputBox>
                    <NumericTextField
                      id="total-area"
                      label={
                        propertyType === PropertyType.APARTMENT
                          ? 'área total'
                          : 'área do terreno'
                      }
                      suffix=" m²"
                      decimalSeparator=","
                      decimalScale={2}
                      maxLength={18}
                      value={totalArea}
                      setValue={setTotalArea}
                    />
                    <NumericTextField
                      id="built-area"
                      label={
                        propertyType === PropertyType.APARTMENT
                          ? 'área privativa'
                          : 'área construída'
                      }
                      suffix=" m²"
                      decimalSeparator=","
                      decimalScale={2}
                      maxLength={18}
                      value={builtArea}
                      setValue={setBuiltArea}
                    />
                    <NumericTextField
                      id="rooms"
                      label="quartos"
                      suffix=" quartos"
                      maxLength={12}
                      value={rooms}
                      setValue={setRooms}
                    />
                    <NumericTextField
                      id="toilets"
                      label="banheiros"
                      suffix=" banheiros"
                      maxLength={13}
                      value={toilets}
                      setValue={setToilets}
                    />
                    <NumericTextField
                      id="suites"
                      label="suítes"
                      suffix=" suítes"
                      maxLength={10}
                      value={suites}
                      setValue={setSuites}
                    />
                    <NumericTextField
                      id="parking-lots"
                      label="vagas de garagem"
                      suffix=" vagas"
                      maxLength={9}
                      value={parkingLots}
                      setValue={setParkingLots}
                    />
                  </InputBox>
                  <InputBox>
                    <NumericTextField
                      id="propertyAge"
                      label="idade do imóvel"
                      suffix=" anos"
                      maxLength={9}
                      value={age}
                      setValue={setAge}
                    />
                    <SelectTextField
                      id="conservation"
                      label="estado de conservação do imóvel"
                      value={conservation}
                      setValue={setConservation}
                      selectOptions={selectConservation()}
                    />
                    <SelectTextField
                      id="building-standard"
                      label="padrão construtivo"
                      value={buildingStandard}
                      setValue={setBuildingStandard}
                      selectOptions={selectStandard()}
                    />
                  </InputBox>
                </>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <NumericTextField
                      id="total-area"
                      label="área do terreno"
                      suffix=" m²"
                      decimalSeparator=","
                      decimalScale={2}
                      maxLength={18}
                      value={totalArea}
                      setValue={setTotalArea}
                    />
                  </Grid>
                </Grid>
              ))}
          </SectionBox>
          {newProperty ? (
            <SubmitBox>
              <SubmitButton
                form="entrance"
                type="submit"
                disabled={submitLoading}
              >
                {submitLoading ? (
                  <CircularProgress size={22} />
                ) : (
                  Constants.submit
                )}
              </SubmitButton>
            </SubmitBox>
          ) : (
            <EditSubmitBox>
              {!isBank ? (
                <PeptDialog osId={osId} referenceNumber={referenceNumber} />
              ) : (
                <Box />
              )}
              <FlexSpaceBetweenBox gap="20px">
                {!isBank ? (
                  <>
                    <EditButton
                      form="entrance"
                      type="submit"
                      disabled={submitLoading || loadingApprove}
                    >
                      {submitLoading ? (
                        <CircularProgress size={22} />
                      ) : (
                        Constants.confirmEdit
                      )}
                    </EditButton>
                    <ConfirmationDialog
                      entrance
                      loading={loadingApprove}
                      title={Constants.approve}
                      text={Constants.changeStatus.replace(
                        '**',
                        `${referenceNumber}`
                      )}
                      modalCallback={() => handleStatus(osId, referenceNumber)}
                    />
                  </>
                ) : propertyData?.hive_request?.acceptance_status ===
                  'rejected' ? (
                  <ConfirmationDialog
                    loading={removeRequestLoading}
                    title={Constants.remove}
                    text={Constants.removeRequest}
                    modalCallback={handleRemoveRequest}
                  />
                ) : (
                  propertyData?.worker_company_id === null && (
                    <EditButton
                      form="entrance"
                      type="submit"
                      disabled={submitLoading || loadingApprove}
                    >
                      {submitLoading ? (
                        <CircularProgress size={22} />
                      ) : (
                        Constants.confirmEdit
                      )}
                    </EditButton>
                  )
                )}
              </FlexSpaceBetweenBox>
            </EditSubmitBox>
          )}
        </Box>
      </BoxContainer>
      {openSnackbar && <Snackbar />}
    </GridContainer>
  );
}
