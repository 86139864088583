import { Grid } from '@mui/material';

import { WarrantyData } from '../../../../api/theHive/statisticalReport/types';
import { StyledMenuItem, StyledSelect, StyledTextField } from '../styles';

interface WarrantyProps {
  inputs: WarrantyData;
  readOnly: boolean;
}

export function WarrantyStatements({
  inputs,
  readOnly,
}: WarrantyProps): JSX.Element {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <StyledSelect
          disabled={readOnly}
          select
          label=""
          value={String(inputs.can_be_accepted)}
        >
          <StyledMenuItem value={undefined}>selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="true">sim</StyledMenuItem>
          <StyledMenuItem value="false">não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={9}>
        {String(inputs.can_be_accepted) === 'false' && (
          <StyledTextField
            label=""
            placeholder="justifique*"
            value={inputs.unaccepted_reason}
            disabled={readOnly}
          />
        )}
      </Grid>
    </Grid>
  );
}
