import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)(() => ({
  position: 'relative',
  border: '1px solid transparent',
  paddingBottom: 20,
}));

export const ProgressBar = styled(Box)(() => ({
  position: 'absolute',
  left: 0,
  top: 26,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

interface IconProps {
  complete: 'true' | 'false';
}

export const Icon = styled(Box)<IconProps>(({ complete }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: ' center',
  textAlign: 'center',
  span: {
    color: complete === 'true' ? '#28C40A' : '#FE850C',
    transition:
      complete === 'true' ? 'color 0.3s ease' : 'color 0.3s ease 0.3s',
    fontSize: 24,
    animation: complete === 'true' ? 'pulse 0.5s ease-in-out' : 'none',
  },
  borderRadius: 50,
  background: '#F2F2F2',
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
      opacity: 1,
    },
    '50%': {
      transform: 'scale(1.1)',
      opacity: 0.8,
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 1,
    },
  },
}));

export const Line = styled(Box)<IconProps>(({ complete }) => ({
  height: '100%',
  width: '1px',
  background: `linear-gradient(${
    complete === 'true' ? 'to top' : 'to bottom'
  }, ${complete === 'true' ? '#FE850C' : '#28C40A'} 50%, ${
    complete === 'true' ? '#28C40A 50%' : '#FE850C 50%'
  })`,
  backgroundSize: '100% 200%',
  backgroundPosition: complete === 'true' ? '0% 0%' : '0% 100%',
  transition: 'background-position 0.3s ease-in-out',
}));
