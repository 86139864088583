/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  convertArchivedReason,
  EvaluationType,
  Status,
} from '../../api/enumerations';
import { getSelectedInspectionPictures } from '../../api/inspections';
import { GalleryPictureData } from '../../api/pictures/types';
import { getSample } from '../../api/samples';
import { ElementData } from '../../api/samples/types';
import { GetWorkOrder } from '../../api/workOrders';
import { WorkOrderData } from '../../api/workOrders/types';
import { AllAttachments } from '../../components/AllAttachments';
import { ExportSample } from '../../components/ExportSample';
import { MessagesDialog } from '../../components/Messages';
import { AccordionTitle } from '../../components/Sections/AccordionTitle';
import { ClientData } from '../../components/Sections/ClientData';
import { FormResponse } from '../../components/Sections/FormResponse';
import { PdfViewer } from '../../components/Sections/PdfViewer';
import { PropertyAddressWithMaps } from '../../components/Sections/PropertyAddressWithMaps';
import { PropertyData } from '../../components/Sections/PropertyData';
import { PropertyInfo } from '../../components/Sections/PropertyInfo';
import { ReportDownload } from '../../components/Sections/ReportDownload';
import { Rooms } from '../../components/Sections/Rooms';
import { SampleSection } from '../../components/Sections/Sample';
import { ScheduleInfo } from '../../components/Sections/ScheduleInfo';
import { StatisticalReport } from '../../components/Sections/StatisticalReport';
import { Title } from '../../components/Sections/Title';
import { ToogleAccordion } from '../../components/Sections/ToogleAccordion';
import Snackbar from '../../components/Snackbar';
import { BoxContainer, LoadingBox } from '../../components/UI/Box';
import { GridContainer } from '../../components/UI/Grid';
import { Constants } from '../../constants/archived';
import {
  IconApartmentMS,
  IconArrowCircleLeftMS,
  IconCalendarTodayMS,
  IconDescriptionMS,
  IconDownloadMS,
  IconEngineeringMS,
  IconFactCheckMS,
  IconListAltMS,
  IconLocationCityMS,
  IconLocationOnMS,
  IconPhotoLibraryMS,
} from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import { useAccordion } from '../../hooks/useAccordion';
import useErrorMessage from '../../hooks/useErrorMessage';
import useGeneral from '../../hooks/useGeneral';
import { useRooms } from '../../hooks/useRooms';
import {
  BackButton,
  DownloadButton,
  FlexSpaceBetweenBox,
  InputTitle,
  SectionBox,
  StyledInput,
  SubmitBox,
} from './styles';

export function Archived(): JSX.Element {
  const [propertyData, setPropertyData] = useState<WorkOrderData>();
  const [sampleData, setSampleData] = useState<ElementData[]>();
  const [loadingPage, setLoadingPage] = useState(true);
  const [selectedPictures, setSelectedPictures] = useState<
    GalleryPictureData[]
  >([]);

  const navigate = useNavigate();

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();
  const { osId } = useGeneral();
  const { handleRooms, rooms } = useRooms();
  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandFour,
    setExpandFour,
    expandFive,
    setExpandFive,
    expandSix,
    setExpandSix,
    expandSeven,
    setExpandSeven,
    expandEight,
    setExpandEight,
    expandNine,
    setExpandNine,
    expandTen,
    setExpandTen,
    expandAll,
    setExpandAll,
    toggleAccordions,
  } = useAccordion();

  useEffect(() => {
    if (
      expandOne &&
      expandTwo &&
      expandThree &&
      expandFour &&
      expandFive &&
      expandSix &&
      expandSeven &&
      expandEight &&
      expandNine &&
      expandTen
    ) {
      setExpandAll(true);
    } else {
      setExpandAll(false);
    }
  }, [
    expandOne,
    expandTwo,
    expandThree,
    expandFour,
    expandFive,
    expandSix,
    expandSeven,
    expandEight,
    expandNine,
    expandTen,
  ]);

  const getSelectedPictures = async (inspectionId: number): Promise<void> => {
    try {
      const response = await getSelectedInspectionPictures(inspectionId);
      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }
      setSelectedPictures(response.data);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  };

  const getDataCallback = useCallback(async () => {
    let getSamples = false;
    let isFactors = false;

    try {
      const response = await GetWorkOrder(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data || response.data.status !== Status.ARCHIVED) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data.samples) {
        getSamples = true;
      }

      if (
        response.data.evaluation_type === EvaluationType.AUTOFACTORS ||
        response.data.evaluation_type === EvaluationType.SIMPFACTORS
      ) {
        isFactors = true;
      }

      if (response.data.inspection) {
        handleRooms(response.data.inspection.id);
        getSelectedPictures(response.data.inspection.id);
      }

      setPropertyData(response.data);
      setLoadingPage(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error, true));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }

    if (getSamples) {
      try {
        const response = await getSample(osId, 1, 105);
        if (response.detail.description) {
          throw new Error(response.detail.description);
        }
        if (!response.data) {
          throw new Error('A amostra não pode ser carregada.');
        }

        if (isFactors) {
          const filteredSamples: ElementData[] = response.data.items.filter(
            (data) => data.is_utilized
          );
          setSampleData(filteredSamples);
          return;
        }
        setSampleData(response.data.items);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setOpenSnackbar(true);
        setErrorMessage(true);
      }
    }
  }, [osId, setErrorMessage, setOpenSnackbar, setSnackbarMessage]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  const downloadFile = (link: string | null): void => {
    if (link) {
      const linkSource = link;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.click();
    }
  };

  return (
    <GridContainer>
      <BackButton onClick={() => navigate('/archived')}>
        {IconArrowCircleLeftMS}
      </BackButton>
      <BoxContainer>
        <Title
          osNumber={propertyData?.reference_number || 0}
          title={Constants.archived}
          createdAt={propertyData?.created_at}
        />
        {loadingPage ? (
          <LoadingBox>
            <CircularProgress />
          </LoadingBox>
        ) : (
          <>
            <FlexSpaceBetweenBox>
              <Box>
                <InputTitle>{Constants.reason}</InputTitle>
                <StyledInput>
                  {convertArchivedReason(propertyData?.archived_reason)}
                </StyledInput>
              </Box>
              <Box display="flex" gap={1.2}>
                <MessagesDialog
                  osId={osId}
                  hiveRequest={propertyData?.hive_request || undefined}
                />
                <AllAttachments propertyData={propertyData} osId={osId} />
              </Box>{' '}
            </FlexSpaceBetweenBox>
            <ClientData propertyData={propertyData} />
            <Box>
              <ToogleAccordion expand={expandAll} toogle={toggleAccordions} />
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyData}
                  icon={IconApartmentMS}
                  openAccordion={expandOne}
                  setOpenAccordion={setExpandOne}
                />
                {expandOne && <PropertyData propertyData={propertyData} />}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyAddress}
                  icon={IconLocationOnMS}
                  openAccordion={expandTwo}
                  setOpenAccordion={setExpandTwo}
                />
                {expandTwo && (
                  <PropertyAddressWithMaps
                    checkLocation
                    propertyData={propertyData}
                  />
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyDetails}
                  icon={IconLocationCityMS}
                  openAccordion={expandThree}
                  setOpenAccordion={setExpandThree}
                />
                {expandThree && <PropertyInfo propertyData={propertyData} />}
              </SectionBox>
              {propertyData?.evaluation_type !== EvaluationType.AUTOFACTORS &&
                propertyData?.evaluation_type !==
                  EvaluationType.AUTOINFERENCES && (
                  <SectionBox>
                    <AccordionTitle
                      title={Constants.scheduleInfo}
                      icon={IconCalendarTodayMS}
                      openAccordion={expandFour}
                      setOpenAccordion={setExpandFour}
                    />
                    {expandFour && (
                      <Box>
                        {propertyData?.inspection ? (
                          <ScheduleInfo
                            inspectionData={propertyData.inspection}
                            rooms={rooms}
                          />
                        ) : (
                          <Typography>{Constants.noInspection}</Typography>
                        )}
                      </Box>
                    )}
                  </SectionBox>
                )}
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyPhotos}
                  icon={IconPhotoLibraryMS}
                  openAccordion={expandFive}
                  setOpenAccordion={setExpandFive}
                />
                {expandFive && (
                  <Box>
                    {propertyData?.inspection && rooms.length > 0 ? (
                      <Rooms
                        navigationPath={`${osId}/inspection/${propertyData.inspection.id}/rooms`}
                        rooms={rooms}
                        osId={osId}
                        inspectionId={propertyData.inspection.id}
                      />
                    ) : (
                      <Typography>{Constants.noPhotos}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.form}
                  icon={IconListAltMS}
                  openAccordion={expandSix}
                  setOpenAccordion={setExpandSix}
                />
                {expandSix && (
                  <Box>
                    {propertyData?.inspection?.form_response ? (
                      <FormResponse
                        questionForm={propertyData.inspection.form_response}
                      />
                    ) : (
                      <Typography>{Constants.noForm}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.sample}
                  icon={IconFactCheckMS}
                  openAccordion={expandSeven}
                  setOpenAccordion={setExpandSeven}
                />
                {expandSeven && (
                  <Box>
                    {sampleData && sampleData?.length > 0 ? (
                      <SampleSection
                        sampleData={sampleData}
                        propertyData={propertyData}
                      />
                    ) : (
                      <Typography>{Constants.noSample}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.report}
                  icon={IconEngineeringMS}
                  openAccordion={expandEight}
                  setOpenAccordion={setExpandEight}
                />
                {expandEight && <ReportDownload id={osId} />}
              </SectionBox>
              <SectionBox id="statistical-report">
                <AccordionTitle
                  title="Relatório estatístico"
                  icon={IconDescriptionMS}
                  openAccordion={expandNine}
                  setOpenAccordion={setExpandNine}
                />
                {expandNine && propertyData && (
                  <StatisticalReport
                    propertyData={propertyData}
                    rooms={rooms}
                    selectedPictures={selectedPictures}
                    readOnly
                  />
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.pdfReport}
                  icon={IconDescriptionMS}
                  openAccordion={expandTen}
                  setOpenAccordion={setExpandTen}
                />
                {expandTen &&
                  (propertyData?.task_id === null ? (
                    <Box display="flex" gap={2}>
                      <DownloadButton
                        disabled={
                          !propertyData.manual_statistical_report_report_pdf
                        }
                        onClick={() =>
                          downloadFile(
                            propertyData.manual_statistical_report_report_pdf
                          )
                        }
                      >
                        {IconDownloadMS} Relatório PDF
                      </DownloadButton>
                      <DownloadButton
                        disabled={
                          !propertyData.manual_statistical_report_calculation_report_document
                        }
                        onClick={() =>
                          downloadFile(
                            propertyData.manual_statistical_report_calculation_report_document
                          )
                        }
                      >
                        {IconDownloadMS} Memorial de cálculo
                      </DownloadButton>
                    </Box>
                  ) : (
                    <PdfViewer osId={osId} />
                  ))}
              </SectionBox>
              {propertyData?.report_xlsx && (
                <SubmitBox>
                  <ExportSample
                    osId={osId}
                    title="exportar lista de elementos e formulário"
                  />
                </SubmitBox>
              )}
            </Box>
          </>
        )}
      </BoxContainer>
      {openSnackbar && <Snackbar />}
    </GridContainer>
  );
}
