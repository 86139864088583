/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, InputAdornment } from '@mui/material';
import { useCallback, useEffect, useState, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { listAnnouncements } from '../../../../../api/theHive/announcement';
import {
  getNotice,
  listNoticeCompanies,
} from '../../../../../api/theHive/notices';
import { NoticeCompaniesData } from '../../../../../api/theHive/notices/types';
import {
  IconArrowCircleLeftMS,
  IconSearchMS,
} from '../../../../../constants/icons';
import { GlobalContext } from '../../../../../context/global';
import useErrorMessage from '../../../../../hooks/useErrorMessage';
import AnnouncementTable from '../components/AnnouncementTable';
import { NoticeAttachments } from '../components/Attachments';
import { CompaniesTable } from '../components/CompaniesTable';
import { CoverageAreaGrid } from '../components/CoverageAreaGrid';
import { useFetchCoverageOperations } from '../components/hooks/coverage';
import { useTypologyGroups } from '../components/hooks/typology';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { ModalAddingCoverage } from '../components/ModalAddingCoverage';
import { ModalAddTypology } from '../components/ModalAddTypology';
import { NewAnnouncementModal } from '../components/NewAnnouncementmodal';
import { TypologyGroupGrid } from '../components/TypologyGroupGrid';
import { AddTypologies } from '../NewNotice/styles';
import { StyledInputBase } from '../styles';
import {
  NoticeTitle,
  NoticeContainer,
  OutlinedButton,
  BackButton,
  InputTitle,
  StyledInput,
  TableSection,
  AddButton,
} from './styles';
import { AnnouncementData, Notice } from './types';

export function NoticeDetails(): JSX.Element {
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const [notice, setNotice] = useState<Notice | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showNewAnnouncementmodal, setShowNewAnnouncementmodal] =
    useState(false);
  const [showTypologyModal, setShowTypologyModal] = useState(false);
  const [showCoverageModal, setShowCoverageModal] = useState(false);
  const [pageCompanies, setPageCompanies] = useState(1);
  const [totalPagesCompanies, setTotalPagesCompanies] = useState(12);
  const ITEMS_PER_PAGE = 10;

  const [pageAnnouncement, setPageAnnouncement] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [companies, setCompanies] = useState<NoticeCompaniesData[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [announcements, setAnnouncements] = useState<AnnouncementData[]>([]);
  const [announcementsTotalPages, setAnnouncementsTotalPages] = useState(0);
  const ANNOUNCEMENTS_PER_PAGE = 10;

  const navigate = useNavigate();
  const { notice: noticeIdParam } = useParams();
  const noticeId = parseInt(noticeIdParam as string, 10);
  const { getErrorMessage } = useErrorMessage();
  const searchTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const fetchNoticeDetails = async (): Promise<void> => {
      try {
        setIsLoading(true);
        const response = await getNotice(noticeId);
        if (response && response.data) {
          setNotice(response?.data);
        }
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchNoticeDetails();
  }, [noticeId]);

  const fetchCompanies = async (search: string): Promise<void> => {
    try {
      setIsSearching(true);

      const response = await listNoticeCompanies(
        noticeId,
        pageCompanies,
        ITEMS_PER_PAGE,
        search
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      setCompanies(response.data || []);
      setTotalPagesCompanies(response.detail.total_pages || 0);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setIsSearching(false);
    }
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    setPageCompanies(1);

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      fetchCompanies(newSearchTerm);
    }, 600);
  };

  const { coverageData, getCoverage } = useFetchCoverageOperations({
    noticeId,
  });

  const { typologyGroups, fetchTypologyGroups } = useTypologyGroups(noticeId);

  useEffect(() => {
    fetchCompanies(searchTerm);
  }, [pageCompanies]);

  useEffect(() => {
    getCoverage();
  }, [getCoverage]);

  useEffect(() => {
    fetchTypologyGroups();
  }, [fetchTypologyGroups]);

  const navigateBack = (): void => navigate('/the-hive/notices');
  const navigateToEdit = (): void => navigate('./edit');
  const navigateToAddCompany = (): void => navigate('./add-company');

  const handleShowNewAnnouncementmodal = useCallback(() => {
    setShowNewAnnouncementmodal(true);
  }, []);

  const handleCloseNewAnnouncementmodal = useCallback(() => {
    setShowNewAnnouncementmodal(false);
  }, []);

  const handleShowTypologyModal = useCallback(() => {
    setShowTypologyModal(true);
  }, []);

  const handleCloseTypologyModal = useCallback(() => {
    setShowTypologyModal(false);
  }, []);

  const handleShowCoverageModal = useCallback(() => {
    setShowCoverageModal(true);
  }, []);

  const handleCloseCoverageModal = useCallback(() => {
    setShowCoverageModal(false);
  }, []);

  const fetchAnnouncements = async (): Promise<void> => {
    try {
      const response = await listAnnouncements(
        noticeId,
        pageAnnouncement,
        ANNOUNCEMENTS_PER_PAGE
      );
      if (response.data) {
        const transformedAnnouncements: AnnouncementData[] = response.data.map(
          (announcement) => ({
            id: announcement.id,
            subject: announcement.subject,
            contents: announcement.contents,
            is_visible: announcement.is_visible,
          })
        );
        setAnnouncements(transformedAnnouncements);
        setAnnouncementsTotalPages(response.detail.total_pages || 0);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleAnnouncementUpdated = useCallback(async () => {
    await fetchAnnouncements();
  }, [fetchAnnouncements]);

  useEffect(() => {
    if (noticeId) {
      fetchAnnouncements();
    }
  }, [noticeId, pageAnnouncement]);

  const formatDate = (dateString: string): string => {
    if (!dateString) return '';
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <BackButton onClick={navigateBack}>{IconArrowCircleLeftMS}</BackButton>
      <NoticeContainer>
        <Grid container spacing={3}>
          <Grid item>
            <NoticeTitle>{notice?.name}</NoticeTitle>
          </Grid>
          <Grid item>
            <NoticeAttachments noticeId={noticeId} />
          </Grid>
          <Grid item flex={1} display="flex" justifyContent="end">
            <OutlinedButton onClick={navigateToEdit}>editar</OutlinedButton>
          </Grid>
        </Grid>
        <Grid container spacing={3} mt="12px">
          <Grid item>
            <InputTitle>Vigência do edital (início):</InputTitle>
            <StyledInput>
              {notice?.start_date ? formatDate(notice.start_date) : ''}
            </StyledInput>
          </Grid>
          <Grid item>
            <InputTitle>Vigência do edital (final):</InputTitle>
            <StyledInput>
              {notice?.end_date ? formatDate(notice.end_date) : ''}
            </StyledInput>
          </Grid>
          <Grid item xs={12}>
            <InputTitle>Observação:</InputTitle>
            <StyledInput>{notice?.observations}</StyledInput>
          </Grid>
        </Grid>
      </NoticeContainer>
      <Grid item container justifyContent="start" mt={5}>
        <AddTypologies onClick={handleShowCoverageModal}>
          Adicionar cobertura
        </AddTypologies>
      </Grid>
      <CoverageAreaGrid data={coverageData} fetchCoverage={getCoverage} />
      <Grid item container justifyContent="start" mt={5}>
        <AddTypologies onClick={handleShowTypologyModal}>
          Adicionar serviços
        </AddTypologies>
      </Grid>
      <TypologyGroupGrid
        data={typologyGroups}
        fetchTypologyGroups={fetchTypologyGroups}
      />
      <TableSection>
        <Grid item container justifyContent="end" xs={12}>
          <AddButton onClick={handleShowNewAnnouncementmodal}>
            adicionar/excluir comunicado
          </AddButton>
        </Grid>
        <Grid item container mt={3}>
          <AnnouncementTable
            data={announcements}
            page={pageAnnouncement}
            totalPages={announcementsTotalPages}
            setPage={setPageAnnouncement}
            onAnnouncementUpdated={handleAnnouncementUpdated}
          />
        </Grid>
      </TableSection>
      <TableSection>
        <Grid item container justifyContent="end" xs={12} gap={4}>
          <Box display="flex" alignItems="center">
            <StyledInputBase
              placeholder="buscar empresa"
              inputProps={{ 'aria-label': 'pesquisar', maxLength: 14 }}
              value={searchTerm}
              onChange={handleSearchInputChange}
              startAdornment={
                <InputAdornment position="start" sx={{ color: '#004D43' }}>
                  {IconSearchMS}
                </InputAdornment>
              }
            />
          </Box>
          <AddButton onClick={navigateToAddCompany}>
            adicionar empresa
          </AddButton>
        </Grid>
        <Grid item container mt={3}>
          <CompaniesTable
            data={companies}
            page={pageCompanies}
            totalPages={totalPagesCompanies}
            setPage={setPageCompanies}
            isLoading={isSearching}
          />
        </Grid>
      </TableSection>
      <ModalAddingCoverage
        isModalOpen={showCoverageModal}
        onCloseModal={handleCloseCoverageModal}
        title="Área de cobertura"
        fetchCoverage={getCoverage}
      />
      <ModalAddTypology
        typologyGroups={typologyGroups}
        isModalOpen={showTypologyModal}
        onCloseModal={handleCloseTypologyModal}
        title="Adicionar serviços"
        fetchTypologyGroups={fetchTypologyGroups}
      />
      <NewAnnouncementModal
        isModalOpen={showNewAnnouncementmodal}
        onCloseModal={handleCloseNewAnnouncementmodal}
        title="Novo comunicado"
        onAnnouncementUpdated={handleAnnouncementUpdated}
        setAnnouncements={setAnnouncements}
        announcements={announcements}
      />
    </>
  );
}
