/* eslint-disable max-lines */

export enum ArchivedReason {
  FINISHED = 1,
  CANCELED,
}

export const convertArchivedReason = (
  archivedReason: ArchivedReason | undefined | null
): string => {
  let word = '';
  switch (archivedReason) {
    case ArchivedReason.FINISHED:
      word = 'finalizado';
      break;
    case ArchivedReason.CANCELED:
      word = 'cancelado';
      break;
    default:
      word = '';
  }
  return word;
};

export enum ClientKind {
  SELECT,
  CORPORATE,
  BRASIL_BANK,
  LANDING,
  CAIXA,
  BNB = 21,
  INSPECTION = 6,
  OTHER_BANK = 5,
}

export const convertClientKind = (clientKind: ClientKind): string => {
  let word = '';
  switch (clientKind) {
    case ClientKind.SELECT:
      word = 'selecione uma opção';
      break;
    case ClientKind.CORPORATE:
      word = 'empresarial';
      break;
    case ClientKind.BRASIL_BANK:
      word = 'Banco do Brasil';
      break;
    case ClientKind.LANDING:
      word = 'particular';
      break;
    case ClientKind.CAIXA:
      word = 'Caixa';
      break;
    case ClientKind.BNB:
      word = 'BNB';
      break;
    case ClientKind.OTHER_BANK:
      word = 'outros bancos';
      break;
    case ClientKind.INSPECTION:
      word = 'Vistoria';
      break;
    default:
      word = '';
  }
  return word;
};

export enum Concept {
  SELECT,
  URBAN,
  RURAL,
}

export const convertConcept = (enumType: Concept): string => {
  let word: string;
  switch (enumType) {
    case Concept.SELECT:
      word = 'selecione uma opção';
      break;
    case Concept.URBAN:
      word = 'urbano';
      break;
    case Concept.RURAL:
      word = 'rural';
      break;
    default:
      word = '';
  }
  return word;
};

export enum ConservationState {
  SELECT,
  NEW,
  NEWREGULAR,
  REGULAR,
  REGULARREPAIR,
  EASYREPAIR,
  REPAIREASYBIG,
  BIGREPAIR,
  REPAIRBIGNOVALUE,
  NOVALUE,
}

export const convertConservationState = (
  enumType: ConservationState
): string => {
  let word: string;
  switch (enumType) {
    case ConservationState.SELECT:
      word = 'selecione uma opção';
      break;
    case ConservationState.NEW:
      word = 'novo';
      break;
    case ConservationState.NEWREGULAR:
      word = 'entre novo e regular';
      break;
    case ConservationState.REGULAR:
      word = 'regular';
      break;
    case ConservationState.REGULARREPAIR:
      word = 'regular à reparos simples';
      break;
    case ConservationState.EASYREPAIR:
      word = 'reparos simples';
      break;
    case ConservationState.REPAIREASYBIG:
      word = 'reparos simples à importantes';
      break;
    case ConservationState.BIGREPAIR:
      word = 'reparos importantes';
      break;
    case ConservationState.REPAIRBIGNOVALUE:
      word = 'reparos importantes à edificação sem valor';
      break;
    case ConservationState.NOVALUE:
      word = 'sem valor';
      break;
    default:
      word = '';
  }
  return word;
};

export enum CubPropertyType {
  SELECT,
  HOUSE,
  APARTMENT,
  FREEFLOOR,
  STORE,
  INDUSTRIAL,
}

export const convertCubPropertyType = (enumType: CubPropertyType): string => {
  let word: string;
  switch (enumType) {
    case CubPropertyType.SELECT:
      word = 'selecione uma opção';
      break;
    case CubPropertyType.HOUSE:
      word = 'casa';
      break;
    case CubPropertyType.APARTMENT:
      word = 'apartamento';
      break;
    case CubPropertyType.FREEFLOOR:
      word = 'andar livre';
      break;
    case CubPropertyType.STORE:
      word = 'salas e lojas';
      break;
    case CubPropertyType.INDUSTRIAL:
      word = 'industrial';
      break;
    default:
      word = '';
  }
  return word;
};

export enum EvaluationType {
  SELECT,
  AUTOFACTORS,
  AUTOINFERENCES,
  SIMPFACTORS,
  SIMPINFERENCES,
}

export const convertEvaluationType = (
  evaluationType: EvaluationType
): string => {
  let word: string;
  switch (evaluationType) {
    case EvaluationType.SELECT:
      word = 'selecione uma opção';
      break;
    case EvaluationType.AUTOFACTORS:
      word = 'Automático - Fatores';
      break;
    case EvaluationType.AUTOINFERENCES:
      word = 'Automático - Inferência';
      break;
    case EvaluationType.SIMPFACTORS:
      word = 'Simplificado - Fatores';
      break;
    case EvaluationType.SIMPINFERENCES:
      word = 'Simplificado - Inferência';
      break;
    default:
      word = '';
  }
  return word;
};

export enum EvaluationTypeBank {
  SELECT,
  SIMPINFERENCES = 4,
}

export const convertEvaluationTypeBank = (
  evaluationType: EvaluationTypeBank
): string => {
  let word: string;
  switch (evaluationType) {
    case EvaluationTypeBank.SELECT:
      word = 'selecione uma opção';
      break;
    case EvaluationTypeBank.SIMPINFERENCES:
      word = 'Simplificado - Inferência';
      break;
    default:
      word = '';
  }
  return word;
};

export enum EvaluationTypeLot {
  SELECT,
  AUTOINFERENCES = 2,
  SIMPINFERENCES = 4,
}

export const convertEvaluationTypeLot = (
  evaluationType: EvaluationTypeLot
): string => {
  let word: string;
  switch (evaluationType) {
    case EvaluationTypeLot.SELECT:
      word = 'selecione uma opção';
      break;
    case EvaluationTypeLot.AUTOINFERENCES:
      word = 'Inferência - Sem Vistoria';
      break;
    case EvaluationTypeLot.SIMPINFERENCES:
      word = 'Inferência - Com Vistoria';
      break;
    default:
      word = '';
  }
  return word;
};

export enum ProfileUsers {
  SELECT,
  ARCHITECT,
  ENGINEER,
  ADMIN,
}

export const convertProfileUsers = (profileUsers: ProfileUsers): string => {
  let word: string;
  switch (profileUsers) {
    case ProfileUsers.SELECT:
      word = 'selecione uma opção';
      break;
    case ProfileUsers.ARCHITECT:
      word = 'arquiteto';
      break;
    case ProfileUsers.ENGINEER:
      word = 'engenheiro';
      break;
    case ProfileUsers.ADMIN:
      word = 'admin';
      break;
    default:
      word = '';
  }
  return word;
};

export enum PropertyType {
  SELECT,
  APARTMENT,
  HOUSE,
  LOT = 5,
}

export const convertPropertyType = (enumType: PropertyType): string => {
  let word: string;
  switch (enumType) {
    case PropertyType.SELECT:
      word = 'selecione uma opção';
      break;
    case PropertyType.APARTMENT:
      word = 'apartamento';
      break;
    case PropertyType.HOUSE:
      word = 'casa';
      break;
    case PropertyType.LOT:
      word = 'terreno/lote';
      break;
    default:
      word = '';
  }
  return word;
};

export enum PropertyUse {
  SELECT,
  RESIDENCIAL,
  COMERCIAL,
  INDUSTRIAL,
  INSTITUTIONAL,
  MIXED,
}

export const convertPropertyUse = (enumType: PropertyUse): string => {
  let word: string;
  switch (enumType) {
    case PropertyUse.SELECT:
      word = 'selecione uma opção';
      break;
    case PropertyUse.RESIDENCIAL:
      word = 'residencial';
      break;
    case PropertyUse.COMERCIAL:
      word = 'comercial';
      break;
    case PropertyUse.INDUSTRIAL:
      word = 'industrial';
      break;
    case PropertyUse.INSTITUTIONAL:
      word = 'institucional';
      break;
    case PropertyUse.MIXED:
      word = 'misto';
      break;
    default:
      word = '';
  }
  return word;
};

export enum QuestionType {
  SELECT,
  SHORT_ANSWER,
  SINGLE_SELECTION,
  MULTIPLE_CHOICE,
  LIST,
  DATE,
  NUMERIC,
}

export const convertQuestionType = (enumType: QuestionType): string => {
  let word: string;
  switch (enumType) {
    case QuestionType.SELECT:
      word = 'selecione uma opção';
      break;
    case QuestionType.SHORT_ANSWER:
      word = 'resposta curta';
      break;
    case QuestionType.SINGLE_SELECTION:
      word = 'seleção única';
      break;
    case QuestionType.MULTIPLE_CHOICE:
      word = 'múltipla escolha';
      break;
    case QuestionType.LIST:
      word = 'lista suspensa';
      break;
    case QuestionType.DATE:
      word = 'data';
      break;
    case QuestionType.NUMERIC:
      word = 'resposta numérica';
      break;
    default:
      word = '';
  }
  return word;
};

export enum RegistrationUf {
  SELECT,
  AC,
  AL,
  AP,
  AM,
  BA,
  CE,
  DF,
  ES,
  GO,
  MA,
  MT,
  MS,
  MG,
  PA,
  PB,
  PR,
  PE,
  PI,
  RJ,
  RN,
  RS,
  RO,
  RR,
  SC,
  SP,
  SE,
  TO,
}

export const convertRegistrationUf = (enumType: RegistrationUf): string => {
  let word: string;
  switch (enumType) {
    case RegistrationUf.SELECT:
      word = 'selecione uma opção';
      break;
    case RegistrationUf.AC:
      word = 'AC';
      break;
    case RegistrationUf.AL:
      word = 'AL';
      break;
    case RegistrationUf.AP:
      word = 'AP';
      break;
    case RegistrationUf.AM:
      word = 'AM';
      break;
    case RegistrationUf.BA:
      word = 'BA';
      break;
    case RegistrationUf.CE:
      word = 'CE';
      break;
    case RegistrationUf.DF:
      word = 'DF';
      break;
    case RegistrationUf.ES:
      word = 'ES';
      break;
    case RegistrationUf.GO:
      word = 'GO';
      break;
    case RegistrationUf.MA:
      word = 'MA';
      break;
    case RegistrationUf.MT:
      word = 'MT';
      break;
    case RegistrationUf.MS:
      word = 'MS';
      break;
    case RegistrationUf.MG:
      word = 'MG';
      break;
    case RegistrationUf.PA:
      word = 'PA';
      break;
    case RegistrationUf.PB:
      word = 'PB';
      break;
    case RegistrationUf.PR:
      word = 'PR';
      break;
    case RegistrationUf.PE:
      word = 'PE';
      break;
    case RegistrationUf.PI:
      word = 'PI';
      break;
    case RegistrationUf.RJ:
      word = 'RJ';
      break;
    case RegistrationUf.RN:
      word = 'RN';
      break;
    case RegistrationUf.RS:
      word = 'RS';
      break;
    case RegistrationUf.RO:
      word = 'RO';
      break;
    case RegistrationUf.RR:
      word = 'RR';
      break;
    case RegistrationUf.SC:
      word = 'SC';
      break;
    case RegistrationUf.SP:
      word = 'SP';
      break;
    case RegistrationUf.SE:
      word = 'SE';
      break;
    case RegistrationUf.TO:
      word = 'TO';
      break;
    default:
      word = '';
  }
  return word;
};

export const ufAbbreviation: Record<RegistrationUf, string> = {
  [RegistrationUf.SELECT]: 'selecione',
  [RegistrationUf.AC]: 'AC',
  [RegistrationUf.AL]: 'AL',
  [RegistrationUf.AP]: 'AP',
  [RegistrationUf.AM]: 'AM',
  [RegistrationUf.BA]: 'BA',
  [RegistrationUf.CE]: 'CE',
  [RegistrationUf.DF]: 'DF',
  [RegistrationUf.ES]: 'ES',
  [RegistrationUf.GO]: 'GO',
  [RegistrationUf.MA]: 'MA',
  [RegistrationUf.MT]: 'MT',
  [RegistrationUf.MS]: 'MS',
  [RegistrationUf.MG]: 'MG',
  [RegistrationUf.PA]: 'PA',
  [RegistrationUf.PB]: 'PB',
  [RegistrationUf.PR]: 'PR',
  [RegistrationUf.PE]: 'PE',
  [RegistrationUf.PI]: 'PI',
  [RegistrationUf.RJ]: 'RJ',
  [RegistrationUf.RN]: 'RN',
  [RegistrationUf.RS]: 'RS',
  [RegistrationUf.RO]: 'RO',
  [RegistrationUf.RR]: 'RR',
  [RegistrationUf.SC]: 'SC',
  [RegistrationUf.SP]: 'SP',
  [RegistrationUf.SE]: 'SE',
  [RegistrationUf.TO]: 'TO',
};

export const ufAbbreviationToEnum = Object.fromEntries(
  Object.entries(ufAbbreviation).map(([key, value]) => [value, Number(key)])
) as Record<string, RegistrationUf>;

export enum ReportGoal {
  SELECT,
  MARKET_BUY,
  MARKET_TENANCY,
  REEDITION,
  LIQUIDATION,
  OTHERS,
}

export const convertReportGoal = (reportGoal: ReportGoal): string => {
  let word = '';
  switch (reportGoal) {
    case ReportGoal.SELECT:
      word = 'selecione uma opção';
      break;
    case ReportGoal.MARKET_BUY:
      word = 'valor de mercado de compra e venda';
      break;
    case ReportGoal.MARKET_TENANCY:
      word = 'valor de mercado de locação';
      break;
    case ReportGoal.REEDITION:
      word = 'custo de reedição';
      break;
    case ReportGoal.LIQUIDATION:
      word = 'valor de liquidação forçada';
      break;
    case ReportGoal.OTHERS:
      word = 'outros';
      break;
    default:
      word = '';
  }
  return word;
};

export enum ReportFunction {
  SELECT,
  TENANCY,
  LEASE,
  LENDING,
  ACQUISITION,
  DONATION,
  ALIENATION,
  GIVING_PAYMENT,
  EXCHANGE,
  GUARANTEE,
  ACCOUNTING,
  INSURANCE,
  AUCTION,
  ADJUDICATION,
  REPARATION,
  TAXATION,
  JUDICIAL,
  EXTRA_JUDICIAL,
  PRE_AUCTION,
  OTHERS,
}

export const convertReportFunction = (
  reportFunction: ReportFunction
): string => {
  let word = '';
  switch (reportFunction) {
    case ReportFunction.SELECT:
      word = 'selecione uma opção';
      break;
    case ReportFunction.TENANCY:
      word = 'locação';
      break;
    case ReportFunction.LEASE:
      word = 'arrendamento';
      break;
    case ReportFunction.LENDING:
      word = 'comodato';
      break;
    case ReportFunction.ACQUISITION:
      word = 'aquisição';
      break;
    case ReportFunction.DONATION:
      word = 'doação';
      break;
    case ReportFunction.ALIENATION:
      word = 'alienação';
      break;
    case ReportFunction.GIVING_PAYMENT:
      word = 'dação em pagamento';
      break;
    case ReportFunction.EXCHANGE:
      word = 'permuta';
      break;
    case ReportFunction.GUARANTEE:
      word = 'garantia';
      break;
    case ReportFunction.ACCOUNTING:
      word = 'fins contábeis';
      break;
    case ReportFunction.INSURANCE:
      word = 'seguro';
      break;
    case ReportFunction.AUCTION:
      word = 'arrematação';
      break;
    case ReportFunction.ADJUDICATION:
      word = 'adjudicação';
      break;
    case ReportFunction.REPARATION:
      word = 'indenização';
      break;
    case ReportFunction.TAXATION:
      word = 'tributação';
      break;
    case ReportFunction.EXTRA_JUDICIAL:
      word = 'extrajudicial';
      break;
    case ReportFunction.JUDICIAL:
      word = 'judicial';
      break;
    case ReportFunction.PRE_AUCTION:
      word = 'leilão';
      break;
    case ReportFunction.OTHERS:
      word = 'outros';
      break;
    default:
      word = '';
  }
  return word;
};

export enum ReportSource {
  ELEMENT = 1,
  REPORT,
}

export const convertReportSource = (enumType: ReportSource): string => {
  let word: string;
  switch (enumType) {
    case ReportSource.ELEMENT:
      word = 'elemento';
      break;
    case ReportSource.REPORT:
      word = 'relatório';
      break;
    default:
      word = '';
  }
  return word;
};

export enum Standard {
  SELECT,
  SIMPLE,
  NORMAL,
  HIGH,
}

export const convertStandard = (enumType: Standard): string => {
  let word: string;
  switch (enumType) {
    case Standard.SELECT:
      word = 'selecione uma opção';
      break;
    case Standard.SIMPLE:
      word = 'simples';
      break;
    case Standard.NORMAL:
      word = 'normal';
      break;
    case Standard.HIGH:
      word = 'alto';
      break;
    default:
      word = '';
  }
  return word;
};

export const convertStandard400K = (enumType: Standard): string => {
  let word: string;
  switch (enumType) {
    case Standard.SELECT:
      word = 'selecione uma opção';
      break;
    case Standard.SIMPLE:
      word = 'normal';
      break;
    case Standard.NORMAL:
      word = 'superior';
      break;
    case Standard.HIGH:
      word = 'fino';
      break;
    default:
      word = '';
  }
  return word;
};

export enum Status {
  ENTRANCE = 1,
  SCHEDULE,
  INSPECTION,
  SAMPLE_CREATION,
  CALCULATION,
  REPORT,
  REVISION,
  ARCHIVED,
  PEPT = 9,
}

export const convertStatus = (enumType: Status): string => {
  let word: string;
  switch (enumType) {
    case Status.ENTRANCE:
      word = 'entrada';
      break;
    case Status.SCHEDULE:
      word = 'agendamento';
      break;
    case Status.INSPECTION:
      word = 'em vistoria';
      break;
    case Status.SAMPLE_CREATION:
      word = 'criação de amostra';
      break;
    case Status.CALCULATION:
      word = 'processando cálculo';
      break;
    case Status.REPORT:
      word = 'elaboração de laudo';
      break;
    case Status.REVISION:
      word = 'revisão e aprovação';
      break;
    case Status.ARCHIVED:
      word = 'arquivado';
      break;
    case Status.PEPT:
      word = 'pendência técnica';
      break;
    default:
      word = '';
  }
  return word;
};

export const convertStatusUrl = (enumType: Status): string => {
  let word: string;
  switch (enumType) {
    case Status.ENTRANCE:
      word = 'edit';
      break;
    case Status.SCHEDULE:
      word = 'schedule';
      break;
    case Status.INSPECTION:
      word = 'inspection';
      break;
    case Status.SAMPLE_CREATION:
      word = 'sample';
      break;
    case Status.REPORT:
      word = 'report';
      break;
    case Status.REVISION:
      word = 'revision';
      break;
    case Status.PEPT:
      word = 'pept';
      break;
    case Status.CALCULATION:
      word = 'calculation';
      break;
    case Status.ARCHIVED:
      word = 'archived';
      break;
    default:
      word = '';
  }
  return word;
};

export enum StatusCode {
  OK = 0,
  ERROR = 3,
  NOT_FOUND = 4,
  PROCESSING = 5,
}

export enum YesOrNo {
  SELECT,
  YES,
  NO,
}

export const convertYesOrNo = (enumType: YesOrNo): string => {
  let word: string;
  switch (enumType) {
    case YesOrNo.SELECT:
      word = 'selecione uma opção';
      break;
    case YesOrNo.YES:
      word = 'sim';
      break;
    case YesOrNo.NO:
      word = 'não';
      break;
    default:
      word = '';
  }
  return word;
};

export enum WebsocketTypes {
  AVM_PROCESSED = 'avm-processed',
  NEW_MESSAGE = 'unread-hive-messages-count',
}
