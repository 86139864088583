import { CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TitleTypography = styled(Typography)(() => ({
  fontSize: '28px',
  fontWeight: '600',
  textTransform: 'uppercase',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  '& span': { fontSize: '26px', color: '#00B39B' },
}));

export const WhiteProgress = styled(CircularProgress)(() => ({
  color: '#FFFFFF',
}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '21px',
  fontWeight: '600',
  '& span': { color: theme.palette.primary.main },
}));

export const DialogTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '24px',
  fontWeight: '600',
  margin: '4px 0 20px',
  '& span': { color: theme.palette.primary.main },
}));

export const WhiteCircularProgress = styled(CircularProgress)(() => ({
  color: '#FFFFFF',
}));
