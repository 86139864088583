/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useCallback, useEffect } from 'react';

import { EvaluationType, Status, StatusCode } from '../../api/enumerations';
import { getSelectedInspectionPictures } from '../../api/inspections';
import { GalleryPictureData } from '../../api/pictures/types';
import { RoomData } from '../../api/rooms/types';
import { getSample } from '../../api/samples';
import { ElementData } from '../../api/samples/types';
import {
  checkHiveRequest,
  updateHiveRequestStatus,
} from '../../api/theHive/workOrders';
import { HiveRequestStatusProps } from '../../api/theHive/workOrders/types';
import { GetWorkOrder } from '../../api/workOrders';
import {
  AvmFactorsData,
  AvmInferenceData,
  WorkOrderData,
} from '../../api/workOrders/types';
import { GlobalContext } from '../../context/global';
import useErrorMessage from '../../hooks/useErrorMessage';
import useGeneral from '../../hooks/useGeneral';
import { useRooms } from '../../hooks/useRooms';

interface UseRevisionProps {
  propertyData: WorkOrderData | undefined;
  loadingPage: boolean;
  rooms: RoomData[];
  sampleData: ElementData[] | undefined;
  hasAvmReport: boolean;
  getDataCallback: () => Promise<void>;
  selectedPictures: GalleryPictureData[];
  handleUpdateStatus: (observation?: string) => Promise<void>;
  workerFinalized: boolean;
  loadingAccepted: boolean;
  loadingRejected: boolean;
  hiveAccepted: boolean;
}

export function useRevision(): UseRevisionProps {
  const [propertyData, setPropertyData] = useState<WorkOrderData>();
  const [sampleData, setSampleData] = useState<ElementData[]>();
  const [hasAvmReport, setHasAvmReport] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [selectedPictures, setSelectedPictures] = useState<
    GalleryPictureData[]
  >([]);

  const [loadingAccepted, setLoadingAccepted] = useState(false);
  const [loadingRejected, setLoadingRejected] = useState(false);
  const [workerFinalized, setWorkerFinalized] = useState<boolean>(false);
  const [hiveAccepted, setHiveAccepted] = useState<boolean>(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();
  const { osId, navigateHome } = useGeneral();
  const { handleRooms, rooms } = useRooms();

  const getSelectedPictures = async (inspectionId: number): Promise<void> => {
    try {
      const response = await getSelectedInspectionPictures(inspectionId);
      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }
      setSelectedPictures(response.data);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  };

  const checkHiveStatus = useCallback(async (): Promise<void> => {
    try {
      const response = await checkHiveRequest(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data.work_status === 'worker_finalized')
        setWorkerFinalized(true);
      if (response.data.work_status === 'hive_accepted') setHiveAccepted(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(getErrorMessage(error));
    }
  }, [osId]);

  const getDataCallback = useCallback(async () => {
    let getSamples = false;
    let isFactors = false;

    try {
      const response = await GetWorkOrder(osId);
      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (!response.data || response.data.status !== Status.REVISION) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      const avmData = response.data.avm_report as
        | AvmInferenceData
        | AvmFactorsData
        | null;

      if (avmData) {
        setHasAvmReport(true);
      }

      if (response.data.samples) {
        getSamples = true;
      }

      if (
        response.data.evaluation_type === EvaluationType.AUTOFACTORS ||
        response.data.evaluation_type === EvaluationType.SIMPFACTORS
      ) {
        isFactors = true;
      }

      if (response.data.inspection) {
        handleRooms(response.data.inspection.id);
        getSelectedPictures(response.data.inspection.id);
      }

      if (response.data.worker_company_id) checkHiveStatus();

      setPropertyData(response.data);
      setLoadingPage(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error, true));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }

    if (getSamples) {
      try {
        const response = await getSample(osId, 1, 105);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data) {
          throw new Error('A amostra não pode ser carregada.');
        }

        if (isFactors) {
          const filteredSamples: ElementData[] = response.data.items.filter(
            (data) => data.is_utilized
          );
          setSampleData(filteredSamples);
          return;
        }
        setSampleData(response.data.items);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setOpenSnackbar(true);
        setErrorMessage(true);
      }
    }
  }, [osId, setErrorMessage, setOpenSnackbar, setSnackbarMessage]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  const handleUpdateStatus = async (observation?: string): Promise<void> => {
    let data: HiveRequestStatusProps = {
      work_status: 'hive_accepted',
    };

    if (observation) {
      setLoadingRejected(true);
      data = {
        work_status: 'hive_rejected',
        work_status_observations: observation,
      };
    } else {
      setLoadingAccepted(true);
    }

    try {
      const response = await updateHiveRequestStatus(osId, data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Status atualizado com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      navigateHome();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setLoadingAccepted(false);
      setLoadingRejected(false);
    }
  };

  return {
    propertyData,
    loadingPage,
    rooms,
    sampleData,
    hasAvmReport,
    getDataCallback,
    selectedPictures,
    handleUpdateStatus,
    workerFinalized,
    loadingAccepted,
    loadingRejected,
    hiveAccepted,
  };
}
