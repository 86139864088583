import { Box, Button, Dialog, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: 16,
    backgroundColor: '#FAFCFF',
  },
}));

export const DialogButton = styled(Button)(({ theme }) => ({
  width: '250px',
  border: '2px solid #E96778',
  color: '#E2475C',
  backgroundColor: '#FAFCFF',
  fontWeight: '600',
  '&:hover': {
    backgroundColor: '#E96778',
  },
  '&:active': {
    backgroundColor: '#E96778',
  },
  [theme.breakpoints.down('lg')]: {
    minWidth: '180px',
    maxWidth: '200px',
  },
  [theme.breakpoints.down('xl')]: {
    width: 200,
    padding: 16,
  },
}));

export const DialogContainer = styled(Box)(() => ({
  minWidth: 600,
  minHeight: 200,
  padding: '40px 40px',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
}));

export const TextTypography = styled(Typography)(() => ({
  textAlign: 'center',
  fontSize: '22px',
  fontWeight: '600',
  color: '#595959',
}));

export const AlertTypography = styled(Typography)(() => ({
  color: '#595959',
  textAlign: 'center',
}));

export const TextArea = styled('textarea')(() => ({
  width: '100%',
  minHeight: 150,
  font: '400 16px Montserrat',
  borderRadius: 10,
  outlineColor: '#004D43',
  padding: 16,
  color: '#595959',
}));

export const ButtonBox = styled(Box)(() => ({
  margin: '20px 0',
  display: 'flex',
  gap: '40px',
  justifyContent: 'center',
}));

export const OutlinedButton = styled(Button)(() => ({
  maxWidth: '150px',
  border: '1.5px solid #E96778',
  color: '#E96778',
  backgroundColor: '#FAFCFF',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#E96778',
    filter: 'brightness(85%)',
  },
  '&:active': {
    backgroundColor: '#E96778',
    filter: 'brightness(85%)',
  },
}));

export const FilledButton = styled(Button)(() => ({
  maxWidth: '150px',
  backgroundColor: '#E96778',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#E96778',
    filter: 'brightness(85%)',
  },
  '&:active': {
    backgroundColor: '#E96778',
    filter: 'brightness(85%)',
  },
}));
