/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { Box, CircularProgress, Typography } from '@mui/material';
import { useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { EvaluationType, StatusCode } from '../../api/enumerations';
import { PreviousStatus } from '../../api/workOrders';
import { AvmFactorsData, AvmInferenceData } from '../../api/workOrders/types';
import { AllAttachments } from '../../components/AllAttachments';
import ConfirmationDeleteDialog from '../../components/ConfirmationDeleteDialog';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { MessagesDialog } from '../../components/Messages';
import { PeptDialog } from '../../components/PeptDialog';
import { AccordionTitle } from '../../components/Sections/AccordionTitle';
import { ClientData } from '../../components/Sections/ClientData';
import { FormResponse } from '../../components/Sections/FormResponse';
import { PropertyAddressWithMaps } from '../../components/Sections/PropertyAddressWithMaps';
import { PropertyData } from '../../components/Sections/PropertyData';
import { PropertyInfo } from '../../components/Sections/PropertyInfo';
import { ReportInfo } from '../../components/Sections/ReportInfo';
import { Rooms } from '../../components/Sections/Rooms';
import { SampleSection } from '../../components/Sections/Sample';
import { ScheduleInfo } from '../../components/Sections/ScheduleInfo';
import { StatisticalReport } from '../../components/Sections/StatisticalReport';
import { Title } from '../../components/Sections/Title';
import { ToogleAccordion } from '../../components/Sections/ToogleAccordion';
import Snackbar from '../../components/Snackbar';
import {
  BoxContainer,
  CancelOsBox,
  FlexSpaceBetweenBox,
  LoadingBox,
} from '../../components/UI/Box';
import { GridContainer } from '../../components/UI/Grid';
import { WhiteProgress } from '../../components/UI/Typography';
import {
  IconApartmentMS,
  IconArrowCircleLeftMS,
  IconCalendarTodayMS,
  IconDescriptionMS,
  IconEditDocumentMS,
  IconEngineeringMS,
  IconFactCheckMS,
  IconListAltMS,
  IconLocationCityMS,
  IconLocationOnMS,
  IconPhotoLibraryMS,
} from '../../constants/icons';
import { Constants } from '../../constants/report';
import { GlobalContext } from '../../context/global';
import { useAccordion } from '../../hooks/useAccordion';
import { useCancelWorkOrder } from '../../hooks/useCancelWorkOrder';
import { useChangeStatus } from '../../hooks/useChangeStatus';
import useErrorMessage from '../../hooks/useErrorMessage';
import useGeneral from '../../hooks/useGeneral';
import { FactorsDescription } from './FactorsDescription';
import useReport from './hooks';
import { InferenceDescription } from './InferenceDescription';
import { InspectionSelectedPhotos } from './InspectionSelectedPhotos';
import {
  AvmStatusBox,
  BackButton,
  BackToSamplesButton,
  ContainerHeaderButtons,
  SectionBox,
  SubmitBox,
} from './styles';

export function Report(): JSX.Element {
  const isBank = process.env.REACT_APP_IS_BANK === 'true';

  const {
    propertyData,
    selectedPictures,
    removeSelectedPictures,
    getCharts,
    handleChangeStatus,
    loadingPage,
    avmStatus,
    forceFactors,
    forceTransformations,
    hasAvmReport,
    sampleData,
    rooms,
  } = useReport();

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandFour,
    setExpandFour,
    expandFive,
    setExpandFive,
    expandSix,
    setExpandSix,
    expandSeven,
    setExpandSeven,
    expandEight,
    setExpandEight,
    expandNine,
    setExpandNine,
    expandTen,
    setExpandTen,
    expandEleven,
    setExpandEleven,
    expandAll,
    setExpandAll,
    toggleAccordions,
  } = useAccordion();
  const { handleCancelWorkOrder } = useCancelWorkOrder();
  const { loadingApprove, loadingPreviousStatus } = useChangeStatus();
  const { getErrorMessage } = useErrorMessage();
  const { osId, navigateHome } = useGeneral();

  const navigate = useNavigate();

  useEffect(() => {
    if (
      expandOne &&
      expandTwo &&
      expandThree &&
      expandFour &&
      expandFive &&
      expandSix &&
      expandSeven &&
      expandEight &&
      expandNine &&
      expandTen &&
      expandEleven
    ) {
      setExpandAll(true);
    } else {
      setExpandAll(false);
    }
  }, [
    expandOne,
    expandTwo,
    expandThree,
    expandFour,
    expandFive,
    expandSix,
    expandSeven,
    expandEight,
    expandNine,
    expandTen,
    expandEleven,
  ]);

  useEffect(() => {
    if (propertyData && propertyData.task_id !== null) {
      setExpandEight(true);
    }
  }, [propertyData]);

  const redirectToPreviousStatus = useCallback(async () => {
    try {
      const response = await PreviousStatus(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setTimeout(() => {
        navigate(-1);
      }, 2000);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  }, []);

  return (
    <GridContainer>
      <BackButton onClick={navigateHome}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer>
        <Title
          osNumber={propertyData?.reference_number || 0}
          title={Constants.reportPreparation}
          createdAt={propertyData?.created_at}
        />
        {loadingPage ? (
          <LoadingBox>
            <CircularProgress />
          </LoadingBox>
        ) : (
          <>
            <FlexSpaceBetweenBox>
              <CancelOsBox>
                <ConfirmationDeleteDialog
                  iconCancel
                  title={Constants.cancelOs}
                  text={Constants.cancelOsText}
                  modalCallback={handleCancelWorkOrder}
                />
              </CancelOsBox>
              <Box display="flex" gap={1.2}>
                {isBank &&
                  propertyData &&
                  propertyData?.worker_company_id !== null && (
                    <MessagesDialog
                      osId={osId}
                      hiveRequest={propertyData?.hive_request || undefined}
                    />
                  )}
                <AllAttachments propertyData={propertyData} osId={osId} />
              </Box>{' '}
            </FlexSpaceBetweenBox>
            {avmStatus.length > 0 && (
              <AvmStatusBox>
                {avmStatus}
                {Constants.avmFailedHint}
              </AvmStatusBox>
            )}
            <ClientData propertyData={propertyData} />
            <Box>
              <ToogleAccordion expand={expandAll} toogle={toggleAccordions} />
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyData}
                  icon={IconApartmentMS}
                  openAccordion={expandOne}
                  setOpenAccordion={setExpandOne}
                />
                {expandOne && <PropertyData propertyData={propertyData} />}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyAddress}
                  icon={IconLocationOnMS}
                  openAccordion={expandTwo}
                  setOpenAccordion={setExpandTwo}
                />
                {expandTwo && (
                  <PropertyAddressWithMaps
                    checkLocation
                    propertyData={propertyData}
                  />
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyDetails}
                  icon={IconLocationCityMS}
                  openAccordion={expandThree}
                  setOpenAccordion={setExpandThree}
                />
                {expandThree && <PropertyInfo propertyData={propertyData} />}
              </SectionBox>
              {propertyData?.evaluation_type !== EvaluationType.AUTOFACTORS &&
                propertyData?.evaluation_type !==
                  EvaluationType.AUTOINFERENCES && (
                  <SectionBox>
                    <AccordionTitle
                      title={Constants.scheduleInfo}
                      icon={IconCalendarTodayMS}
                      openAccordion={expandFour}
                      setOpenAccordion={setExpandFour}
                    />
                    {expandFour && (
                      <Box>
                        {propertyData?.inspection ? (
                          <ScheduleInfo
                            inspectionData={propertyData.inspection}
                            rooms={rooms}
                          />
                        ) : (
                          <Typography>{Constants.noInspection}</Typography>
                        )}
                      </Box>
                    )}
                  </SectionBox>
                )}
              <SectionBox>
                <AccordionTitle
                  title={Constants.propertyPhotos}
                  icon={IconPhotoLibraryMS}
                  openAccordion={expandFive}
                  setOpenAccordion={setExpandFive}
                />
                {expandFive && (
                  <Box>
                    {propertyData?.inspection && rooms.length > 0 ? (
                      <Rooms
                        navigationPath={`${osId}/inspection/${propertyData.inspection.id}/rooms`}
                        rooms={rooms}
                        osId={osId}
                        inspectionId={propertyData.inspection.id}
                      />
                    ) : (
                      <Typography>{Constants.noPhotos}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.form}
                  icon={IconListAltMS}
                  openAccordion={expandSix}
                  setOpenAccordion={setExpandSix}
                />
                {expandSix && (
                  <Box>
                    {propertyData?.inspection?.form_response ? (
                      <FormResponse
                        questionForm={propertyData.inspection.form_response}
                      />
                    ) : (
                      <Typography>{Constants.noForm}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title={Constants.sample}
                  icon={IconFactCheckMS}
                  openAccordion={expandSeven}
                  setOpenAccordion={setExpandSeven}
                />
                {expandSeven && (
                  <Box>
                    {sampleData && sampleData?.length > 0 ? (
                      <SampleSection
                        sampleData={sampleData}
                        propertyData={propertyData}
                      />
                    ) : (
                      <Typography>{Constants.noSample}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              {propertyData?.task_id !== null && (
                <SectionBox>
                  <AccordionTitle
                    title={Constants.report}
                    icon={IconEngineeringMS}
                    openAccordion={expandEight}
                    setOpenAccordion={setExpandEight}
                  />
                  {expandEight && (
                    <Box>
                      {hasAvmReport && propertyData ? (
                        <ReportInfo
                          propertyData={propertyData}
                          getCharts={getCharts}
                          forceFactors={forceFactors}
                          forceTransformations={forceTransformations}
                        />
                      ) : (
                        <Box>{Constants.reportError}</Box>
                      )}
                    </Box>
                  )}
                </SectionBox>
              )}
              <SectionBox id="statistical-report">
                <AccordionTitle
                  title="Relatório estatístico"
                  icon={IconDescriptionMS}
                  openAccordion={expandNine}
                  setOpenAccordion={setExpandNine}
                />
                {expandNine && propertyData && (
                  <StatisticalReport
                    propertyData={propertyData}
                    rooms={rooms}
                    selectedPictures={selectedPictures}
                    removeSelectedPictures={removeSelectedPictures}
                    readOnly
                  />
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title="Campos complementares"
                  icon={IconEditDocumentMS}
                  openAccordion={expandTen}
                  setOpenAccordion={setExpandTen}
                />
                {expandTen && (
                  <Box>
                    {hasAvmReport ? (
                      propertyData?.evaluation_type ===
                        EvaluationType.AUTOINFERENCES ||
                      propertyData?.evaluation_type ===
                        EvaluationType.SIMPINFERENCES ? (
                        <InferenceDescription
                          tableData={
                            propertyData?.avm_report as AvmInferenceData
                          }
                          osId={osId}
                        />
                      ) : (
                        <FactorsDescription
                          tableData={propertyData?.avm_report as AvmFactorsData}
                          osId={osId}
                        />
                      )
                    ) : (
                      <Box>{Constants.reportError}</Box>
                    )}
                  </Box>
                )}
              </SectionBox>
              <SectionBox>
                <AccordionTitle
                  title="Imagens vistoria"
                  icon={IconPhotoLibraryMS}
                  openAccordion={expandEleven}
                  setOpenAccordion={setExpandEleven}
                />
                {expandEleven && (
                  <Box>
                    {propertyData?.inspection?.id && rooms.length > 0 ? (
                      <InspectionSelectedPhotos
                        selectedPhotos={selectedPictures}
                        inspectionId={propertyData?.inspection?.id}
                        removeSelection={removeSelectedPictures}
                      />
                    ) : (
                      <Typography>{Constants.noPhotos}</Typography>
                    )}
                  </Box>
                )}
              </SectionBox>
              {!isBank && (
                <SubmitBox>
                  <ContainerHeaderButtons>
                    <BackToSamplesButton
                      disabled={loadingPreviousStatus}
                      onClick={redirectToPreviousStatus}
                    >
                      {loadingPreviousStatus ? (
                        <WhiteProgress size={22} />
                      ) : (
                        Constants.backToSample
                      )}
                    </BackToSamplesButton>
                    <PeptDialog
                      osId={osId}
                      referenceNumber={propertyData?.reference_number}
                    />
                  </ContainerHeaderButtons>
                  <ConfirmationDialog
                    title={Constants.approve}
                    loading={loadingApprove}
                    text={Constants.changeStatus.replace(
                      '**',
                      `${propertyData?.reference_number}`
                    )}
                    modalCallback={handleChangeStatus}
                  />
                </SubmitBox>
              )}
            </Box>
          </>
        )}
      </BoxContainer>
      {openSnackbar && <Snackbar />}
    </GridContainer>
  );
}
