/* eslint-disable react-hooks/exhaustive-deps */
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Tooltip,
} from '@mui/material';

import { PropertyType } from '../../../../api/enumerations';
import { CondominiumInformationProps } from '../../../../api/theHive/statisticalReport/types';
import {
  StyledCheckbox,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../styles';
import {
  fireProtection,
  infrastructureServicesAndEquipmentsComponents,
  mainFacadeComponents,
} from './utils';

interface CondominiumProps {
  propertyType: PropertyType;
  doesNotApply: number;
  inputs: CondominiumInformationProps;
  readOnly: boolean;
}

export function Condominium({
  propertyType,
  doesNotApply,
  inputs,
  readOnly,
}: CondominiumProps): JSX.Element {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={readOnly}
          label="não se aplica"
          value={doesNotApply}
        >
          <StyledMenuItem value={0}>selecione uma opção</StyledMenuItem>
          <StyledMenuItem value={1}>sim</StyledMenuItem>
          <StyledMenuItem value={2}>não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      {propertyType === PropertyType.HOUSE ? (
        <>
          <Grid item xs={3}>
            <Tooltip
              arrow
              placement="top"
              title="número de unidades de condomínio"
            >
              <StyledTextField
                disabled={readOnly}
                type="number"
                label="número de unidades de condomínio"
                value={inputs.number_of_units}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              disabled={readOnly}
              type="number"
              label="área do condomínio"
              value={inputs.area}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={3}>
            <StyledSelect
              select
              disabled={readOnly}
              label="esquadrias"
              value={inputs.framing}
            >
              <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
              <StyledMenuItem value="alumínio">alumínio</StyledMenuItem>
              <StyledMenuItem value="ferro">ferro</StyledMenuItem>
              <StyledMenuItem value="madeira">madeira</StyledMenuItem>
              <StyledMenuItem value="PVC">PVC</StyledMenuItem>
              <StyledMenuItem value="vidro temperado">
                vidro temperado
              </StyledMenuItem>
              <StyledMenuItem value="outros">outros</StyledMenuItem>
            </StyledSelect>
          </Grid>
          <Grid item xs={3}>
            <StyledSelect
              select
              disabled={readOnly}
              label="revestimento"
              value={inputs.wall_covering}
            >
              <StyledMenuItem disabled value="">
                selecione uma opção
              </StyledMenuItem>
              <StyledMenuItem value="pintura">pintura</StyledMenuItem>
            </StyledSelect>
          </Grid>
          <Grid item xs={3}>
            <StyledSelect
              select
              disabled={readOnly}
              label="localização"
              value={inputs.location_status_level}
            >
              <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
              <StyledMenuItem value="boa">boa</StyledMenuItem>
              <StyledMenuItem value="regular">regular</StyledMenuItem>
              <StyledMenuItem value="ruim">ruim</StyledMenuItem>
              <StyledMenuItem value="ótima">ótima</StyledMenuItem>
            </StyledSelect>
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              disabled={readOnly}
              type="number"
              label="número de pavimentos"
              value={inputs.number_of_floors}
            />
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              disabled={readOnly}
              type="number"
              label="número de unidades por andar"
              value={inputs.number_of_units_per_floor}
            />
          </Grid>
          <Grid item xs={3}>
            <StyledSelect
              select
              disabled={readOnly}
              label="posição"
              value={inputs.appraisal_position}
            >
              <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
              <StyledMenuItem value="isolada/ frente do terreno">
                isolada/ frente do terreno
              </StyledMenuItem>
              <StyledMenuItem value="isolada/ centro do terreno">
                isolada/ centro do terreno
              </StyledMenuItem>
              <StyledMenuItem value="geminada em uma lateral">
                geminada em uma lateral
              </StyledMenuItem>
              <StyledMenuItem value="geminada nas laterais">
                geminada nas laterais
              </StyledMenuItem>
              <StyledMenuItem value="juntos aos fundos">
                juntos aos fundos
              </StyledMenuItem>
              <StyledMenuItem value="junto a uma lateral">
                junto a uma lateral
              </StyledMenuItem>
              <StyledMenuItem value="junto às laterais">
                junto às laterais
              </StyledMenuItem>
              <StyledMenuItem value="ocupa todo o terreno">
                ocupa todo o terreno
              </StyledMenuItem>
            </StyledSelect>
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              disabled={readOnly}
              type="number"
              label="idade estimada"
              value={inputs.age}
            />
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              disabled={readOnly}
              type="number"
              label="quantidade de blocos"
              value={inputs.number_of_blocks}
            />
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              disabled={readOnly}
              type="number"
              label="número de elevadores"
              value={inputs.number_of_elevators}
            />
          </Grid>
          <Grid item xs={3}>
            <StyledSelect
              select
              disabled={readOnly}
              label="uso"
              value={inputs.use_type}
            >
              <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
              <StyledMenuItem value="comercial">comercial</StyledMenuItem>
              <StyledMenuItem value="residencial">residencial</StyledMenuItem>
              <StyledMenuItem value="misto">misto</StyledMenuItem>
            </StyledSelect>
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              disabled={readOnly}
              type="number"
              label="número de unidades"
              value={inputs.number_of_units}
            />
          </Grid>
          <Grid item xs={3}>
            <StyledSelect
              select
              disabled={readOnly}
              label="padrão de acabamento"
              value={inputs.finishing_standard}
            >
              <StyledMenuItem disabled value="">
                selecione uma opção
              </StyledMenuItem>
              <StyledMenuItem value="alto">alto</StyledMenuItem>
              <StyledMenuItem value="baixo">baixo</StyledMenuItem>
              <StyledMenuItem value="mínimo">mínimo</StyledMenuItem>
              <StyledMenuItem value="normal/ médio">
                normal / médio
              </StyledMenuItem>
            </StyledSelect>
          </Grid>
        </>
      )}
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={readOnly}
          label="estado de conservação"
          value={inputs.preservation_state}
        >
          <StyledMenuItem>selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="bom">bom</StyledMenuItem>
          <StyledMenuItem value="regular">regular</StyledMenuItem>
          <StyledMenuItem value="ruim">ruim</StyledMenuItem>
          <StyledMenuItem value="em implantação">em implantação</StyledMenuItem>
        </StyledSelect>
      </Grid>
      {propertyType === PropertyType.HOUSE ? (
        <>
          <Grid item xs={12}>
            <FormLabel>proteção contra incêndio</FormLabel>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              disabled={readOnly}
              control={
                <Checkbox
                  value="não possui"
                  checked={inputs.fire_protection.includes('não possui')}
                />
              }
              label="não possui"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              disabled={readOnly}
              control={
                <Checkbox
                  value="alarme"
                  checked={inputs.fire_protection.includes('alarme')}
                />
              }
              label="alarme"
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <FormLabel>fachada principal</FormLabel>
          </Grid>
          {mainFacadeComponents.map((item) => (
            <Grid item xs={3} key={item.label}>
              <FormGroup>
                <FormControlLabel
                  disabled={readOnly}
                  control={
                    <StyledCheckbox
                      value={item.value}
                      checked={inputs.main_facade_components.includes(
                        item.value
                      )}
                    />
                  }
                  label={item.label}
                />
              </FormGroup>
            </Grid>
          ))}
          <Grid item xs={12}>
            <FormLabel>proteção contra incêndio</FormLabel>
          </Grid>
          {fireProtection.map((item) => (
            <Grid item xs={3} key={item.label}>
              <FormGroup>
                <FormControlLabel
                  disabled={readOnly}
                  control={
                    <Checkbox
                      value={item.value}
                      checked={inputs.fire_protection.includes(item.value)}
                    />
                  }
                  label={item.label}
                />
              </FormGroup>
            </Grid>
          ))}
          <Grid item xs={3}>
            <StyledTextField
              disabled={readOnly}
              label="outros"
              value={inputs.other_fire_protections}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <FormLabel>infraestrutura / serviços / equipamentos</FormLabel>
      </Grid>
      {infrastructureServicesAndEquipmentsComponents.map((item) => (
        <Grid item xs={3} key={item.label}>
          <FormGroup>
            <FormControlLabel
              disabled={readOnly}
              control={
                <StyledCheckbox
                  value={item.value}
                  checked={inputs.infrastructure_services_and_equipments_components.includes(
                    item.value
                  )}
                />
              }
              label={item.label}
            />
          </FormGroup>
        </Grid>
      ))}
    </Grid>
  );
}
