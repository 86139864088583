import { Grid } from '@mui/material';

import { StyledTextField } from '../styles';

interface AdditionalInformationProps {
  inputs: string;
  readOnly: boolean;
}

export function AdditionalInformation({
  inputs,
  readOnly,
}: AdditionalInformationProps): JSX.Element {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StyledTextField label="" disabled={readOnly} value={inputs} />
      </Grid>
    </Grid>
  );
}
