/* eslint-disable max-len */
import {
  Box,
  Checkbox,
  MenuItem,
  styled,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

export const ReportTitle = styled(Typography)(({ theme }) => ({
  fontSize: '21px',
  fontWeight: '600',
  color: '#707070',
  textTransform: 'uppercase',
  borderBottom: `2px solid ${theme.palette.primary.main}`,
  width: 'fit-content',
}));

export const SectionBox = styled(Box)(() => ({
  margin: '0px 30px',
}));

export const StyledTableRowBody = styled(TableRow)(() => ({
  '& input': {
    border: 'none',
    outline: 'none',
    width: '100%',
  },
}));

export const StyledTableCellBody = styled(TableCell)(() => ({
  border: '1px solid #CCC',
  paddingRight: 0,
  paddingLeft: 0,
  flexWrap: 'wrap',

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    outline: 'none',
  },
}));

export const StyledSelect = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-root': {
    borderRadius: '16px',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
    fontSize: '2rem',
  },
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  '&:hover': { backgroundColor: '#d485eed1' },
  '&:first-of-type': {
    color: '#7c7c7c',
    '&.Mui-selected': { backgroundColor: '#EDEDED' },
    '&:hover': {
      backgroundColor: '#EDEDED',
      cursor: 'auto',
    },
  },
}));

export const StyledTextField = styled(TextField)(() => ({
  width: '100%',
  '& .MuiInputBase-root': {
    whiteSpace: 'normal',
    borderRadius: '16px',
    textOverflow: 'clip',
    display: 'block',
  },
  'input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0px 1000px #F5F5F4 inset',
  },
  '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
    { '-webkit-appearance': 'none', margin: 0 },
  '& input[type=number]': { '-moz-appearance': 'textfield' },
}));

export const StyledCheckbox = styled(Checkbox)(() => ({
  padding: '4px',
  marginLeft: '5px',
}));

export const TableTextField = styled(TextField)(() => ({
  '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
    { WebkitAppearance: 'none', margin: 0 },
  '& input[type=number]': { MozAppearance: 'textfield' },
}));
