export const Constants = {
  revision: 'revisão e aprovação',
  cancelOs: 'cancelar OS',
  cancelOsText: 'cancelar esta OS',
  propertyData: 'Dados do imóvel',
  pdfReport: 'Relatório',
  propertyAddress: 'Localização do imóvel',
  propertyDetails: 'Características do imóvel',
  scheduleInfo: 'Informações sobre agendamento',
  noInspection: 'esta OS não possui agendamento',
  propertyPhotos: 'Imagens do imóvel',
  noPhotos: 'esta OS não possui imagens',
  noSample: 'esta OS não possui amostra',
  form: 'Formulário',
  report: 'Cálculo automatizado',
  noForm: 'esta OS não possui Formulário',
  sample: 'Amostra',
  noReport: 'Relatório não pode ser criado',
  sendAuto: 'Enviar para cliente automaticamente',
  approve: 'finalizar e enviar',
  reportError: 'reportar erro',
  changeStatus:
    'alterar o status da OS ** de Revisão e Aprovação para Entrega/Emissão de nota',
  download: 'download',
  downloadExcel: 'Exportar dados do cálculo e formulário',
  errorReport: 'reportar erro',
  rejectCompletion: 'rejeitar finalização da OS',
  approveCompletion: 'aprovar finalização da OS',
};
