import { Grid, Tooltip } from '@mui/material';
import { Fragment } from 'react';

import { VariablesData } from '../../../../api/theHive/statisticalReport/types';
import { StyledMenuItem, StyledSelect, StyledTextField } from '../styles';

interface VariablesProps {
  inputs: VariablesData[];
  readOnly: boolean;
}

export function Variables({ inputs, readOnly }: VariablesProps): JSX.Element {
  return (
    <Grid container spacing={2}>
      {inputs.map((input) => (
        <Fragment key={input.name}>
          <Grid item xs={3}>
            <StyledTextField
              disabled={readOnly}
              label="nome da variável"
              value={input.name}
              color="secondary"
            />
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              disabled={readOnly}
              label="descrição da variável"
              value={input.description}
              color="secondary"
            />
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              disabled={readOnly}
              label="significância da variável"
              value={input.significance_level}
              color="secondary"
              type="number"
            />
          </Grid>
          <Grid item xs={3}>
            <StyledSelect
              disabled={readOnly}
              select
              label="tipo da variável"
              value={input.type}
              color="secondary"
            >
              <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
              <StyledMenuItem value="dependente">dependente</StyledMenuItem>
              <StyledMenuItem value="independente">independente</StyledMenuItem>
            </StyledSelect>
          </Grid>
          <Grid item xs={4}>
            <StyledSelect
              disabled={readOnly}
              select
              label="crescimento da variável"
              color="secondary"
              value={input.growth}
            >
              <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
              <StyledMenuItem value="positiva">positiva</StyledMenuItem>
              <StyledMenuItem value="negativa">negativa</StyledMenuItem>
            </StyledSelect>
          </Grid>
          <Grid item xs={4}>
            <Tooltip
              arrow
              placement="top"
              title="atributo do avaliando para a variável"
            >
              <StyledTextField
                disabled={readOnly}
                label="atributo do avaliando para a variável"
                value={input.appraisal_attribute}
                color="secondary"
              />
            </Tooltip>
          </Grid>
          <Grid item xs={4}>
            <StyledTextField
              disabled={readOnly}
              label="amplitude da amostra"
              value={input.sample_extent}
              color="secondary"
            />
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
}
