/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import {
  Box,
  FormControl,
  LinearProgress,
  MenuItem,
  Pagination,
  TableContainer,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { StatusCode } from '../../api/enumerations';
import { getArchivedWorkOrders } from '../../api/workOrders';
import { WorkOrderData } from '../../api/workOrders/types';
import Snackbar from '../../components/Snackbar';
import { TitleBox } from '../../components/UI/Box';
import { GridMain } from '../../components/UI/Grid';
import { TitleTypography } from '../../components/UI/Typography';
import { IconArchiveMS } from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import useErrorMessage from '../../hooks/useErrorMessage';
import {
  PagesContainer,
  SearchBox,
  SearchButton,
  SelectPeriod,
  StyledInputBase,
} from './styles';
import { TableArchived } from './Table';

export function ArchivedFiles(): JSX.Element {
  const [period, setPeriod] = useState(0);
  const [search, setSearch] = useState('');
  const [pressSearch, setPressSearch] = useState(false);
  const [allArchivedOs, setAllArchivedOS] = useState<WorkOrderData[]>();

  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);

  const workOrderPerPage = 10;

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();

  const getDataCallback = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getArchivedWorkOrders(
        page,
        workOrderPerPage,
        search
      );
      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente');
      }
      if (response.data && response.data.total_pages) {
        setTotalPages(response.data.total_pages);
        setAllArchivedOS(response.data.items);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
      setPressSearch(false);
    }
  }, [page, search]);

  const handleNextPage = (pageNumber: number): void => {
    setPage(pageNumber);
  };

  useEffect(() => {
    getDataCallback();
  }, [page]);

  const resetData = (): void => {
    setAllArchivedOS([]);
    setPage(1);
    getDataCallback();
  };

  useEffect(() => {
    if (pressSearch) {
      resetData();
    }
  }, [pressSearch]);

  return (
    <GridMain>
      <TitleBox>
        <TitleTypography>
          {IconArchiveMS}
          arquivados
        </TitleTypography>
      </TitleBox>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <TableContainer>
          <SearchBox>
            <div>
              <StyledInputBase
                placeholder="procurar nesta página"
                inputProps={{ 'aria-label': 'pesquisar' }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <FormControl sx={{ width: 'auto', marginLeft: 8 }}>
                <SelectPeriod
                  defaultValue={0}
                  value={period}
                  onChange={(e) => setPeriod(Number(e.target.value))}
                >
                  <MenuItem value={0} disabled>
                    Período
                  </MenuItem>
                  <MenuItem value={15}>últimos 15 dias</MenuItem>
                  <MenuItem value={30}>últimos 30 dias</MenuItem>
                  <MenuItem value={90}>últimos 90 dias</MenuItem>
                  <MenuItem value={120}>últimos 120 dias</MenuItem>
                </SelectPeriod>
              </FormControl>
            </div>
            <SearchButton onClick={() => setPressSearch(true)}>
              buscar
            </SearchButton>
          </SearchBox>
          {loading ? (
            <Box sx={{ width: '100%', marginTop: 16 }}>
              <LinearProgress />
            </Box>
          ) : (
            <>
              <TableArchived tableData={allArchivedOs} />
              <PagesContainer>
                <Pagination
                  page={page}
                  count={totalPages}
                  onChange={(_, page) => handleNextPage(page)}
                  disabled={totalPages === 0}
                />
              </PagesContainer>
            </>
          )}
        </TableContainer>
      </Box>
      {openSnackbar && <Snackbar />}
    </GridMain>
  );
}
