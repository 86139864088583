import { Grid, TextFieldProps, Tooltip } from '@mui/material';
import {
  DesktopDatePicker,
  DesktopTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';

import { InspectionInfoData } from '../../../../api/theHive/statisticalReport/types';
import { StyledTextField } from '../../../../pages/Agenda/styles';
import { StyledTextField as TextField } from '../styles';

interface ReportProps {
  inputs: InspectionInfoData;
  readOnly: boolean;
}

export function ReportInspection({
  inputs,
  readOnly,
}: ReportProps): JSX.Element {
  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [endTime, setEndTime] = useState<Dayjs | null>(null);
  const [appointmentTime, setAppointmentTime] = useState<Dayjs | null>(null);
  const [dateOfContact, setDateOfContact] = useState<Dayjs | null>(null);
  const [scheduleDate, setScheduleDate] = useState<Dayjs | null>(null);
  const [inspectionDate, setInspectionDate] = useState<Dayjs | null>(null);

  useEffect(() => {
    setInspectionDate(dayjs(inputs.inspection_date));
    setDateOfContact(dayjs(inputs.contact_date));
    setStartTime(dayjs(inputs.inspection_start_time));
    setEndTime(dayjs(inputs.inspection_end_time));
    setScheduleDate(dayjs(inputs.inspection_schedule_date));
    setAppointmentTime(dayjs(inputs.inspection_schedule_time));
  }, [inputs]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Tooltip
          arrow
          placement="top"
          title="nome do contato para agendamento da vistoria"
        >
          <TextField
            disabled={readOnly}
            label="nome do contato para agendamento da vistoria"
            value={inputs.contact_name}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <TextField
          disabled={readOnly}
          label="telefone do contato"
          value={inputs.contact_phone}
        />
      </Grid>
      <Grid item xs={3}>
        <Tooltip
          arrow
          placement="top"
          title="nome da pessoa que possa atestar o fato"
        >
          <TextField
            label="nome da pessoa que possa atestar o fato"
            value={inputs.witness_name}
            disabled={readOnly}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <Tooltip
          arrow
          placement="top"
          title="telefone da pessoa que possa atestar o fato"
        >
          <TextField
            label="telefone da pessoa que possa atestar o fato"
            value={inputs.witness_phone}
            disabled={readOnly}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            disabled={readOnly}
            label="data do contato"
            format="DD-MM-YYYY"
            value={dateOfContact}
            slots={{
              textField: StyledTextField as React.ComponentType<TextFieldProps>,
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            disabled={readOnly}
            label="data do agendamento da vistoria"
            format="DD-MM-YYYY"
            value={scheduleDate}
            slots={{
              textField: StyledTextField as React.ComponentType<TextFieldProps>,
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopTimePicker
            disabled={readOnly}
            label="hora do agendamento da vistoria"
            ampm={false}
            value={appointmentTime}
            slots={{
              textField: StyledTextField as React.ComponentType<TextFieldProps>,
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            disabled={readOnly}
            label="data da vistoria"
            format="DD-MM-YYYY"
            value={inspectionDate}
            slots={{
              textField: StyledTextField as React.ComponentType<TextFieldProps>,
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopTimePicker
            disabled={readOnly}
            label="hora da chegada"
            ampm={false}
            value={startTime}
            slots={{
              textField: StyledTextField as React.ComponentType<TextFieldProps>,
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopTimePicker
            disabled={readOnly}
            label="hora da saída"
            ampm={false}
            value={endTime}
            slots={{
              textField: StyledTextField as React.ComponentType<TextFieldProps>,
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12}>
        <TextField
          disabled={readOnly}
          label="histórico de vistorias infrutíferas, caso houver"
          value={inputs.unsuccessful_inspection_history}
        />
      </Grid>
    </Grid>
  );
}
