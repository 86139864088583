import { Table, TableBody, TableHead, TablePagination } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { convertStatusUrl } from '../../../api/enumerations';
import { LastMessagesData } from '../../../api/theHive/messages/types';
import { GetWorkOrder } from '../../../api/workOrders';
import { Constants } from '../../../constants/notifications';
import { GlobalContext } from '../../../context/global';
import { convertDate } from '../../../helpers';
import useErrorMessage from '../../../hooks/useErrorMessage';
import { Cell, Row, StyledTableCell, StyledTableContainer } from './styles';

interface NotificationsTableProps {
  tableData: LastMessagesData[];
  page: number;
  setPage: (value: number) => void;
  totalPages: number;
  rowsPerPage: number;
}

export function NotificationsTable({
  tableData,
  page,
  setPage,
  totalPages,
  rowsPerPage,
}: NotificationsTableProps): JSX.Element {
  const navigate = useNavigate();
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  const getWorkOrderData = async (osId: number): Promise<void> => {
    try {
      const response = await GetWorkOrder(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.data) {
        const status = convertStatusUrl(response.data.status);
        navigate(`/home/property/${response.data?.id}/${status}`);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <StyledTableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <Row>
            <Cell align="center" width="15%" sx={{ borderTopLeftRadius: 8 }}>
              {Constants.entranceDate}
            </Cell>
            <Cell align="center" width="15%">
              {Constants.osNumber}
            </Cell>
            <Cell align="center" width="15%">
              {Constants.company}
            </Cell>
            <Cell align="center" sx={{ borderTopRightRadius: 8 }} />
          </Row>
        </TableHead>
        <TableBody>
          {tableData.map((rowData) => (
            <Row
              key={rowData.id}
              onClick={() => getWorkOrderData(rowData.work_order.id)}
            >
              <StyledTableCell align="center">
                {convertDate(rowData.created_at)}
              </StyledTableCell>
              <StyledTableCell align="center">
                {rowData.work_order.reference_number}
              </StyledTableCell>
              <StyledTableCell align="center">
                {rowData.worker_company.name}
              </StyledTableCell>
              <StyledTableCell align="center">
                {rowData.raw_content || ''}
              </StyledTableCell>
            </Row>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10]}
        count={totalPages}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        labelRowsPerPage="Qtd por página"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count}`
        }
      />
    </StyledTableContainer>
  );
}
