import { Grid, Tooltip } from '@mui/material';
import { Fragment } from 'react';

import {
  ConstructionData,
  ImprovementsData,
} from '../../../../api/theHive/statisticalReport/types';
import { StyledMenuItem, StyledSelect, StyledTextField } from '../styles';

interface ImprovementsContruction {
  constructionInputs: ConstructionData;
  improvementsInputs: ImprovementsData;
  doesNotApply: number;
  readOnly: boolean;
}

export function ImprovementsContruction({
  constructionInputs,
  improvementsInputs,
  doesNotApply,
  readOnly,
}: ImprovementsContruction): JSX.Element {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={readOnly}
          label="não se aplica"
          value={doesNotApply}
        >
          <StyledMenuItem value={0}>selecione uma opção</StyledMenuItem>
          <StyledMenuItem value={1}>sim</StyledMenuItem>
          <StyledMenuItem value={2}>não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={readOnly}
          label="estado de conservação imóvel avaliando"
          value={improvementsInputs.appraisal_preservation_state}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="bom">bom</StyledMenuItem>
          <StyledMenuItem value="em construção">em construção</StyledMenuItem>
          <StyledMenuItem value="regular">regular</StyledMenuItem>
          <StyledMenuItem value="ruim">ruim</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={6} />
      {improvementsInputs.improvements.map((item) => (
        <Fragment key={item.name}>
          <Grid item xs={2.5}>
            <StyledSelect
              select
              disabled={readOnly}
              label="benfeitoria / divisão intera"
              value={item.name}
            >
              <StyledMenuItem disabled value="">
                selecione uma opção
              </StyledMenuItem>
              <StyledMenuItem value="alvenaria">alvenaria</StyledMenuItem>
              <StyledMenuItem value="dry-wall">dry wall</StyledMenuItem>
              <StyledMenuItem value="madeira">madeira</StyledMenuItem>
              <StyledMenuItem value="misto">misto</StyledMenuItem>
            </StyledSelect>
          </Grid>
          <Grid item xs={2}>
            <StyledTextField
              disabled={readOnly}
              label="área"
              type="number"
              value={item.area}
            />
          </Grid>
          <Grid item xs={2.5}>
            <StyledSelect
              select
              disabled={readOnly}
              label="padrão de acabamento"
              value={item.finishing_standard}
            >
              <StyledMenuItem disabled value="">
                selecione uma opção
              </StyledMenuItem>
              <StyledMenuItem value="alto">alto</StyledMenuItem>
              <StyledMenuItem value="baixo">baixo</StyledMenuItem>
              <StyledMenuItem value="mínimo">mínimo</StyledMenuItem>
              <StyledMenuItem value="normal/ médio">
                normal / médio
              </StyledMenuItem>
            </StyledSelect>
          </Grid>
          <Grid item xs={2}>
            <StyledTextField
              disabled={readOnly}
              label="idade aparente"
              value={item.age}
            />
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              disabled={readOnly}
              label="estado de conservação"
              value={item.preservation_state}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              disabled={readOnly}
              label="observações"
              value={item.observations}
            />
          </Grid>
        </Fragment>
      ))}
      <Grid item xs={3}>
        <Tooltip
          arrow
          placement="top"
          title="imóvel ocupado no momento da vistoria"
        >
          <StyledSelect
            disabled={readOnly}
            select
            label="imóvel ocupado no momento da vistoria"
            value={constructionInputs.appraisal_occupied_during_inspection}
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          disabled={readOnly}
          select
          label="solução esgoto sanitário"
          value={constructionInputs.sewage_solution}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="rede de esgoto">rede de esgoto</StyledMenuItem>
          <StyledMenuItem value="fossa séptica">fossa séptica</StyledMenuItem>
          <StyledMenuItem value="sumidouro">sumidouro</StyledMenuItem>
          <StyledMenuItem value="não possui">não possui</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          disabled={readOnly}
          select
          label="esquadrias"
          value={constructionInputs.framing}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="alumínio">alumínio</StyledMenuItem>
          <StyledMenuItem value="ferro">ferro</StyledMenuItem>
          <StyledMenuItem value="madeira">madeira</StyledMenuItem>
          <StyledMenuItem value="PVC">PVC</StyledMenuItem>
          <StyledMenuItem value="vidro temperado">
            vidro temperado
          </StyledMenuItem>
          <StyledMenuItem value="outros">outros</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          disabled={readOnly}
          type="number"
          label="área construída/ privativa total (m²)"
          value={constructionInputs.total_constructed_or_private_area}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          disabled={readOnly}
          select
          label="solução de abastecimento de água"
          value={constructionInputs.water_supply}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="rede de água potável">
            rede de água potável
          </StyledMenuItem>
          <StyledMenuItem value="poço">poço</StyledMenuItem>
          <StyledMenuItem value="não possui">não possui</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          disabled={readOnly}
          type="number"
          label="total de vagas"
          value={constructionInputs.number_of_parking_spaces}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          disabled={readOnly}
          type="number"
          label="área averbada (m²)"
          value={constructionInputs.registered_area}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          disabled={readOnly}
          type="number"
          label="área não averbada (m²)"
          value={constructionInputs.not_registered_area}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          disabled={readOnly}
          // eslint-disable-next-line max-len
          label="Demais Informações Relevantes (Descrição da divisão interna / benfeitoria, não descritos acima):"
          value={constructionInputs.observations}
        />
      </Grid>
    </Grid>
  );
}
