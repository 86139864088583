import { Grid, Tooltip } from '@mui/material';

import { AppraisalInformationData } from '../../../../../api/theHive/statisticalReport/types';
import { StyledMenuItem, StyledSelect, StyledTextField } from '../../styles';
import { ufValues } from './constants';

interface HouseInputsProps {
  inputs: AppraisalInformationData;
  readOnly: boolean;
}

export function HouseInputs({
  inputs,
  readOnly,
}: HouseInputsProps): JSX.Element {
  return (
    <>
      <Grid item xs={3}>
        <StyledTextField
          label="número da proposta"
          value={inputs.proposal_number}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="número da matrícula"
          value={inputs.subscription_number}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <Tooltip arrow placement="top" title="nome do proprietário do imóvel">
          <StyledTextField
            label="nome do proprietário do imóvel"
            value={inputs.owner_name}
            disabled={readOnly}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="uso do imóvel avaliando"
          value={inputs.real_estate_use}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="residencial unifamiliar">
            residencial unifamiliar
          </StyledMenuItem>
          <StyledMenuItem value="residencial multifamiliar">
            residencial multifamiliar
          </StyledMenuItem>
          <StyledMenuItem value="comercial">comercial</StyledMenuItem>
          <StyledMenuItem value="industrial">industrial</StyledMenuItem>
          <StyledMenuItem value="institucional">institucional</StyledMenuItem>
          <StyledMenuItem value="outro">outro</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <Tooltip arrow placement="top" title="imóvel habitado anteriormente">
          <StyledSelect
            select
            label="imóvel habitado anteriormente"
            value={String(inputs.has_been_used_before)}
            disabled={readOnly}
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="qual tipo de ocupação"
          value={inputs.real_estate_use_type}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="desocupado">desocupado</StyledMenuItem>
          <StyledMenuItem value="cedido/ comodado">
            cedido / comodado
          </StyledMenuItem>
          <StyledMenuItem value="invadido">invadido</StyledMenuItem>
          <StyledMenuItem value="locado/ arrendado">
            locado / arrendado
          </StyledMenuItem>
          <StyledMenuItem value="ocupado">ocupado</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="tipo"
          value={inputs.type}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="convencional">convencional</StyledMenuItem>
          <StyledMenuItem value="cobertura">cobertura</StyledMenuItem>
          <StyledMenuItem value="duplex">duplex</StyledMenuItem>
          <StyledMenuItem value="garden">garden</StyledMenuItem>
          <StyledMenuItem value="kitnet">kitnet</StyledMenuItem>
          <StyledMenuItem value="loft">loft</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="logradouro"
          value={inputs.address_street}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="número"
          value={inputs.address_number}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="complemento"
          value={inputs.address_complement}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="cidade"
          value={inputs.address_city}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="UF"
          value={inputs.address_state}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          {ufValues.map((uf) => (
            <StyledMenuItem key={uf.value} value={uf.value}>
              {uf.label}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          label="latitude"
          value={inputs.latitude}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          label="longitude"
          value={inputs.longitude}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="localização"
          value={inputs.location_status_level}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="boa">boa</StyledMenuItem>
          <StyledMenuItem value="regular">regular</StyledMenuItem>
          <StyledMenuItem value="ruim">ruim</StyledMenuItem>
          <StyledMenuItem value="ótima">ótima</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <Tooltip arrow placement="top" title="tipo de implantação">
          <StyledSelect
            select
            label="tipo de implantação"
            value={inputs.deployment_type}
            disabled={readOnly}
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="condomínio">condomínio</StyledMenuItem>
            <StyledMenuItem value="isolado">isolado</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <Tooltip arrow placement="top" title="estado de conservação">
          <StyledSelect
            select
            label="estado de conservação"
            value={inputs.preservation_state}
            disabled={readOnly}
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="bom">bom</StyledMenuItem>
            <StyledMenuItem value="em construção">em construção</StyledMenuItem>
            <StyledMenuItem value="regular">regular</StyledMenuItem>
            <StyledMenuItem value="ruim">ruim</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="padrão construtivo"
          value={inputs.constructive_standard}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="mínimo">mínimo</StyledMenuItem>
          <StyledMenuItem value="baixo">baixo</StyledMenuItem>
          <StyledMenuItem value="normal/ médio">normal/ médio</StyledMenuItem>
          <StyledMenuItem value="alto">alto</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          label="idade aparente do imóvel"
          value={inputs.age}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          label="número de dormitórios"
          value={inputs.number_of_bedrooms}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          label="número de suítes"
          value={inputs.number_of_suites}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          label="dormitórios + suítes"
          value={inputs.number_of_bedrooms_and_suites}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <Tooltip
          arrow
          placement="top"
          title="nº de banheiros sociais (não contar das suítes)"
        >
          <StyledTextField
            type="number"
            label="nº de banheiros sociais (não contar das suítes)"
            value={inputs.number_of_social_bathrooms}
            disabled={readOnly}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <Tooltip arrow placement="top" title="nº banheiros (sociais + suítes)">
          <StyledTextField
            type="number"
            label="nº banheiros (sociais + suítes)"
            value={inputs.number_of_social_and_suite_bathrooms}
            disabled={readOnly}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="fechamento das paredes"
          value={inputs.wall_closure}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="alvenaria">alvenaria</StyledMenuItem>
          <StyledMenuItem value="dry wall">dry wall</StyledMenuItem>
          <StyledMenuItem value="madeira">madeira</StyledMenuItem>
          <StyledMenuItem value="misto">misto</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <Tooltip arrow placement="top" title="abastecimento de água na unidade">
          <StyledSelect
            select
            label="abastecimento de água na unidade"
            value={inputs.water_supply}
            disabled={readOnly}
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="rede de água potável">
              rede de água potável
            </StyledMenuItem>
            <StyledMenuItem value="poço">poço</StyledMenuItem>
            <StyledMenuItem value="não possui">não possui</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <Tooltip arrow placement="top" title="solução de esgoto sanitário">
          <StyledSelect
            select
            label="solução de esgoto sanitário"
            value={inputs.sewage_solution}
            disabled={readOnly}
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="rede de esgoto">
              rede de esgoto
            </StyledMenuItem>
            <StyledMenuItem value="fossa séptica/ sumidouro">
              fossa séptica/ sumidouro
            </StyledMenuItem>
            <StyledMenuItem value="não possui">não possui</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <Tooltip arrow placement="top" title="posição da edificação no terreno">
          <StyledSelect
            select
            label="posição da edificação no terreno"
            value={inputs.position_into_land}
            disabled={readOnly}
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="isolada">isolada</StyledMenuItem>
            <StyledMenuItem value="geminada em uma lateral">
              geminada em uma lateral
            </StyledMenuItem>
            <StyledMenuItem value="geminada nas laterais">
              geminada nas laterais
            </StyledMenuItem>
            <StyledMenuItem value="junto aos fundos">
              junto aos fundos
            </StyledMenuItem>
            <StyledMenuItem value="junto a uma lateral">
              junto a uma lateral
            </StyledMenuItem>
            <StyledMenuItem value="junto às laterais">
              junto às laterais
            </StyledMenuItem>
            <StyledMenuItem value="ocupa todo o terreno">
              ocupa todo o terreno
            </StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="cobertura (telhado)"
          value={inputs.roof_covering}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="laje">laje</StyledMenuItem>
          <StyledMenuItem value="telha cerâmica">telha cerâmica</StyledMenuItem>
          <StyledMenuItem value="telha fibrocimento">
            telha fibrocimento
          </StyledMenuItem>
          <StyledMenuItem value="telha concreto">telha concreto</StyledMenuItem>
          <StyledMenuItem value="outros">outros</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          label="esquadrias"
          value={inputs.framing}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="alumínio">alumínio</StyledMenuItem>
          <StyledMenuItem value="ferro">ferro</StyledMenuItem>
          <StyledMenuItem value="madeira">madeira</StyledMenuItem>
          <StyledMenuItem value="PVC/plástico">PVC/plástico</StyledMenuItem>
          <StyledMenuItem value="vidro temperado">
            vidro temperado
          </StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={6}>
        <StyledSelect
          select
          label="objetivo da avaliação"
          value={inputs.evaluation_objective}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="valor de mercado de compra e venda">
            valor de mercado de compra e venda
          </StyledMenuItem>
          <StyledMenuItem value="valor de mercado de locação">
            valor de mercado de locação
          </StyledMenuItem>
          <StyledMenuItem value="custo de reedição">
            custo de reedição
          </StyledMenuItem>
          <StyledMenuItem value="valor de liquidação forçada">
            valor de liquidação forçada
          </StyledMenuItem>
          <StyledMenuItem value="outros">outros</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={6}>
        <StyledTextField
          type="number"
          label="nº lavabos (não deve ser considerado na soma total de banheiros)"
          value={inputs.number_of_lavatories}
          disabled={readOnly}
        />
      </Grid>
    </>
  );
}
