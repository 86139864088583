import { FormControlLabel, FormGroup, FormLabel, Grid } from '@mui/material';

import { PropertyType } from '../../../../../api/enumerations';
import {
  AppraisalInformationData,
  ParkingApartmentData,
  ParkingHouseData,
} from '../../../../../api/theHive/statisticalReport/types';
import {
  StyledCheckbox,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../../styles';
import { ApartmentInputs } from './apartmentInputs';
import { infraValues } from './constants';
import { HouseInputs } from './houseInputs';
import { TableApartmentParking } from './Tables/TableApartmentParking';
import { TableArea } from './Tables/TableArea';
import { TableHouseParking } from './Tables/TableHouseParking';
import { TableInternalDivision } from './Tables/TableInternalDivision';

interface AppraisalInformationProps {
  inputs: AppraisalInformationData;
  propertyType: PropertyType;
  readOnly: boolean;
}

export function AppraisalInformation({
  inputs,
  propertyType,
  readOnly,
}: AppraisalInformationProps): JSX.Element {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <StyledSelect
          disabled={readOnly}
          select
          label="tipo do imóvel"
          value={inputs.real_estate_type}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="apartamento">apartamento</StyledMenuItem>
          <StyledMenuItem value="casa">casa</StyledMenuItem>
          <StyledMenuItem value="terreno">terreno</StyledMenuItem>
          <StyledMenuItem value="sala comercial">sala comercial</StyledMenuItem>
          <StyledMenuItem value="flat">flat</StyledMenuItem>
          <StyledMenuItem value="loja de rua">loja de rua</StyledMenuItem>
        </StyledSelect>
      </Grid>
      {propertyType === PropertyType.APARTMENT ? (
        <ApartmentInputs inputs={inputs} readOnly={readOnly} />
      ) : (
        <>
          <HouseInputs inputs={inputs} readOnly={readOnly} />
          <Grid item xs={12}>
            <FormLabel>infraestrutura / serviços / equipamentos</FormLabel>
          </Grid>
          {infraValues.map((item) => (
            <Grid item xs={3} key={item.label}>
              <FormGroup>
                <FormControlLabel
                  disabled={readOnly}
                  control={
                    <StyledCheckbox
                      value={item.value}
                      checked={inputs.infrastructure_services_and_equipments?.includes(
                        item.value
                      )}
                    />
                  }
                  label={item.label}
                />
              </FormGroup>
            </Grid>
          ))}
        </>
      )}
      <Grid item xs={12}>
        <StyledTextField
          disabled={readOnly}
          label="propósito da avaliação / finalidade do laudo / linha"
          value={inputs.evaluation_purpose}
        />
      </Grid>
      <Grid item xs={12}>
        <TableArea inputs={inputs.area_information} readOnly={readOnly} />
      </Grid>
      <Grid item xs={12}>
        {propertyType === PropertyType.APARTMENT ? (
          <TableApartmentParking
            inputs={inputs.parking_information as ParkingApartmentData}
            readOnly={readOnly}
          />
        ) : (
          <TableHouseParking
            inputs={inputs.parking_information as ParkingHouseData}
            readOnly={readOnly}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <TableInternalDivision
          inputs={inputs.internal_division}
          readOnly={readOnly}
        />
      </Grid>
    </Grid>
  );
}
