import { Grid } from '@mui/material';

import { DiagnosisData } from '../../../../api/theHive/statisticalReport/types';
import { StyledMenuItem, StyledSelect, StyledTextField } from '../styles';

interface DiagnosticDataProps {
  inputs: DiagnosisData;
  readOnly: boolean;
}

export function Diagnostic({
  inputs,
  readOnly,
}: DiagnosticDataProps): JSX.Element {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <StyledSelect
          disabled={readOnly}
          select
          label="absorção pelo mercado"
          value={inputs.market_uptake}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="difícil">difícil</StyledMenuItem>
          <StyledMenuItem value="fácil">fácil</StyledMenuItem>
          <StyledMenuItem value="extremamente fácil">
            extremamente fácil
          </StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          disabled={readOnly}
          label="prazo de venda efetivo (meses)"
          type="number"
          value={inputs.effective_sell_deadline_in_months}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledTextField
          disabled={readOnly}
          label="público alvo"
          value={inputs.target_audience}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          disabled={readOnly}
          label="facilitadores na negociação"
          value={inputs.negatiation_facilitators}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          disabled={readOnly}
          select
          label="desempenho do mercado"
          value={inputs.market_perfomance}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="aparentemente esquecido">
            aparentemente esquecido
          </StyledMenuItem>
          <StyledMenuItem value="sem destaque">sem destaque</StyledMenuItem>
          <StyledMenuItem value="aparentemente baixo">
            aparentemente baixo
          </StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          disabled={readOnly}
          select
          label="número de ofertas"
          value={inputs.number_of_offers_status_level}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="aparentemente esquecido">
            aparentemente esquecido
          </StyledMenuItem>
          <StyledMenuItem value="sem destaque">sem destaque</StyledMenuItem>
          <StyledMenuItem value="aparentemente baixo">
            aparentemente baixo
          </StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          disabled={readOnly}
          select
          label="liquidez do imóvel"
          value={inputs.liquidity}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="provavelmente alta">
            provavelmente alta
          </StyledMenuItem>
          <StyledMenuItem value="sem destaque">sem destaque</StyledMenuItem>
          <StyledMenuItem value="provavelmente baixa">
            provavelmente baixa
          </StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          disabled={readOnly}
          label="observações importantes"
          value={inputs.observations}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          disabled={readOnly}
          label="informações complementares de unidades autônomas"
          value={inputs.independent_unities_complementary_information}
        />
      </Grid>
    </Grid>
  );
}
