import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { AttachmentsFilesProvider } from './context/attachmentFiles';
import { GlobalProvider } from './context/global';
import { WebsocketProvider } from './context/websocketMessage';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <GlobalProvider>
      <WebsocketProvider>
        <AttachmentsFilesProvider>
          <App />
        </AttachmentsFilesProvider>
      </WebsocketProvider>
    </GlobalProvider>
  </React.StrictMode>
);

reportWebVitals();
