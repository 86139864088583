import { Grid } from '@mui/material';

import { LandData } from '../../../../api/theHive/statisticalReport/types';
import { StyledMenuItem, StyledSelect, StyledTextField } from '../styles';

interface TerrainProps {
  doesNotApply: number;
  inputs: LandData;
  readOnly: boolean;
}

export function Terrain({
  doesNotApply,
  inputs,
  readOnly,
}: TerrainProps): JSX.Element {
  const percentage = Array.from({ length: 10 }, (_, index) => (index + 1) * 10);

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={readOnly}
          label="não se aplica"
          value={doesNotApply}
        >
          <StyledMenuItem value={0}>selecione uma opção</StyledMenuItem>
          <StyledMenuItem value={1}>sim</StyledMenuItem>
          <StyledMenuItem value={2}>não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={readOnly}
          label="topografia"
          value={inputs.topography}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="aclive maior que 10%">
            aclive maior que 10%
          </StyledMenuItem>
          <StyledMenuItem value="declive maior que 10%">
            declive maior que 10%
          </StyledMenuItem>
          <StyledMenuItem value="plano">plano</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={readOnly}
          label="tipo de implantação"
          value={inputs.implantation_type}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="isolado">isolado</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={readOnly}
          label="formato"
          value={inputs.shape}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="irregular">irregular</StyledMenuItem>
          <StyledMenuItem value="regular">regular</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={readOnly}
          label="posição do terreno"
          value={inputs.land_position}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="meio de quadra">meio de quadra</StyledMenuItem>
          <StyledMenuItem value="esquina">esquina</StyledMenuItem>
          <StyledMenuItem value="encravado">encravado</StyledMenuItem>
          <StyledMenuItem value="quadra inteira">quadra inteira</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={readOnly}
          label="drenagem (superfície)"
          value={inputs.surface_draining}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="alagável">alagável</StyledMenuItem>
          <StyledMenuItem value="brejoso">brejoso</StyledMenuItem>
          <StyledMenuItem value="seco">seco</StyledMenuItem>
          <StyledMenuItem value="outra">outra</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={readOnly}
          label="cota do greide"
          value={inputs.greide_quota}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="abaixo">abaixo</StyledMenuItem>
          <StyledMenuItem value="acima">acima</StyledMenuItem>
          <StyledMenuItem value="mesmo nível">mesmo nível</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          disabled={readOnly}
          label="nº de frentes"
          value={String(inputs.number_of_fronts)}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          disabled={readOnly}
          label="área total (m²)"
          value={String(inputs.total_area_in_squared_meters)}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          disabled={readOnly}
          label="fração ideal (%)"
          value={String(inputs.ideal_fraction)}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          disabled={readOnly}
          label="testada (frente)"
          value={inputs.testada_front}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          disabled={readOnly}
          label="qual zoneamento"
          value={inputs.zoning}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledTextField
          disabled={readOnly}
          label="quais usos permitidos pelo zoneamento"
          value={inputs.allowed_uses_by_zoning}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={readOnly}
          label="taxa de ocupação máxima permitida"
          value={inputs.maximum_allowed_occupation_rate_percentage}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          {percentage.map((item) => (
            <StyledMenuItem value={item}>{item}%</StyledMenuItem>
          ))}
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          disabled={readOnly}
          placeholder="%"
          label="coeficiente de aproveitamento"
          value={String(inputs.utilization_coefficient_percentage)}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          disabled={readOnly}
          label="altura máxima permitida para edificação"
          value={String(inputs.maximum_height_allowed)}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          disabled={readOnly}
          label="frontal"
          value={inputs.front}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          disabled={readOnly}
          label="lateral"
          value={inputs.side}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          disabled={readOnly}
          label="fundos"
          value={inputs.back}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={readOnly}
          label="tipo de cercamento do terreno"
          value={inputs.land_fencing_type}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="sem cercamento">sem cercamento</StyledMenuItem>
          <StyledMenuItem value="arame">arame</StyledMenuItem>
          <StyledMenuItem value="tela">tela</StyledMenuItem>
          <StyledMenuItem value="gradil">gradil</StyledMenuItem>
          <StyledMenuItem value="muro">muro</StyledMenuItem>
          <StyledMenuItem value="outro">outro</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={6}>
        <StyledTextField
          disabled={readOnly}
          label="nível de restrição (fração)"
          value={inputs.restriction_fraction_level}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          disabled={readOnly}
          label="observações"
          value={inputs.observations}
        />
      </Grid>
    </Grid>
  );
}
