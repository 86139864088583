/* eslint-disable react/no-array-index-key */
import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';

import { InternalDivisionData } from '../../../../../../api/theHive/statisticalReport/types';
import { StyledTableCellBody } from '../../../styles';

interface TableInternalDivisionProps {
  inputs: InternalDivisionData[];
  readOnly: boolean;
}

export function TableInternalDivision({
  inputs,
  readOnly,
}: TableInternalDivisionProps): JSX.Element {
  return (
    <TableContainer>
      <Table>
        <TableRow sx={{ backgroundColor: '#F4EEF6', border: '1px solid #ccc' }}>
          <TableCell align="center" colSpan={3}>
            divisão interna
          </TableCell>
        </TableRow>
        <TableRow sx={{ backgroundColor: '#E9E1ED', border: '1px solid #ccc' }}>
          <TableCell
            align="center"
            sx={{ width: '50%', border: '1px solid #ccc' }}
          >
            tipo de cômodo
          </TableCell>
          <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
            quantidade
          </TableCell>
        </TableRow>
        {inputs.map((item, index) => (
          <TableRow key={index}>
            <StyledTableCellBody>
              <TextField disabled={readOnly} multiline value={item.room_type} />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TextField disabled={readOnly} multiline value={item.quantity} />
            </StyledTableCellBody>
          </TableRow>
        ))}
      </Table>
    </TableContainer>
  );
}
