/* eslint-disable react-hooks/exhaustive-deps */
import {
  Badge,
  Divider,
  Grid,
  Icon,
  IconOwnProps,
  Tooltip,
} from '@mui/material';
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  ClientKind,
  convertArchivedReason,
  convertClientKind,
  convertEvaluationType,
  convertReportFunction,
  convertReportGoal,
  Status,
  WebsocketTypes,
} from '../../../../api/enumerations';
import { AvmStatusData, WorkOrderData } from '../../../../api/workOrders/types';
import logoBb from '../../../../assets/images/logo-bb.svg';
import logoCaixa from '../../../../assets/images/logo-caixa.svg';
import protoFaceFailed from '../../../../assets/images/proto-sad.png';
import protoFaceWainting from '../../../../assets/images/proto-wink.png';
import { Constants } from '../../../../constants/home';
import {
  IconCancelMS,
  IconEmailMS,
  IconErrorMS,
  IconPendingMS,
  IconTroubleshootMS,
  IconVerifiedMS,
} from '../../../../constants/icons';
import { WebsocketContext } from '../../../../context/websocketMessage';
import { checkCardColor, formatDate } from '../../../../helpers';
import {
  AvmStatusBox,
  BankFlag,
  CardContainer,
  CardLabel,
  info,
  ProtoIcon,
  RejectedIcon,
  TypeFlag,
} from './styles';

interface CardProps {
  cardData: WorkOrderData;
}

interface IconStatus {
  icon: ReactNode;
  color: IconOwnProps['color'];
  tooltipTitle: string;
}

export function Card({ cardData }: CardProps): JSX.Element {
  const [hasResponsible, setHasResponsible] = useState(false);
  const [limitAlert, setLimitAlert] = useState(false);
  const [limitOver, setLimitOver] = useState(false);
  const [calculationError, setCalculationError] = useState(false);
  const [unreadCount, setUnreadCount] = useState<number>(
    cardData.unread_messages_count
  );
  const isBank = process.env.REACT_APP_IS_BANK === 'true';
  const avmStatus = cardData.status === Status.CALCULATION;
  const responsibleStatus = useMemo(
    () => [
      Status.INSPECTION,
      Status.SAMPLE_CREATION,
      Status.CALCULATION,
      Status.REPORT,
      Status.REVISION,
    ],
    []
  );

  const { websocketMessage } = useContext(WebsocketContext);

  useEffect(() => {
    if (websocketMessage) {
      const messageData = JSON.parse(websocketMessage.data);
      if (
        messageData.type === WebsocketTypes.NEW_MESSAGE &&
        messageData.payload.work_order_id === cardData.id
      ) {
        setUnreadCount(messageData.payload.unread_messages_count);
      }
    }
  }, [websocketMessage]);

  const getHiveStatusIcon = useCallback((): IconStatus | null => {
    if (!cardData.hive_request?.work_status) {
      return null;
    }

    switch (cardData.hive_request.work_status) {
      case 'in_progress':
        return {
          icon: IconPendingMS,
          color: 'action',
          tooltipTitle: 'não enviado',
        };
      case 'hive_accepted':
        return {
          icon: IconVerifiedMS,
          color: 'success',
          tooltipTitle: 'aceito pelo banco',
        };
      case 'hive_rejected':
        return {
          icon: IconCancelMS,
          color: 'error',
          tooltipTitle: 'rejeitado pelo banco',
        };
      case 'worker_finalized':
        return {
          icon: IconTroubleshootMS,
          color: 'warning',
          tooltipTitle: 'em análise pelo banco',
        };
      default:
        return null;
    }
  }, [cardData]);

  useEffect(() => {
    if (responsibleStatus.includes(cardData.status)) {
      setHasResponsible(true);
    }
    if (cardData.status === Status.CALCULATION) {
      if (cardData.avm_status) {
        if (
          cardData.avm_status.status === 'not found' ||
          cardData.avm_status.status === 'failed' ||
          cardData.avm_status.status === 'undetermined' ||
          (cardData.avm_status.failure_reason &&
            cardData.avm_status.failure_reason?.length > 0)
        ) {
          setCalculationError(true);
        }
      }
    }
    if (cardData.limit_finish_at) {
      const dateOver = new Date(cardData.limit_finish_at);
      const d = new Date(cardData.limit_finish_at);
      const dateAlert = new Date(d.setDate(d.getDate() - 3));
      const today = new Date();
      today.setHours(23);

      if (today >= dateOver) {
        setLimitOver(true);
        return;
      }
      if (today >= dateAlert) {
        setLimitAlert(true);
      }
    }
  }, []);

  const handleAvmStatus = (avmStatus: AvmStatusData | null): string => {
    switch (true) {
      case Boolean(avmStatus?.failure_reason):
        return 'erro de cálculo';
      default:
        return 'aguardando...';
    }
  };

  const iconStatus = getHiveStatusIcon();
  return (
    <CardContainer
      key={cardData.id}
      sx={{
        borderColor: checkCardColor(
          isBank ? 3 : cardData.client_kind,
          limitOver,
          limitAlert,
          calculationError
        ),
        backgroundColor: limitAlert ? '#F8F7E4' : '#FAFCFF',
      }}
    >
      <TypeFlag
        sx={{
          backgroundColor: checkCardColor(
            isBank ? 3 : cardData.client_kind,
            limitOver,
            limitAlert
          ),
        }}
      />
      {!isBank && cardData.client_kind === ClientKind.BRASIL_BANK && (
        <BankFlag image={logoBb} title="logo banco do brasil" />
      )}
      {!isBank && cardData.client_kind === ClientKind.CAIXA && (
        <BankFlag image={logoCaixa} title="logo caixa" />
      )}
      <CardLabel late={limitOver || calculationError}>
        {Constants.os}
        <span style={info}>{cardData.reference_number}</span>
      </CardLabel>
      {hasResponsible ? (
        <CardLabel late={limitOver || calculationError}>
          {Constants.inspector}
          <span style={info}>
            {cardData.inspection && cardData.inspection.engineer_name}
          </span>
        </CardLabel>
      ) : (
        <CardLabel late={limitOver}>
          {Constants.client}
          <span style={info}>{cardData.client_name}</span>
        </CardLabel>
      )}
      <CardLabel late={limitOver || calculationError}>
        {Constants.clientType}
        <span style={info}>{convertClientKind(cardData.client_kind)}</span>
      </CardLabel>
      <CardLabel late={limitOver || calculationError}>
        {Constants.address}
        <span style={info}>{cardData.street}</span>
      </CardLabel>
      {hasResponsible ? (
        <>
          <CardLabel late={limitOver || calculationError}>
            {Constants.schedule}
            <span style={info}>
              {cardData.inspection?.schedule_at &&
                formatDate(cardData.inspection.schedule_at)}
            </span>
          </CardLabel>
          {cardData.status === Status.ARCHIVED && (
            <CardLabel late={limitOver}>
              {Constants.reason}
              <span style={info}>
                {convertArchivedReason(cardData.archived_reason)}
              </span>
            </CardLabel>
          )}
          <CardLabel late={limitOver || calculationError}>
            {Constants.limitFinish}
            <span style={info}>
              {cardData.limit_finish_at && formatDate(cardData.limit_finish_at)}
            </span>
          </CardLabel>
        </>
      ) : (
        <>
          <CardLabel late={limitOver}>
            {Constants.report}
            <span style={info}>
              {convertEvaluationType(cardData.evaluation_type)}
            </span>
          </CardLabel>
          <CardLabel late={limitOver}>
            {Constants.goal}
            <span style={info}>{convertReportGoal(cardData.report_goal)}</span>
          </CardLabel>
          <CardLabel late={limitOver}>
            {Constants.function}
            <span style={info}>
              {convertReportFunction(cardData.report_function)}
            </span>
          </CardLabel>
        </>
      )}
      {avmStatus && (
        <AvmStatusBox>
          <ProtoIcon
            image={
              cardData.avm_status?.failure_reason
                ? protoFaceFailed
                : protoFaceWainting
            }
          />
          <p>{handleAvmStatus(cardData.avm_status)}</p>
        </AvmStatusBox>
      )}
      {cardData.worker_company && (
        <>
          <Divider sx={{ m: '6px 0' }} />
          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-between"
            mt={1}
          >
            <Grid item>
              <CardLabel late={limitOver} sx={{ alignItems: 'center' }}>
                {Constants.company}
                <span
                  style={{
                    ...info,
                    display: 'inline-block',
                    alignItems: 'center',
                    overflow: 'hidden',
                    maxWidth: '115px',
                    whiteSpace: 'nowrap',
                    position: 'static',
                    marginBottom: -6,
                  }}
                >
                  credenciado quarenta e três
                </span>
              </CardLabel>
            </Grid>
            {iconStatus && cardData.status === Status.REVISION && (
              <Grid item mb="-6px">
                <Tooltip arrow placement="top" title={iconStatus.tooltipTitle}>
                  <Icon sx={{ height: '26px' }} color={iconStatus.color}>
                    {iconStatus.icon}
                  </Icon>
                </Tooltip>
              </Grid>
            )}
            {unreadCount > 0 && (
              <Grid
                item
                // container
                justifyContent="end"
                style={{
                  color: '#00B39B',
                }}
              >
                <Badge
                  badgeContent={unreadCount}
                  color="error"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  {IconEmailMS}
                </Badge>
              </Grid>
            )}
          </Grid>
        </>
      )}

      {cardData.hive_request?.acceptance_status === 'rejected' && (
        <RejectedIcon>
          <Tooltip arrow placement="top" title={Constants.requestRejected}>
            {IconErrorMS}
          </Tooltip>
        </RejectedIcon>
      )}
    </CardContainer>
  );
}
