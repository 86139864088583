import {
  BaseUrl,
  myHeadersWithToken,
  myHeadersWithTokenContentType,
} from '../../global';
import { AutoRequestData, DetailResponse, RequestsProps } from './types';

export const createRequest = (data: RequestsProps): Promise<DetailResponse> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };

  return fetch(`${BaseUrl}/api/V1/hive-requests`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const createAutoRequest = (
  data: AutoRequestData
): Promise<DetailResponse> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };

  return fetch(`${BaseUrl}/api/V1/hive-requests/automatic`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const removeRequest = (requestId: number): Promise<DetailResponse> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithToken(),
  };

  return fetch(`${BaseUrl}/api/V1/hive-requests/${requestId}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
