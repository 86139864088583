import { InputAdornment } from '@mui/material';

import { GetWorkOrdersParams } from '../../../api/workOrders/types';
import { IconSearchMS } from '../../../constants/icons';
import SearchModal from './SearchModal';
import { StyledInputBase } from './styles';

interface SearchProps {
  setSearchField: (value: string) => void;
  setFilters: (value: GetWorkOrdersParams | undefined) => void;
  searchLoading: boolean;
  isFiltersActive: boolean;
}

export function BankSearch({
  setSearchField,
  setFilters,
  searchLoading,
  isFiltersActive,
}: SearchProps): JSX.Element {
  return (
    <StyledInputBase
      placeholder="Pesquisar"
      inputProps={{ 'aria-label': 'pesquisar', maxLength: 11 }}
      type="search"
      startAdornment={
        <InputAdornment position="start" sx={{ color: '#004D43' }}>
          {IconSearchMS}
        </InputAdornment>
      }
      endAdornment={
        <SearchModal
          setFilters={setFilters}
          searchLoading={searchLoading}
          isFiltersActive={isFiltersActive}
        />
      }
      onChange={(e) => {
        if (e.target.value.length > 0) {
          setSearchField(e.target.value);
        }
        if (e.target.value.length === 0) {
          setSearchField('');
        }
      }}
      onFocus={(e) => {
        e.target.value = '';
        setSearchField('');
      }}
    />
  );
}
