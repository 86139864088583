import { BaseUrl, myHeadersWithTokenContentType } from '../../global';
import { StatisticalReportAttachmentsProps } from './types';

export const listStatisticalReportAttachment = (
  id: number
): Promise<StatisticalReportAttachmentsProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/statistical-report/attachments`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
