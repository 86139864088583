/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, TextFieldProps, Tooltip } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';

import { EvaluationMethodologyData } from '../../../../api/theHive/statisticalReport/types';
import { StyledTextField } from '../../../../pages/Entrance/styles';
import { StyledMenuItem, StyledSelect } from '../styles';

interface MethodologyProps {
  inputs: EvaluationMethodologyData;
  readOnly: boolean;
}

export function Methodology({
  inputs,
  readOnly,
}: MethodologyProps): JSX.Element {
  const [initialDate, setInitialDate] = useState<Dayjs | null>(null);
  const [finishDate, setFinishDate] = useState<Dayjs | null>(null);

  useEffect(() => {
    setInitialDate(dayjs(inputs.samples_reference_research_start_date));
    setFinishDate(dayjs(inputs.samples_reference_research_end_date));
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <StyledSelect
          disabled={readOnly}
          select
          label="tratamento"
          value={inputs.type}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="estatística inferencial">
            inferência
          </StyledMenuItem>
          <StyledMenuItem value="fatores">fatores</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={4}>
        <StyledSelect
          disabled={readOnly}
          select
          label="metodologia de avaliação"
          value={inputs.methodology}
        >
          <StyledMenuItem value={undefined}>selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="MCDDM">MCDDM</StyledMenuItem>
          <StyledMenuItem value="SMDNO">SMDNO</StyledMenuItem>
          <StyledMenuItem value="outras metodologias">
            outras metodologias
          </StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={4}>
        <StyledTextField
          disabled={readOnly}
          label="indicar metodologia"
          value={inputs.methodology_name}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          disabled={readOnly}
          label="F calculado menor que 20?"
          value={String(inputs.f_calculus_less_than_20)}
          select
        >
          <StyledMenuItem value={undefined}>selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="true">sim</StyledMenuItem>
          <StyledMenuItem value="false">não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={2.5}>
        <Tooltip arrow placement="top" title="grau de fundamentação">
          <StyledSelect
            disabled={readOnly}
            select
            label="grau de fundamentação"
            value={inputs.reasoning_level}
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="I">I</StyledMenuItem>
            <StyledMenuItem value="II">II</StyledMenuItem>
            <StyledMenuItem value="III">III</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={2.5}>
        <Tooltip arrow placement="top" title="grau de precisão">
          <StyledSelect
            disabled={readOnly}
            select
            label="grau de precisão"
            value={inputs.precision_level}
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="I">I</StyledMenuItem>
            <StyledMenuItem value="II">II</StyledMenuItem>
            <StyledMenuItem value="III">III</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Tooltip
          arrow
          placement="top"
          title="o coeficiente de determinação está abaixo de 0,64?"
        >
          <StyledSelect
            disabled={readOnly}
            select
            value={String(inputs.determination_coefficient_less_than_0_64)}
            label="o coeficiente de determinação está abaixo de 0,64?"
          >
            <StyledMenuItem value={undefined}>
              selecione uma opção
            </StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Tooltip
          arrow
          placement="top"
          title="quantidade de variáveis independentes adotadas no modelo?"
        >
          <StyledTextField
            disabled={readOnly}
            label="quantidade de variáveis independentes adotadas no modelo?"
            value={inputs.number_of_independent_variables}
            type="number"
          />
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Tooltip
          arrow
          placement="top"
          title="quantidade de dados utilizados no modelo?"
        >
          <StyledTextField
            disabled={readOnly}
            label="quantidade de dados utilizados no modelo?"
            value={inputs.number_of_data}
            type="number"
          />
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Tooltip
          arrow
          placement="top"
          title="período inicial de referência de pesquisa das amostras?"
        >
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                disabled={readOnly}
                label="período inicial de referência de pesquisa das amostras?"
                value={initialDate}
                format="DD-MM-YYYY"
                slots={{
                  textField:
                    StyledTextField as React.ComponentType<TextFieldProps>,
                }}
              />
            </LocalizationProvider>
          </Box>
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Tooltip
          arrow
          placement="top"
          title="período final de referência de pesquisa das amostras?"
        >
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                disabled={readOnly}
                label="período final de referência de pesquisa das amostras?"
                value={finishDate}
                format="DD-MM-YYYY"
                slots={{
                  textField:
                    StyledTextField as React.ComponentType<TextFieldProps>,
                }}
              />
            </LocalizationProvider>
          </Box>
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Tooltip
          arrow
          placement="top"
          title="a significância de uma das variáveis é maior que 30%?"
        >
          <StyledSelect
            disabled={readOnly}
            label="a significância de uma das variáveis é maior que 30%?"
            value={String(
              inputs.have_variable_with_significance_level_greater_than_30_percent
            )}
            select
          >
            <StyledMenuItem value={undefined}>
              selecione uma opção
            </StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Tooltip
          arrow
          placement="top"
          title="houve extrapolação de alguma variável?"
        >
          <StyledSelect
            disabled={readOnly}
            label="houve extrapolação de alguma variável?"
            value={String(inputs.have_extrapolated_variable)}
            select
          >
            <StyledMenuItem value={undefined}>
              selecione uma opção
            </StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Tooltip
          arrow
          placement="top"
          title="o modelo atende a micronumerosidade das variáveis?"
        >
          <StyledSelect
            disabled={readOnly}
            label="o modelo atende a micronumerosidade das variáveis?"
            value={String(inputs.have_micronumerosity)}
            select
          >
            <StyledMenuItem value={undefined}>
              selecione uma opção
            </StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <StyledSelect
          disabled={readOnly}
          select
          label="o modelo possui Outliers?"
          value={String(inputs.have_outliers)}
        >
          <StyledMenuItem value={undefined}>selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="true">sim</StyledMenuItem>
          <StyledMenuItem value="false">não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={4}>
        <Tooltip
          arrow
          placement="top"
          title="O valor adotado é da estimativa Central?"
        >
          <StyledSelect
            disabled={readOnly}
            label="O valor adotado é da estimativa Central?"
            value={String(inputs.is_value_from_central_estimate)}
            select
          >
            <StyledMenuItem value={undefined}>
              selecione uma opção
            </StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          disabled={readOnly}
          label="justificativa"
          value={inputs.justification}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          disabled={readOnly}
          label="indicar a equação do modelo adotada?"
          value={inputs.model_equation}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          disabled={readOnly}
          label="observação valor adotado"
          value={inputs.observations}
        />
      </Grid>
    </Grid>
  );
}
