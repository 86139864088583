/* eslint-disable max-len */
import {
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Tooltip,
} from '@mui/material';

import { GeneralConditionsData } from '../../../../api/theHive/statisticalReport/types';
import {
  StyledCheckbox,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../styles';
import { depreciativeFactors, valuingFactors } from './utils';

interface GeneralConditionsDataProps {
  inputs: GeneralConditionsData;
  readOnly: boolean;
}

export function GeneralConditions({
  inputs,
  readOnly,
}: GeneralConditionsDataProps): JSX.Element {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Tooltip
          arrow
          placement="top"
          title="as informações da documentação correspondem à verificadas na vistoria"
        >
          <StyledSelect
            select
            label="as informações da documentação correspondem à verificadas na vistoria"
            value={inputs.documents_information_matches_with_inspection}
            disabled={readOnly}
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={6}>
        {inputs.documents_information_matches_with_inspection === 'false' && (
          <StyledTextField
            label="justifique"
            required
            value={inputs.unmatched_information_reason}
            disabled={readOnly}
          />
        )}
      </Grid>
      <Grid item xs={6}>
        <Tooltip
          arrow
          placement="top"
          title="o imóvel aparenta condições de estabilidade e solidez"
        >
          <StyledSelect
            select
            label="o imóvel aparenta condições de estabilidade e solidez"
            value={inputs.stability_conditions}
            disabled={readOnly}
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={6}>
        {inputs.stability_conditions === 'false' && (
          <StyledTextField
            label="justifique"
            required
            value={inputs.instability_conditions_reason}
            disabled={readOnly}
          />
        )}
      </Grid>
      <Grid item xs={6}>
        <StyledSelect
          select
          label="o imóvel apresenta vícios de construção aparentes"
          value={inputs.construction_defects}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="true">sim</StyledMenuItem>
          <StyledMenuItem value="false">não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={6}>
        {inputs.construction_defects === 'true' && (
          <StyledTextField
            label="justifique"
            required
            value={inputs.construction_defects_reason}
            disabled={readOnly}
          />
        )}
      </Grid>
      <Grid item xs={6}>
        <StyledSelect
          select
          label="o imóvel aparenta condições de habitabilidade?"
          value={inputs.liveability_conditions}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="true">sim</StyledMenuItem>
          <StyledMenuItem value="false">não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={6}>
        {inputs.liveability_conditions === 'false' && (
          <StyledTextField
            label="justifique"
            required
            value={inputs.unlivability_reason}
            disabled={readOnly}
          />
        )}
      </Grid>
      <Grid item xs={6}>
        <Tooltip
          arrow
          placement="top"
          title="o imóvel é afetado significativamente por fatores ambientais, climáticos, localização, etc"
        >
          <StyledSelect
            select
            label="o imóvel é afetado significativamente por fatores ambientais, climáticos, localização, etc"
            value={
              inputs.affected_by_environmental_climatic_or_localization_factors
            }
            disabled={readOnly}
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={6}>
        <Tooltip
          arrow
          placement="top"
          title="o imóvel foi construído a partir do sistema inovador regulamentado pelo DATEC com chancela do SINAT no âmbito do PBQP-H"
        >
          <StyledSelect
            select
            label="o imóvel foi construído a partir do sistema inovador regulamentado pelo DATEC com chancela do SINAT no âmbito do PBQP-H"
            value={
              inputs.constructed_after_inovation_system_ruled_by_DATEC_with_approval_of_SINAT_within_the_scope_of_PBQP_H
            }
            disabled={readOnly}
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>fatores valorizantes</FormLabel>
      </Grid>
      {valuingFactors.map((item) => (
        <Grid item xs={3} key={item.label}>
          <FormGroup>
            <FormControlLabel
              control={
                <StyledCheckbox
                  value={item.value}
                  checked={inputs.valuing_factors.includes(item.value)}
                  disabled={readOnly}
                />
              }
              label={item.label}
            />
          </FormGroup>
        </Grid>
      ))}
      <Grid item xs={3}>
        <StyledTextField
          placeholder="outros"
          value={inputs.other_valuing_factors}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel>restritivos/depreciação</FormLabel>
      </Grid>
      {depreciativeFactors.map((item) => (
        <Grid item xs={3} key={item.label}>
          <FormGroup>
            <FormControlLabel
              control={
                <StyledCheckbox
                  value={item.value}
                  checked={inputs.depreciative_factors.includes(item.value)}
                  disabled={readOnly}
                />
              }
              label={item.label}
            />
          </FormGroup>
        </Grid>
      ))}
      <Grid item xs={6} />
      <Grid item xs={3}>
        <StyledTextField
          placeholder="outros"
          value={inputs.other_depreciative_factors}
          disabled={readOnly}
        />
      </Grid>
    </Grid>
  );
}
