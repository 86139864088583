import {
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Tooltip,
} from '@mui/material';

import { MicroregionData } from '../../../../api/theHive/statisticalReport/types';
import {
  StyledCheckbox,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../styles';
import { infrastructureComponents, publicServices } from './utils';

interface MicroregionProps {
  inputs: MicroregionData;
  readOnly: boolean;
}

export function Microregion({
  inputs,
  readOnly,
}: MicroregionProps): JSX.Element {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <StyledSelect
          disabled={readOnly}
          select
          label="região - uso predominante"
          value={inputs.region_predominant_use}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="residencial unifamiliar">
            residencial unifamiliar
          </StyledMenuItem>
          <StyledMenuItem value="residencial multifamiliar">
            residencial multifamiliar
          </StyledMenuItem>
          <StyledMenuItem value="comercial">comercial</StyledMenuItem>
          <StyledMenuItem value="industrial">industrial</StyledMenuItem>
          <StyledMenuItem value="institucional">institucional</StyledMenuItem>
          <StyledMenuItem value="outro">outro</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={4}>
        <StyledSelect
          disabled={readOnly}
          select
          label="padrão construtivo predominante na região"
          value={inputs.constructive_standard}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="alto (superior, luxo)">
            alto (superior, luxo)
          </StyledMenuItem>
          <StyledMenuItem value="alto (por predominância)">
            alto (por predominância)
          </StyledMenuItem>
          <StyledMenuItem value="normal (com aspecto de alto)">
            normal (com aspecto de alto)
          </StyledMenuItem>
          <StyledMenuItem value="normal (forte predominância)">
            normal (forte predominância)
          </StyledMenuItem>
          <StyledMenuItem value="normal (com aspecto de baixo)">
            normal (com aspecto de baixo)
          </StyledMenuItem>
          <StyledMenuItem value="baixo">baixo</StyledMenuItem>
          <StyledMenuItem value="mínimo">mínimo</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={4}>
        <StyledSelect select label="acessos" disabled={readOnly}>
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="bom / boa">bom / boa</StyledMenuItem>
          <StyledMenuItem value="muito bom">muito bom</StyledMenuItem>
          <StyledMenuItem value="ótimo">ótimo</StyledMenuItem>
          <StyledMenuItem value="regular">regular</StyledMenuItem>
          <StyledMenuItem value="ruim">ruim</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={12}>
        <Tooltip
          arrow
          placement="top"
          // eslint-disable-next-line max-len
          title="o imóvel está localizado em área com lençol freático raso, região de alagamentos ou classificada como de risco pela defesa civil"
        >
          <StyledSelect
            disabled={readOnly}
            select
            // eslint-disable-next-line max-len
            label="o imóvel está localizado em área com lençol freático raso, região de alagamentos ou classificada como de risco pela defesa civil"
            value={String(
              inputs.has_shallow_water_table_flooding_or_risk_zone_classification
            )}
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>infraestrutura urbana</FormLabel>
      </Grid>
      {infrastructureComponents.map((item) => (
        <Grid item xs={3} key={item.label}>
          <FormGroup>
            <FormControlLabel
              control={
                <StyledCheckbox
                  disabled={readOnly}
                  value={item.value}
                  checked={inputs.infrastructure_components.includes(
                    item.value
                  )}
                />
              }
              label={item.label}
            />
          </FormGroup>
        </Grid>
      ))}
      <Grid item xs={12}>
        <FormLabel>serviços públicos e comunitários</FormLabel>
      </Grid>
      {publicServices.map((item) => (
        <Grid item xs={3} key={item.label}>
          <FormGroup>
            <FormControlLabel
              control={
                <StyledCheckbox
                  disabled={readOnly}
                  value={item.value}
                  checked={inputs.public_services.includes(item.value)}
                />
              }
              label={item.label}
            />
          </FormGroup>
        </Grid>
      ))}
      <Grid item xs={4}>
        <StyledTextField
          disabled={readOnly}
          label="outros"
          value={inputs.other_public_services}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          disabled={readOnly}
          label="aspectos importantes observados"
          value={inputs.observations}
        />
      </Grid>
    </Grid>
  );
}
