/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, InputAdornment } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { listAnnouncements } from '../../../../../api/theHive/announcement';
import { listNoticeCompanies } from '../../../../../api/theHive/notices';
import { NoticeCompaniesData } from '../../../../../api/theHive/notices/types';
import {
  IconSearchMS,
  IconArrowCircleLeftMS,
} from '../../../../../constants/icons';
import { GlobalContext } from '../../../../../context/global';
import useErrorMessage from '../../../../../hooks/useErrorMessage';
import { BackButton } from '../../CompanyInfo/styles';
import { AnnouncementTable } from '../components/AnnouncementTable';
import { NoticeAttachments } from '../components/Attachments';
import { CompaniesTable } from '../components/CompaniesTable';
import { CoverageAreaGrid } from '../components/CoverageAreaGrid';
import { useFetchCoverageOperations } from '../components/hooks/coverage';
import {
  useFetchNoticeDetails,
  useNoticeActions,
} from '../components/hooks/notices';
import { useTypologyGroups } from '../components/hooks/typology';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { ModalAddingCoverage } from '../components/ModalAddingCoverage';
import { ModalAddTypology } from '../components/ModalAddTypology';
import { NewAnnouncementModal } from '../components/NewAnnouncementmodal';
import { TypologyGroupGrid } from '../components/TypologyGroupGrid';
import { noticesData } from '../NoticeDetails/mockData';
import { AnnouncementData } from '../NoticeDetails/types';
import { StyledInputBase } from '../styles';
import { CustomDatePicker } from './CustomDatePicker';
import {
  NewTitleNotice,
  TabContainerNotice,
  StyledTextFieldDefault,
  GridButton,
  AddTypologies,
  StyledTextFieldObservation,
  CreateNoticeButton,
  StyledDivider,
  AddAnnouncement,
  CancelButton,
} from './styles';

export function NewNotice(): JSX.Element {
  const [showCoverageModal, setShowCoverageModal] = useState(false);
  const [showTypologyModal, setShowTypologyModal] = useState(false);
  const [showNewAnnouncementmodal, setShowNewAnnouncementmodal] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [pageCompanies, setPageCompanies] = useState(1);
  const [isSearching, setIsSearching] = useState(false);
  const [totalPagesCompanies, setTotalPagesCompanies] = useState(12);
  const ITEMS_PER_PAGE = 10;
  const [companies, setCompanies] = useState<NoticeCompaniesData[]>([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const { getErrorMessage } = useErrorMessage();
  const searchTimeout = useRef<NodeJS.Timeout>();

  const [announcements, setAnnouncements] = useState<AnnouncementData[]>([]);
  const [pageAnnouncement, setPageAnnouncement] = useState(1);
  const [announcementsTotalPages, setAnnouncementsTotalPages] = useState(0);
  const ANNOUNCEMENTS_PER_PAGE = 10;
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const isNewNotice = location.pathname.includes('new');

  const { notice } = useParams();
  const noticeId = parseInt(notice as string, 10);

  const {
    currentNoticeName,
    setCurrentNoticeName,
    name,
    setName,
    observation,
    setObservation,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    isLoading,
  } = useFetchNoticeDetails({ noticeId, isNewNotice });

  const { handleFormSubmit } = useNoticeActions({
    noticeId,
    isNewNotice,
  });

  const { typologyGroups, fetchTypologyGroups } = useTypologyGroups(noticeId);

  const { coverageData, getCoverage } = useFetchCoverageOperations({
    noticeId,
  });

  const fetchCompanies = async (search: string): Promise<void> => {
    try {
      setIsSearching(true);

      const response = await listNoticeCompanies(
        noticeId,
        pageCompanies,
        ITEMS_PER_PAGE,
        search
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      setCompanies(response.data || []);
      setTotalPagesCompanies(response.detail.total_pages || 0);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setIsSearching(false);
    }
  };

  useEffect(() => {
    fetchCompanies(searchTerm);
  }, [pageCompanies, searchTerm]);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    setPageCompanies(1);

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      fetchCompanies(newSearchTerm);
    }, 600);
  };

  useEffect(() => {
    if (!isNewNotice) {
      getCoverage();
    }
  }, [getCoverage]);

  useEffect(() => {
    fetchTypologyGroups();
  }, [fetchTypologyGroups]);

  const validateDates = useMemo(() => {
    return (start: Dayjs | null, end: Dayjs | null) => {
      if (start && end && start.isAfter(end)) {
        setSnackbarMessage(
          'A data de início não pode ser maior que a data final'
        );
        setErrorMessage(true);
        setOpenSnackbar(true);
        return false;
      }
      return true;
    };
  }, [setSnackbarMessage, setErrorMessage, setOpenSnackbar]);

  const validateForm = useCallback(() => {
    const isValid =
      !!name &&
      !!observation &&
      !!startDate &&
      !!endDate &&
      validateDates(startDate, endDate);

    setIsFormValid(isValid);
  }, [name, observation, startDate, endDate, validateDates]);

  const navigateBack = useCallback((): void => {
    let basePath: string;
    if (isNewNotice) {
      basePath = '/the-hive/notices/';
    } else {
      const previousPath = location.pathname.split('/edit')[0];
      basePath = previousPath;
    }
    navigate(basePath);
  }, [isNewNotice, location.pathname, navigate]);

  const handleSubmit = useCallback(async (): Promise<void> => {
    await handleFormSubmit({
      name,
      observation,
      startDate,
      endDate,
    });
  }, [handleFormSubmit, name, observation, startDate, endDate]);

  const fetchAnnouncements = async (): Promise<void> => {
    try {
      const response = await listAnnouncements(
        noticeId,
        pageAnnouncement,
        ANNOUNCEMENTS_PER_PAGE
      );

      if (response.data) {
        const transformedAnnouncements: AnnouncementData[] = response.data.map(
          (announcement) => ({
            id: announcement.id,
            subject: announcement.subject,
            contents: announcement.contents,
            is_visible: announcement.is_visible,
          })
        );

        setAnnouncements(transformedAnnouncements);
        setAnnouncementsTotalPages(response.detail.total_pages || 0);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleAnnouncementUpdated = useCallback(async () => {
    await fetchAnnouncements();
  }, [fetchAnnouncements, pageAnnouncement]);

  useEffect(() => {
    if (noticeId) {
      fetchAnnouncements();
    }
  }, [noticeId, pageAnnouncement]);

  const handleStartDateChange = useCallback(
    (newValue: Dayjs | null): void => {
      setStartDate(newValue);
      validateForm();
    },
    [validateForm]
  );

  const handleEndDateChange = useCallback(
    (newValue: Dayjs | null): void => {
      setEndDate(newValue);
      validateForm();
    },
    [validateForm]
  );

  const handleNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setName(event.target.value);
  };

  const handleObservationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setObservation(event.target.value);
  };

  const handleShowTypologyModal = useCallback(() => {
    setShowTypologyModal(true);
  }, []);

  const handleCloseTypologyModal = useCallback(() => {
    setShowTypologyModal(false);
  }, []);

  const handleShowCoverageModal = useCallback(() => {
    setShowCoverageModal(true);
  }, []);

  const handleCloseCoverageModal = useCallback(() => {
    setShowCoverageModal(false);
  }, []);

  const handleShowNewAnnouncementmodal = useCallback(() => {
    setShowNewAnnouncementmodal(true);
  }, []);

  const handleCloseNewAnnouncementmodal = useCallback(() => {
    setShowNewAnnouncementmodal(false);
  }, []);

  const getNoticeDataCallback = useCallback(() => {
    const notice = noticesData.find((notice) => notice.notice_id === noticeId);
    if (notice) {
      setCurrentNoticeName(notice.name);
      setStartDate(dayjs(notice.start_date));
      setEndDate(dayjs(notice.end_date));
      setName(notice.name);
      setObservation(notice.observation);
    }
  }, [noticeId]);

  useEffect(() => {
    validateForm();
  }, [name, observation, startDate, endDate, validateForm]);

  useEffect(() => {
    if (!isNewNotice) {
      getNoticeDataCallback();
    }
  }, [getNoticeDataCallback, isNewNotice]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <BackButton onClick={navigateBack}>{IconArrowCircleLeftMS}</BackButton>
        <TabContainerNotice>
          <NewTitleNotice>
            {isNewNotice ? 'Novo edital' : currentNoticeName}
          </NewTitleNotice>
          <Grid item container lg={12} xl={9.5} mt={1}>
            <Grid item container xs={6}>
              <Grid item>
                <StyledTextFieldDefault
                  label="Nome"
                  variant="outlined"
                  value={name}
                  onChange={handleNameChange}
                />
              </Grid>
              {!isNewNotice && (
                <GridButton item ml={2}>
                  <NoticeAttachments
                    noticeId={noticeId}
                    disabled={isNewNotice}
                  />
                </GridButton>
              )}
            </Grid>
            <Grid item container xs={6}>
              <Grid item>
                <CustomDatePicker
                  label="Início da Vigência"
                  value={startDate}
                  onChange={handleStartDateChange}
                  placeholder="Data de início"
                />
              </Grid>
              <Grid item ml={3}>
                <CustomDatePicker
                  label="Fim da Vigência"
                  value={endDate}
                  onChange={handleEndDateChange}
                  placeholder="Data do fim"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} mt={4}>
            <StyledTextFieldObservation
              label="Observação"
              variant="outlined"
              multiline
              rows={3}
              placeholder="Escreva uma observação"
              value={observation}
              onChange={handleObservationChange}
            />
          </Grid>
          <Grid item container justifyContent="end" xs={12} mt={4}>
            <CreateNoticeButton
              onClick={handleSubmit}
              disabled={!isFormValid}
              style={{
                opacity: isFormValid ? 1 : 0.5,
                cursor: isFormValid ? 'pointer' : 'not-allowed',
              }}
            >
              {isNewNotice ? 'Criar edital' : 'Confirmar alterações'}
            </CreateNoticeButton>
          </Grid>
          {!isNewNotice && (
            <>
              <Grid item container xs={10} justifyContent="start" mt={5}>
                <AddTypologies onClick={() => handleShowCoverageModal()}>
                  Adicionar cobertura
                </AddTypologies>
              </Grid>
              <CoverageAreaGrid
                data={coverageData}
                fetchCoverage={getCoverage}
              />
              <Grid item container xs={10} justifyContent="start" mt={5}>
                <AddTypologies onClick={() => handleShowTypologyModal()}>
                  Adicionar serviços
                </AddTypologies>
              </Grid>
              <TypologyGroupGrid
                data={typologyGroups}
                fetchTypologyGroups={fetchTypologyGroups}
              />
              <Grid item container xs={12}>
                <StyledDivider />
              </Grid>
              <Grid item container justifyContent="end" xs={12}>
                <AddAnnouncement onClick={handleShowNewAnnouncementmodal}>
                  novo comunicado
                </AddAnnouncement>
              </Grid>
              <Grid item container mt={3}>
                <AnnouncementTable
                  data={announcements}
                  page={pageAnnouncement}
                  totalPages={announcementsTotalPages}
                  setPage={setPageAnnouncement}
                  onAnnouncementUpdated={handleAnnouncementUpdated}
                />
              </Grid>
              <Grid item container justifyContent="end" xs={12} mt={3}>
                <Box display="flex" alignItems="center" mr={4}>
                  <StyledInputBase
                    placeholder="buscar empresa"
                    inputProps={{ 'aria-label': 'pesquisar', maxLength: 14 }}
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                    startAdornment={
                      <InputAdornment
                        position="start"
                        sx={{ color: '#004D43' }}
                      >
                        {IconSearchMS}
                      </InputAdornment>
                    }
                  />
                </Box>
                <AddAnnouncement
                  onClick={() => navigate(`${location.pathname}/add-company`)}
                >
                  adicionar empresa
                </AddAnnouncement>
              </Grid>
              <Grid item container mt={3}>
                <CompaniesTable
                  data={companies}
                  page={pageCompanies}
                  totalPages={totalPagesCompanies}
                  setPage={setPageCompanies}
                  isLoading={isSearching}
                />
              </Grid>
              <Grid item container justifyContent="end" xs={12} mt={5}>
                <Grid item mr={2}>
                  <CancelButton>Cancelar</CancelButton>
                </Grid>
                <Grid>
                  <CreateNoticeButton>Salvar</CreateNoticeButton>
                </Grid>
              </Grid>
            </>
          )}
        </TabContainerNotice>
      </LocalizationProvider>
      <ModalAddingCoverage
        isModalOpen={showCoverageModal}
        onCloseModal={handleCloseCoverageModal}
        title="Área de cobertura"
        fetchCoverage={getCoverage}
      />
      <ModalAddTypology
        typologyGroups={typologyGroups}
        isModalOpen={showTypologyModal}
        onCloseModal={handleCloseTypologyModal}
        title="Adicionar serviços"
        fetchTypologyGroups={fetchTypologyGroups}
      />
      <NewAnnouncementModal
        announcements={announcements}
        setAnnouncements={setAnnouncements}
        isModalOpen={showNewAnnouncementmodal}
        onCloseModal={handleCloseNewAnnouncementmodal}
        title="Novo comunicado"
        onAnnouncementUpdated={handleAnnouncementUpdated}
      />
    </>
  );
}
