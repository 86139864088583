import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';

import { AreaInformationData } from '../../../../../../api/theHive/statisticalReport/types';
import { StyledTableCellBody, StyledTableRowBody } from '../../../styles';

interface TableAreaProps {
  inputs: AreaInformationData;
  readOnly: boolean;
}

export function TableArea({ inputs, readOnly }: TableAreaProps): JSX.Element {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: '#E9E1ED',
              border: '1px solid #ccc',
            }}
          >
            <TableCell
              rowSpan={3}
              align="center"
              sx={{ backgroundColor: 'white', border: '1px solid #ccc' }}
            >
              área(m²)
            </TableCell>
            <TableCell
              colSpan={3}
              align="center"
              sx={{ backgroundColor: '#F4EEF6', border: '1px solid #ccc' }}
            >
              área averbada
            </TableCell>
            <TableCell
              rowSpan={2}
              align="center"
              sx={{ border: '1px solid #ccc' }}
            >
              área não averbada
            </TableCell>
            <TableCell
              rowSpan={2}
              align="center"
              sx={{ border: '1px solid #ccc' }}
            >
              total área privativa (averbada + não averbada)
            </TableCell>
            <TableCell
              rowSpan={2}
              align="center"
              sx={{ border: '1px solid #ccc' }}
            >
              área total do imóvel (averbadas + não averbadas)
            </TableCell>
          </TableRow>
          <TableRow
            sx={{
              backgroundColor: '#E9E1ED',
              border: '1px solid #ccc',
            }}
          >
            <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
              área privativa
            </TableCell>
            <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
              área comum (m²)
            </TableCell>
            <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
              total área averbada
            </TableCell>
          </TableRow>
          <StyledTableRowBody>
            <StyledTableCellBody>
              <TextField
                disabled={readOnly}
                multiline
                value={inputs.registered.private}
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TextField
                disabled={readOnly}
                multiline
                value={inputs.registered.public}
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TextField
                disabled={readOnly}
                multiline
                value={inputs.registered.total}
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TextField
                disabled={readOnly}
                multiline
                value={inputs.not_registered}
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TextField
                disabled={readOnly}
                multiline
                value={inputs.total_private_area}
              />
            </StyledTableCellBody>
            <StyledTableCellBody>
              <TextField
                disabled={readOnly}
                multiline
                value={String(inputs.total_area)}
              />
            </StyledTableCellBody>
          </StyledTableRowBody>
        </TableHead>
      </Table>
    </TableContainer>
  );
}
