/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useContext, useEffect, useState } from 'react';

import {
  convertRegistrationUf,
  RegistrationUf,
  StatusCode,
} from '../../../../api/enumerations';
import { getPipelineFilters } from '../../../../api/workOrders';
import {
  AddressData,
  GetWorkOrdersParams,
} from '../../../../api/workOrders/types';
import { SelectTextField } from '../../../../components/CustomInput';
import { WhiteCircularProgress } from '../../../../components/UI/Typography';
import { IconTuneMS } from '../../../../constants/icons';
import { SelectProps } from '../../../../constants/selectOptions';
import { GlobalContext } from '../../../../context/global';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import {
  BorderButton,
  SectionBox,
  SpaceBetweenBox,
  StyledButton,
  StyledDialog,
  StyledTextField,
  SubmitBox,
  SubmitButton,
} from './styles';

interface SearchModalProps {
  setFilters: (value: GetWorkOrdersParams | undefined) => void;
  searchLoading: boolean;
  isFiltersActive: boolean;
}

export default function SearchModal({
  setFilters,
  searchLoading,
  isFiltersActive,
}: SearchModalProps): JSX.Element {
  const [selectedFilters, setSelectedFilters] = useState<GetWorkOrdersParams>();
  const [ufOptions, setUfOptions] = useState<RegistrationUf[]>([]);
  const [cityOptions, setCityOptions] = useState<SelectProps[]>([]);
  const [licensedOptions, setLicensedOptions] = useState<SelectProps[]>([]);
  const [uf, setUf] = useState(0);
  const [city, setCity] = useState(0);
  const [licensed, setLicensed] = useState(0);
  const [date, setDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const defaultOption = [{ value: 0, label: 'selecione uma opção' }];
  const today = dayjs();
  const minStartDate = endDate ? endDate.subtract(30, 'day') : null;
  const maxStartDate = endDate || today;
  const minEndDate = date || null;
  const maxEndDate = date
    ? date.add(30, 'day').isAfter(today)
      ? today
      : date.add(30, 'day')
    : today;

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleCleanFilters = (): void => {
    setUf(0);
    setCity(0);
    setLicensed(0);
    setDate(null);
    setEndDate(null);
    setSelectedFilters(undefined);
    setCityOptions([]);
    setFilters(undefined);
  };

  const handleResetAndClose = (): void => {
    handleCleanFilters();
    setAnchorEl(null);
  };

  const getFiltersOptions = useCallback(async () => {
    const params: GetWorkOrdersParams = {};

    if (selectedFilters?.uf) params.uf = selectedFilters?.uf;
    if (selectedFilters?.city) params.city = selectedFilters?.city;
    if (selectedFilters?.licenseCompanyId)
      params.licenseCompanyId = selectedFilters?.licenseCompanyId;

    try {
      const response = await getPipelineFilters(params);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      const ufs = [0, ...response.data.address.map((item) => item.uf)];
      setUfOptions(ufs);

      if (response.data.selected_uf) {
        const selectedAddress = response.data.address.find(
          (item: AddressData) => item.uf === response.data.selected_uf
        );

        const citiesOfSelectedUf =
          selectedAddress?.cities?.map((item) => ({
            value: item.id,
            label: item.name,
          })) || [];

        setCityOptions([...defaultOption, ...citiesOfSelectedUf]);
      }

      if (response.data.worker_companies) {
        const companies =
          response.data.worker_companies.map((item) => ({
            value: item.id,
            label: item.name,
          })) || [];

        setLicensedOptions([...defaultOption, ...companies]);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [selectedFilters]);

  useEffect(() => {
    getFiltersOptions();
  }, [getFiltersOptions]);

  useEffect(() => {
    const filtersData: GetWorkOrdersParams = {};

    if (uf !== RegistrationUf.SELECT) filtersData.uf = uf;
    if (city !== 0) {
      const cityName = cityOptions.find((option) => option.value === city);
      if (cityName) filtersData.city = cityName.label;
    }
    if (licensed !== 0) filtersData.licenseCompanyId = licensed;
    if (date) filtersData.startDate = dayjs(date).format('YYYY-MM-DD');
    if (endDate) filtersData.endDate = dayjs(endDate).format('YYYY-MM-DD');

    if (filtersData && Object.keys(filtersData).length !== 0) {
      setSelectedFilters(filtersData);
    }
  }, [uf, city, licensed, date, endDate]);

  const checkDateConflict = useCallback((): boolean => {
    if ((date && !endDate) || (!date && endDate)) {
      setSnackbarMessage('Selecione a data inicial e final');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return true;
    }
    if (date?.isAfter(endDate)) {
      setSnackbarMessage('A data inicial não pode ser posterior à data final.');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return true;
    }
    return false;
  }, [date, endDate]);

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      if (checkDateConflict()) {
        return;
      }

      if (selectedFilters && Object.keys(selectedFilters).length !== 0) {
        setFilters(selectedFilters);
      }
    },
    [selectedFilters]
  );

  const selectRegistrationUf = (
    ufList: RegistrationUf[] | undefined
  ): SelectProps[] => {
    if (ufList) {
      const enumValues = Object.values(ufList).filter(
        (v) => !Number.isNaN(Number(v))
      );

      return enumValues.map((value) => {
        const valueAsNumber = Number(value);
        const enumKey: RegistrationUf = valueAsNumber;
        return {
          value: valueAsNumber,
          label: convertRegistrationUf(enumKey),
        };
      });
    }
    return [{ value: 0, label: 'selecione uma opção' }];
  };

  return (
    <>
      <StyledButton
        aria-describedby="search"
        onClick={handleClick}
        isfilteractive={isFiltersActive ? 'true' : 'false'}
      >
        {IconTuneMS}
      </StyledButton>
      <StyledDialog
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
        aria-labelledby="search dialog"
      >
        <SectionBox component="form" id="bank-search" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <SelectTextField
                id="uf"
                label="UF"
                value={uf}
                setValue={setUf}
                selectOptions={selectRegistrationUf(ufOptions)}
              />
            </Grid>
            <Grid item xs={4}>
              <SelectTextField
                id="city"
                label="cidade"
                value={city}
                setValue={setCity}
                selectOptions={cityOptions}
                disable={uf === RegistrationUf.SELECT}
              />
            </Grid>
            <Grid item xs={4}>
              <SelectTextField
                id="licensed"
                label="credenciados"
                value={licensed}
                setValue={setLicensed}
                selectOptions={licensedOptions}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="data inicial"
                  value={date}
                  format="DD-MM-YYYY"
                  minDate={minStartDate}
                  maxDate={maxStartDate}
                  slotProps={{
                    textField: {
                      sx: StyledTextField,
                    },
                  }}
                  onChange={(e) => setDate(e)}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="data final"
                  value={endDate}
                  format="DD-MM-YYYY"
                  minDate={minEndDate}
                  maxDate={maxEndDate}
                  slotProps={{
                    textField: {
                      sx: StyledTextField,
                    },
                  }}
                  onChange={(e) => setEndDate(e)}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </SectionBox>
        <SpaceBetweenBox>
          <BorderButton
            disabled={!selectedFilters}
            onClick={handleCleanFilters}
          >
            limpar
          </BorderButton>
          <SubmitBox>
            <BorderButton onClick={handleResetAndClose}>cancelar</BorderButton>
            <SubmitButton
              form="bank-search"
              type="submit"
              disabled={searchLoading}
            >
              {searchLoading ? (
                <WhiteCircularProgress size={22} />
              ) : (
                'pesquisar'
              )}
            </SubmitButton>
          </SubmitBox>
        </SpaceBetweenBox>
      </StyledDialog>
    </>
  );
}
