import {
  BaseUrl,
  myHeadersWithToken,
  myHeadersWithTokenContentType,
} from '../../global';
import {
  AddLicenseProps,
  CreatePublicNoticeData,
  DetailProps,
  DetailResponse,
  ListNoticeAttachments,
  ListNoticeCompaniesResponse,
  ListNoticesProps,
  UpdatePublicNoticeData,
} from './types';

export const createPublicNotice = (
  data: CreatePublicNoticeData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };

  return fetch(`${BaseUrl}/api/V1/public-notices`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const listPublicNotices = (
  page: number,
  size: number,
  searchTerm: string
): Promise<ListNoticesProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };

  return fetch(
    `${BaseUrl}/api/V1/public-notices?page=${page}&size=${size}&search=${searchTerm}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getNotice = (id: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };

  return fetch(`${BaseUrl}/api/V1/public-notices/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const updatePublicNotice = (
  id: number,
  data: UpdatePublicNoticeData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PATCH',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };

  return fetch(`${BaseUrl}/api/V1/public-notices/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const deletePublicNotice = (id: number): Promise<DetailProps> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithTokenContentType(),
  };

  return fetch(`${BaseUrl}/api/V1/public-notices/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const listPublicNoticeAttachment = (
  noticeId: number,
  page: number,
  size: number
): Promise<ListNoticeAttachments> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };

  return fetch(
    `${BaseUrl}/api/V1/public-notices/${noticeId}/attachments?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const createPublicNoticeAttachment = (
  noticeId: number,
  data: FormData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithToken(),
    body: data,
  };

  return fetch(
    `${BaseUrl}/api/V1/public-notices/${noticeId}/attachments`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const deletePublicNoticeAttachment = (
  fileId: number
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithToken(),
  };

  return fetch(`${BaseUrl}/api/V1/notice-attachments/${fileId}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const addLicense = (
  notice: number,
  data: AddLicenseProps
): Promise<DetailResponse> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };

  return fetch(
    `${BaseUrl}/api/V1/public-notices/${notice}/licensed-companies`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const listNoticeCompanies = (
  id: number,
  page: number,
  size: number,
  search: string
): Promise<ListNoticeCompaniesResponse> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };

  return fetch(
    // eslint-disable-next-line max-len
    `${BaseUrl}/api/V1/public-notices/${id}/licensed-companies?page=${page}&size=${size}&search=${search}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
