import dayjs, { Dayjs } from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { ClientKind, QuestionType } from '../api/enumerations';
import {
  Icon123MS,
  IconCalendarMonthMS,
  IconCheckBoxMS,
  IconCheckCircleMS,
  IconNotesMS,
  IconRadioButtonCheckedMS,
} from '../constants/icons';

export const cleanValue = (value: string): string => {
  const cleaned = value.replace(/\D/g, '');
  return cleaned;
};

export const checkCardColor = (
  type: number,
  over?: boolean,
  alert?: boolean,
  calculation?: boolean
): string => {
  if (over || calculation) {
    return '#820B0B';
  }
  if (alert) {
    return '#EBA839';
  }
  if (type === ClientKind.BRASIL_BANK) {
    return '#F5F430';
  }
  if (type === ClientKind.CAIXA) {
    return '#005CA9 ';
  }
  if (type === ClientKind.CORPORATE) {
    return '#1A1A1A';
  }
  return '#004D43';
};

export const checkQuestionSelection = (type: number): boolean => {
  if (type === QuestionType.MULTIPLE_CHOICE) {
    return true;
  }
  return false;
};

export const checkQuestionIcon = (type: number): JSX.Element => {
  if (type === QuestionType.SHORT_ANSWER) {
    return IconNotesMS;
  }
  if (type === QuestionType.SINGLE_SELECTION) {
    return IconRadioButtonCheckedMS;
  }
  if (type === QuestionType.MULTIPLE_CHOICE) {
    return IconCheckBoxMS;
  }
  if (type === QuestionType.LIST) {
    return IconCheckCircleMS;
  }
  if (type === QuestionType.DATE) {
    return IconCalendarMonthMS;
  }
  if (type === QuestionType.NUMERIC) {
    return Icon123MS;
  }
  return IconNotesMS;
};

export const convertDate = (date: string): string => {
  const formatDate = new Date(date);
  const localDate = formatDate.toLocaleDateString('pt-br');
  return `${localDate}`;
};

export const convertPercentage = (value: number): string => {
  const percent = value * 100;
  const decimal = percent.toFixed(2);
  const parts = decimal.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join(',');
};

export const convertToFloatNumber = (value: string): number => {
  const newValue = value.replace(/[^\d,]/g, '');
  const valueToNumber = parseFloat(newValue.replace(',', '.'));
  return valueToNumber;
};

export const convertFloatDot = (value: string): number => {
  const temp = value.replace(' %', '');
  const newValue = temp.replace(/(\d{2})(\d{2})/, '$1.$2');
  return Number(newValue);
};

export const formatCep = (value: string): string => {
  const parts = cleanValue(value);
  const newValue = parts.replace(/(\d{5})(\d)/, '$1-$2');
  return newValue;
};

export const formatCPF = (cpf: string): string => {
  const cleaned = cpf.replace(/\D/g, '');

  const formatted = cleaned.replace(
    /(\d{3})(\d{3})(\d{3})(\d{2})/,
    '$1.$2.$3-$4'
  );

  return formatted;
};

export const formatCnpjCpf = (value: string): string => {
  const cnpjCpf = value.replace(/\D/g, '');

  if (cnpjCpf.length < 12) {
    return cnpjCpf
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  }

  return cnpjCpf
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})$/, '$1-$2');
};

export const formatDate = (date: string): string => {
  const localDate = date?.split('-').reverse().join('/');
  return `${localDate}`;
};

export const formatOnlyDate = (date: string | undefined): string => {
  if (date) {
    dayjs.extend(utc);
    dayjs.extend(tz);
    const dateTime = dayjs(date).tz('America/Sao_Paulo');
    const formattedHour = dateTime.format('DD/MM/YYYY');

    return formattedHour;
  }
  return '';
};

export const formatDateAndTime = (date: string | undefined): string => {
  if (date) {
    dayjs.extend(utc);
    dayjs.extend(tz);
    const dateTime = dayjs(date).tz('America/Sao_Paulo');
    const formattedHour = dateTime.format('DD/MM/YYYY - HH:mm');

    return formattedHour;
  }
  return '';
};

export const formatFloatNumber = (value: number): string => {
  const parts = value.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join(',');
};

export const formatFirstLetter = (value: string): string => {
  const newValue = value.charAt(0).toUpperCase();
  return newValue + value.slice(1);
};

export const formatFloatNumberDecimal = (value: number): string => {
  const decimal = value.toFixed(2);
  const parts = decimal.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join(',');
};

export const formatFloatNumberDot = (value: number): string => {
  const decimal = value.toFixed(2);
  const parts = decimal.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parts.join(',');
};

export const formatPhone = (phoneNumber: string): string => {
  const cleaned = cleanValue(phoneNumber);
  const digit = cleaned.replace(/(\d{2})(\d)/, '($1) $2');
  const newValue = digit.replace(/(\d)(\d{4})$/, '$1-$2');
  return newValue;
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  const cleaned = cleanValue(phoneNumber);
  const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phoneNumber;
};

export const formatDecimalPlaces = (value: number): number => {
  if (value % 1 !== 0) {
    return Number(value.toFixed(3));
  }
  return value;
};

export const formatReal = (input: number): string => {
  if (!input) return '';
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(input);
};

export const formatTime = (date: Dayjs): string => {
  const roundDate = date.second(0);
  const formattedTime = roundDate.format('HH:mm:ss');
  return formattedTime;
};

export const getMissingDays = (today: Dayjs, until: Dayjs): string => {
  if (today.isSame(until, 'd')) {
    return '0 dias';
  }
  const missingDays = until.diff(today, 'd') + 1;
  if (missingDays === 1) {
    return `${missingDays} dia`;
  }
  return `${missingDays} dias`;
};

export const generateRandomHexColor = (): string => {
  const letters = '0123456789ABCDEF';
  return `#${Array.from(
    { length: 6 },
    () => letters[Math.floor(Math.random() * 16)]
  ).join('')}`;
};

export const getRandomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min)) + min;
};

interface IOption {
  id: number;
  isUtilized: boolean;
  position: google.maps.LatLngLiteral;
}

export const offsetDuplicateCoord = (coordinates: IOption[]): IOption[] => {
  const seenCoordinates: { [key: string]: boolean } = {};
  const R = 6378137.0;

  // the duplicate coord will be set to the North and East of its origin using the Haversine formula
  return coordinates.map((option) => {
    const coord = option.position;
    const key = `${coord.lat.toFixed(6)},${coord.lng.toFixed(6)}`;

    const DistanceNorth = getRandomNumber(3, 7);
    const DistanceEast = getRandomNumber(3, 7);

    const updatedOption = { ...option, position: { ...coord } };

    if (seenCoordinates[key]) {
      const dLat = DistanceNorth / R;
      const dLon = DistanceEast / (R * Math.cos((Math.PI * coord.lat) / 180));

      updatedOption.position.lat += (dLat * 180) / Math.PI;
      updatedOption.position.lng += (dLon * 180) / Math.PI;
    } else {
      seenCoordinates[key] = true;
    }

    return updatedOption;
  });
};

export const validateCellphone = (cellphone: string): boolean => {
  const cellphoneValue = cleanValue(cellphone);

  return /[1-9]{2}9[1-9]{1}[0-9]{7}/g.test(cellphoneValue);
};

export const validatePhone = (phone: string): boolean => {
  const phoneValue = cleanValue(phone);

  return /[1-9]{2}[0-9]{7}/g.test(phoneValue);
};

export const validateEmail = (email: string): boolean => {
  return /\S+@\S+\.\S+/.test(email);
};

export const validateImg = (e: string): boolean => {
  return /^image/.test(e);
};

export const validateFiles = (e: string): boolean => {
  return /^image\/|^application\/(pdf)/.test(e);
};

export const validatePassword = (password: string): boolean => {
  // Requires 8 characters, at least one uppercase, one lowercase and one number
  return /^(?!.* )(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/.test(
    password
  );
};

export const validateUserName = (name: string): boolean => {
  return /^[^0-9]+$/.test(name);
};
