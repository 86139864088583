/* eslint-disable react/require-default-props */
import { FormLabel, Grid } from '@mui/material';

import { GalleryPictureData } from '../../../../api/pictures/types';
import { RoomData } from '../../../../api/rooms/types';
import { WorkOrderData } from '../../../../api/workOrders/types';
import { InspectionSelectedPhotos } from '../../../../pages/Report/InspectionSelectedPhotos';

interface PicturesProps {
  propertyData: WorkOrderData;
  rooms: RoomData[];
  selectedPictures: GalleryPictureData[];
  removeSelectedPictures?: (id: number) => Promise<void>;
}

export function Pictures({
  propertyData,
  rooms,
  selectedPictures,
  removeSelectedPictures,
}: PicturesProps): JSX.Element {
  return (
    <Grid container>
      <Grid item xs={12}>
        {propertyData?.inspection?.id && rooms.length > 0 ? (
          <InspectionSelectedPhotos
            selectedPhotos={selectedPictures}
            inspectionId={propertyData?.inspection?.id}
            removeSelection={removeSelectedPictures}
          />
        ) : (
          <FormLabel>Esta OS não possui imagens</FormLabel>
        )}
      </Grid>
    </Grid>
  );
}
