/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import { Grid, Paper } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { convertPropertyType } from '../../../api/enumerations';
import { GalleryPictureData } from '../../../api/pictures/types';
import { RoomData } from '../../../api/rooms/types';
import { listStatisticalReportAttachment } from '../../../api/theHive/statisticalReport';
import {
  AppraisalInformationData,
  CondominiumInformationProps,
  ConstructionData,
  DiagnosisData,
  EvaluationMethodologyData,
  EvaluationResultData,
  EvaluationSummaryData,
  GeneralConditionsData,
  ImprovementsData,
  InspectionInfoData,
  LandData,
  MicroregionData,
  StatisticalReportAttachmentData,
  StatisticalReportData,
  VariablesData,
  WarrantyData,
} from '../../../api/theHive/statisticalReport/types';
import { WorkOrderData } from '../../../api/workOrders/types';
import { Constants } from '../../../constants/report';
import { useAccordion } from '../../../hooks/useAccordion';
import useGeneral from '../../../hooks/useGeneral';
import { AccordionTitle } from '../AccordionTitle';
import { WorkOrderProgressBar } from '../ProgressBar';
import { statisticalReportValues } from './initialValue';
import { AdditionalInformation } from './InputsBySections/additional-information';
import { AppraisalInformation } from './InputsBySections/AppraisalInformation';
import { Attachments } from './InputsBySections/attachments';
import { Condominium } from './InputsBySections/condominium';
import { Diagnostic } from './InputsBySections/diagnostic';
import { EvaluationResult } from './InputsBySections/evaluation-result';
import { EvaluationSummary } from './InputsBySections/evaluation-summary';
import { FinalRemarks } from './InputsBySections/final-remarks';
import { GeneralConditions } from './InputsBySections/general-conditions';
import { ImprovementsContruction } from './InputsBySections/improvements-construction';
import { Methodology } from './InputsBySections/methodology';
import { Microregion } from './InputsBySections/microregion';
import { Pictures } from './InputsBySections/pictures';
import { ReportInspection } from './InputsBySections/report-inspection';
import { Terrain } from './InputsBySections/terrain';
import { Variables } from './InputsBySections/variables';
import { WarrantyStatements } from './InputsBySections/warranty-statements';
import { ReportTitle, SectionBox } from './styles';
import {
  ReportCompletionState,
  useReportCompletion,
} from './useReportCompletion';

interface StaticalReport {
  propertyData: WorkOrderData;
  rooms: RoomData[];
  selectedPictures: GalleryPictureData[];
  removeSelectedPictures?: (id: number) => Promise<void>;
  readOnly?: boolean;
}

export function StatisticalReport({
  propertyData,
  rooms,
  selectedPictures,
  removeSelectedPictures,
  readOnly = false,
}: StaticalReport): JSX.Element {
  const propertyType =
    (propertyData && convertPropertyType(propertyData.real_estate_kind)) || '';
  const manualReport = propertyData.manual_statistical_report;
  const landApplyValue =
    manualReport && manualReport.land_information !== 'não se aplica' ? 2 : 1;
  const condoApplyValue =
    manualReport && manualReport.condominium_information !== 'não se aplica'
      ? 2
      : 1;
  const improvementsApplyValue =
    manualReport && manualReport.improvements_information !== 'não se aplica'
      ? 2
      : 1;

  const [expandEleven, setExpandEleven] = useState(false);
  const [expandTwelve, setExpandTwelve] = useState(false);
  const [expandThirteen, setExpandThirteen] = useState(false);
  const [expandFourteen, setExpandFourteen] = useState(false);
  const [expandFifteen, setExpandFifteen] = useState(false);
  const [expandSixteen, setExpandSixteen] = useState(false);
  const [expandSeventeen, setExpandSeventeen] = useState(false);
  const [files, setFiles] = useState<StatisticalReportAttachmentData[]>([]);
  const initialInputs = statisticalReportValues(propertyType, manualReport);
  const {
    expandOne,
    setExpandOne,
    expandTwo,
    setExpandTwo,
    expandThree,
    setExpandThree,
    expandFour,
    setExpandFour,
    expandFive,
    setExpandFive,
    expandSix,
    setExpandSix,
    expandSeven,
    setExpandSeven,
    expandEight,
    setExpandEight,
    expandNine,
    setExpandNine,
    expandTen,
    setExpandTen,
  } = useAccordion();

  const { osId } = useGeneral();
  const { reportCompletion, toggleCompletion } = useReportCompletion();

  const getAttachments = useCallback(async () => {
    try {
      const response = await listStatisticalReportAttachment(osId);

      if (response.detail.description || !response.data) {
        throw new Error();
      }

      toggleCompletion('attachments', true);
      setFiles([...files, ...response.data]);
    } catch (error) {
      toggleCompletion('attachments', false);
    }
  }, [osId]);

  useEffect(() => {
    getAttachments();
  }, []);

  useEffect(() => {
    if (manualReport)
      Object.keys(manualReport).forEach((key) => {
        if (key as keyof ReportCompletionState) {
          toggleCompletion(
            key as keyof ReportCompletionState,
            !!manualReport?.[key as keyof StatisticalReportData]
          );
        }
      });
  }, [propertyData]);

  useEffect(() => {
    if (rooms.length > 0) {
      if (selectedPictures.length > 0) {
        toggleCompletion('images', true);
      } else {
        toggleCompletion('images', false);
      }
    } else {
      toggleCompletion('images', true);
    }
  }, [selectedPictures, rooms]);

  useEffect(() => {
    if (files.length > 0) {
      toggleCompletion('attachments', true);
    } else {
      toggleCompletion('attachments', false);
    }
  }, [files]);

  return (
    <Grid container component={Paper} sx={{ padding: '54px' }}>
      <Grid item xs={12} sx={{ marginBottom: '40px' }}>
        <ReportTitle>{Constants.reportTitle}</ReportTitle>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar
          complete={reportCompletion.evaluation_methodology}
        >
          <SectionBox>
            <AccordionTitle
              title="Metodologia da avaliação"
              openAccordion={expandOne}
              setOpenAccordion={setExpandOne}
            />
            {expandOne && (
              <Methodology
                inputs={
                  initialInputs.evaluation_methodology as EvaluationMethodologyData
                }
                readOnly={readOnly}
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.variables}>
          <SectionBox>
            <AccordionTitle
              title="Variáveis"
              openAccordion={expandTwo}
              setOpenAccordion={setExpandTwo}
            />
            {expandTwo && (
              <Variables
                inputs={initialInputs.variables as VariablesData[]}
                readOnly={readOnly}
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.evaluation_summary}>
          <SectionBox>
            <AccordionTitle
              title="Resumo da avaliação"
              openAccordion={expandThree}
              setOpenAccordion={setExpandThree}
            />
            {expandThree && (
              <EvaluationSummary
                inputs={
                  initialInputs.evaluation_summary as EvaluationSummaryData
                }
                readOnly={readOnly}
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.evaluation_result}>
          <SectionBox>
            <AccordionTitle
              title="Resultado da avaliação"
              openAccordion={expandFour}
              setOpenAccordion={setExpandFour}
            />
            {expandFour && (
              <EvaluationResult
                inputs={initialInputs.evaluation_result as EvaluationResultData}
                readOnly={readOnly}
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.attachments}>
          <SectionBox>
            <AccordionTitle
              title="Anexos"
              openAccordion={expandFive}
              setOpenAccordion={setExpandFive}
            />
            {expandFive && <Attachments files={files} />}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.images}>
          <SectionBox>
            <AccordionTitle
              title="Imagens"
              openAccordion={expandSix}
              setOpenAccordion={setExpandSix}
            />
            {expandSix && (
              <Pictures
                propertyData={propertyData}
                rooms={rooms}
                selectedPictures={selectedPictures}
                removeSelectedPictures={removeSelectedPictures}
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.appraisal_information}>
          <SectionBox>
            <AccordionTitle
              title="Imóvel avaliando"
              openAccordion={expandSeven}
              setOpenAccordion={setExpandSeven}
            />
            {expandSeven && (
              <AppraisalInformation
                inputs={
                  initialInputs.appraisal_information as AppraisalInformationData
                }
                propertyType={propertyData.real_estate_kind}
                readOnly={readOnly}
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar
          complete={reportCompletion.appraisal_microregion_information}
        >
          <SectionBox>
            <AccordionTitle
              title="Microrregião do avaliando"
              openAccordion={expandEight}
              setOpenAccordion={setExpandEight}
            />
            {expandEight && (
              <Microregion
                inputs={
                  initialInputs.appraisal_microregion_information as MicroregionData
                }
                readOnly={readOnly}
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.land_information}>
          <SectionBox>
            <AccordionTitle
              title="Terreno"
              openAccordion={expandNine}
              setOpenAccordion={setExpandNine}
            />
            {expandNine && (
              <Terrain
                doesNotApply={landApplyValue}
                inputs={initialInputs.land_information as LandData}
                readOnly={readOnly}
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar
          complete={reportCompletion.condominium_information}
        >
          <SectionBox>
            <AccordionTitle
              title="Condomínio"
              openAccordion={expandTen}
              setOpenAccordion={setExpandTen}
            />
            {expandTen && (
              <Condominium
                propertyType={propertyData.real_estate_kind}
                doesNotApply={condoApplyValue}
                inputs={
                  initialInputs.condominium_information as CondominiumInformationProps
                }
                readOnly={readOnly}
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar
          complete={reportCompletion.improvements_information}
        >
          <SectionBox>
            <AccordionTitle
              title="Benfeitorias / Construção"
              openAccordion={expandEleven}
              setOpenAccordion={setExpandEleven}
            />
            {expandEleven && (
              <ImprovementsContruction
                improvementsInputs={
                  initialInputs.improvements_information as ImprovementsData
                }
                constructionInputs={
                  initialInputs.construction_information as ConstructionData
                }
                doesNotApply={improvementsApplyValue}
                readOnly={readOnly}
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.market_diagnosis}>
          <SectionBox>
            <AccordionTitle
              title="Diagnóstico de mercado"
              openAccordion={expandTwelve}
              setOpenAccordion={setExpandTwelve}
            />
            {expandTwelve && (
              <Diagnostic
                inputs={initialInputs.market_diagnosis as DiagnosisData}
                readOnly={readOnly}
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.general_conditions}>
          <SectionBox>
            <AccordionTitle
              title="Condições gerais"
              openAccordion={expandThirteen}
              setOpenAccordion={setExpandThirteen}
            />
            {expandThirteen && (
              <GeneralConditions
                inputs={
                  initialInputs.general_conditions as GeneralConditionsData
                }
                readOnly={readOnly}
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar
          complete={reportCompletion.inspection_information}
        >
          <SectionBox>
            <AccordionTitle
              title="Vistoria"
              openAccordion={expandFourteen}
              setOpenAccordion={setExpandFourteen}
            />
            {expandFourteen && (
              <ReportInspection
                inputs={
                  initialInputs.inspection_information as InspectionInfoData
                }
                readOnly={readOnly}
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar complete={reportCompletion.warranty_statements}>
          <SectionBox>
            <AccordionTitle
              title="Manifestações de garantia"
              openAccordion={expandFifteen}
              setOpenAccordion={setExpandFifteen}
            />
            {expandFifteen && (
              <WarrantyStatements
                inputs={initialInputs.warranty_statements as WarrantyData}
                readOnly={readOnly}
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar
          complete={
            reportCompletion.independent_unities_complementary_information
          }
        >
          <SectionBox>
            <AccordionTitle
              title="Informações complementares de unidades autônomas incluídas na transação (exclusivo para fins cartorários)"
              openAccordion={expandSixteen}
              setOpenAccordion={setExpandSixteen}
            />
            {expandSixteen && (
              <AdditionalInformation
                inputs={
                  initialInputs.independent_unities_complementary_information as string
                }
                readOnly={readOnly}
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
      <Grid item xs={12}>
        <WorkOrderProgressBar
          complete={reportCompletion.observations}
          lastAccordion
        >
          <SectionBox>
            <AccordionTitle
              title="Observações finais"
              openAccordion={expandSeventeen}
              setOpenAccordion={setExpandSeventeen}
            />
            {expandSeventeen && (
              <FinalRemarks
                inputs={initialInputs.observations as string}
                readOnly={readOnly}
              />
            )}
          </SectionBox>
        </WorkOrderProgressBar>
      </Grid>
    </Grid>
  );
}
