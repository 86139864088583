/* eslint-disable react/require-default-props */
import Color from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useEffect } from 'react';

import { ReaderBox } from './styles';

interface MessageReaderProps {
  messageRichText?: string;
  messageRawContent?: string;
  messageObservation?: string | null;
}

export function MessageReader({
  messageRichText,
  messageRawContent,
  messageObservation,
}: MessageReaderProps): JSX.Element {
  const editor = useEditor({
    extensions: [StarterKit, TextStyle, Color],
    content: '',
  });

  useEffect(() => {
    if (editor) {
      if (!messageRichText && messageObservation) {
        editor.commands.setContent(messageRawContent || '');
        editor.commands.setContent(messageObservation || '');
        return;
      }
      if (!messageRichText) {
        editor.commands.setContent(messageRawContent || '');
        return;
      }
      try {
        const parsedContent = JSON.parse(messageRichText);
        editor.commands.setContent(parsedContent);
      } catch (error) {
        editor.commands.setContent(messageRawContent || '');
      }
    }
  }, [editor, messageRawContent, messageRichText, messageObservation]);

  return (
    <ReaderBox>
      <EditorContent
        editor={editor}
        className="editor-reader"
        style={{
          fontSize: '16px',
          padding: '12px 0',
        }}
      />
    </ReaderBox>
  );
}
