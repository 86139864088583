import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Menu from './components/Menu';
import ScrollToTop from './components/ScrollToTop';
import { Adjustment } from './pages/Adjustment';
import { Agenda } from './pages/Agenda';
import { Archived } from './pages/Archived';
import { ArchivedFiles } from './pages/ArchivedFiles';
import { Calculation } from './pages/Calculation';
import { Entrance } from './pages/Entrance';
import { ErrorPage } from './pages/ErrorPage';
import { ErrorReport } from './pages/ErrorReport';
import { ReportDetails } from './pages/ErrorReport/ReportDetails';
import { FirstAccess } from './pages/FirstAccess';
import { Forms } from './pages/Forms';
import { FormCreation } from './pages/Forms/FormCreation';
import { Hive } from './pages/Hive';
import { CompanyInfo } from './pages/Hive/Tabs/CompanyInfo';
import { Licensed } from './pages/Hive/Tabs/Licensed';
import { Notices } from './pages/Hive/Tabs/Notices';
import { AddCompany } from './pages/Hive/Tabs/Notices/AddCompany';
import { NewNotice } from './pages/Hive/Tabs/Notices/NewNotice';
import { NoticeDetails } from './pages/Hive/Tabs/Notices/NoticeDetails';
import { Requests } from './pages/Hive/Tabs/Requests';
import { Home } from './pages/Home';
import { Inspection } from './pages/Inspection';
import { InspectionAddPhotos } from './pages/InspectionPhotos/AddPhotos';
import { ImageDetails } from './pages/InspectionPhotos/ImageDetails';
import { InspectionPhotoGallery } from './pages/InspectionPhotos/PhotoGallery';
import { Rooms } from './pages/InspectionPhotos/Rooms';
import { SelectRooms } from './pages/InspectionPhotos/SelectRooms';
import { Login } from './pages/Login';
import { Notifications } from './pages/Notifications';
import { Pept } from './pages/Pept';
import { PresentationMode } from './pages/PresentationMode';
import { Report } from './pages/Report';
import { InspectionPhotos } from './pages/Report/InspectionPhotos';
import { Revision } from './pages/Revision';
import { SampleCreation } from './pages/SampleCreation';
import { Schedule } from './pages/Schedule';
import { UserManagement } from './pages/UserManagement';
import { Users } from './pages/Users';
import PrivateRoute from './PrivateRoute';
import { theme } from './theme';

export default function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/first-access" element={<FirstAccess />} />
          <Route path="/forgot-password" element={<FirstAccess />} />
          <Route element={<Menu />}>
            <Route
              path="/home"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path="/home/property/new"
              element={
                <PrivateRoute>
                  <Entrance />
                </PrivateRoute>
              }
            />
            <Route
              path="/home/property/:id/edit"
              element={
                <PrivateRoute>
                  <Entrance />
                </PrivateRoute>
              }
            />
            <Route
              path="/home/property/:id/pept"
              element={
                <PrivateRoute>
                  <Pept />
                </PrivateRoute>
              }
            />
            <Route
              path="/home/property/:id/schedule"
              element={
                <PrivateRoute>
                  <Schedule />
                </PrivateRoute>
              }
            />
            <Route
              path="/home/property/:id/inspection"
              element={
                <PrivateRoute>
                  <Inspection />
                </PrivateRoute>
              }
            />
            <Route
              path="/home/property/:id/inspection/:inspection/rooms"
              element={
                <PrivateRoute>
                  <Rooms />
                </PrivateRoute>
              }
            />
            <Route
              path="/home/property/:id/inspection/:inspection/rooms/select"
              element={
                <PrivateRoute allowedToBank={false}>
                  <SelectRooms />
                </PrivateRoute>
              }
            />
            <Route
              path="/home/property/:id/inspection/:inspection/room/:room/add-photos"
              element={
                <PrivateRoute allowedToBank={false}>
                  <InspectionAddPhotos />
                </PrivateRoute>
              }
            />
            <Route
              path="/home/property/:id/inspection/:inspection/room/:room/gallery"
              element={
                <PrivateRoute>
                  <InspectionPhotoGallery />
                </PrivateRoute>
              }
            />
            <Route
              path="/home/property/:id/inspection/:inspection/room/:room/image-details/new/:imageId"
              element={
                <PrivateRoute allowedToBank={false}>
                  <ImageDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/home/property/:id/inspection/:inspection/room/:room/image-details/:imageId"
              element={
                <PrivateRoute>
                  <ImageDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/home/property/:id/sample"
              element={
                <PrivateRoute>
                  <SampleCreation />
                </PrivateRoute>
              }
            />
            <Route
              path="/home/property/:id/report"
              element={
                <PrivateRoute>
                  <Report />
                </PrivateRoute>
              }
            />
            <Route
              path="/home/property/:id/report/:inspection/inspection-photos"
              element={
                <PrivateRoute allowedToBank={false}>
                  <InspectionPhotos />
                </PrivateRoute>
              }
            />
            <Route
              path="/home/property/:id/calculation"
              element={
                <PrivateRoute>
                  <Calculation />
                </PrivateRoute>
              }
            />
            <Route
              path="/home/property/:id/revision"
              element={
                <PrivateRoute>
                  <Revision />
                </PrivateRoute>
              }
            />

            <Route
              path="/home/property/:id/archived"
              element={
                <PrivateRoute>
                  <Archived />
                </PrivateRoute>
              }
            />
            <Route
              path="/archived"
              element={
                <PrivateRoute>
                  <ArchivedFiles />
                </PrivateRoute>
              }
            />
            <Route
              path="/adjustment"
              element={
                <PrivateRoute allowedToBank={false}>
                  <Adjustment />
                </PrivateRoute>
              }
            />
            <Route
              path="/users"
              element={
                <PrivateRoute>
                  <Users />
                </PrivateRoute>
              }
            />
            <Route
              path="/forms"
              element={
                <PrivateRoute allowedToBank>
                  <Forms />
                </PrivateRoute>
              }
            />
            <Route
              path="/forms/new"
              element={
                <PrivateRoute allowedToBank>
                  <FormCreation />
                </PrivateRoute>
              }
            />
            <Route
              path="/forms/:id/edit"
              element={
                <PrivateRoute allowedToBank>
                  <FormCreation />
                </PrivateRoute>
              }
            />
            <Route
              path="the-hive"
              element={
                <PrivateRoute allowedToUser={false}>
                  <Hive />
                </PrivateRoute>
              }
            >
              <Route path="" element={<Requests />} />
              <Route path="requests" element={<Requests />} />
              <Route path="licensed">
                <Route path="" element={<Licensed />} />
                <Route path=":notice" element={<NoticeDetails />} />
                <Route
                  path="company-details/:company"
                  element={<CompanyInfo />}
                />
              </Route>
              <Route path="notices">
                <Route path="" element={<Notices />} />
                <Route path="new" element={<NewNotice />} />
                <Route path=":notice" element={<NoticeDetails />} />
                <Route path=":notice/edit" element={<NewNotice />} />
                <Route
                  path=":notice/company-details/:company"
                  element={<CompanyInfo />}
                />
                <Route
                  path=":notice/edit/company-details/:company"
                  element={<CompanyInfo />}
                />
                <Route path=":notice/add-company" element={<AddCompany />} />
              </Route>
            </Route>
            <Route
              path="/user-management"
              element={
                <PrivateRoute allowedToBank={false}>
                  <UserManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="/error-report"
              element={
                <PrivateRoute allowedToBank={false}>
                  <ErrorReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/error-report/:id"
              element={
                <PrivateRoute allowedToBank={false}>
                  <ReportDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/agenda"
              element={
                <PrivateRoute allowedToBank={false}>
                  <Agenda />
                </PrivateRoute>
              }
            />
            <Route
              path="/notifications"
              element={
                <PrivateRoute>
                  <Notifications />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="/home/presentation-mode"
            element={
              <PrivateRoute allowedToBank={false}>
                <PresentationMode />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
