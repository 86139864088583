import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  PropertyType,
  RegistrationUf,
  Status,
  StatusCode,
  ufAbbreviation,
  ufAbbreviationToEnum,
} from '../../api/enumerations';
import { removeRequest } from '../../api/theHive/requests';
import {
  createWorkOrder,
  editWorkOrder,
  GetWorkOrder,
} from '../../api/workOrders';
import { WorkOrderData } from '../../api/workOrders/types';
import { GlobalContext } from '../../context/global';
import { validateFiles } from '../../helpers';
import { useAttachment } from '../../hooks/useAttachmentFiles';
import useErrorMessage from '../../hooks/useErrorMessage';
import useGeneral from '../../hooks/useGeneral';
import useSearchCep from '../../hooks/useSearchCep';

type LatLngLiteral = google.maps.LatLngLiteral;

interface UseEntranceHook {
  handleSubmit: (e: React.FormEvent) => Promise<void>;
  verifyCep: (value: string) => Promise<void>;
  handleFileUpload: (
    e: ChangeEvent<HTMLInputElement>,
    value: string
  ) => Promise<void>;
  handleRemoveRequest: () => Promise<void>;
  address: string;
  setAddress: (value: string) => void;
  addressNumber: string;
  setAddressNumber: (value: string) => void;
  age: number;
  setAge: (value: number) => void;
  buildingStandard: number;
  setBuildingStandard: (value: number) => void;
  builtArea: number;
  setBuiltArea: (value: number) => void;
  cep: string;
  setCep: (value: string) => void;
  cepError: boolean;
  city: string;
  setCity: (value: string) => void;
  client: string;
  setClient: (value: string) => void;
  clientType: number;
  setClientType: (value: number) => void;
  complement: string;
  setComplement: (value: string) => void;
  concept: number;
  setConcept: (value: number) => void;
  conservation: number;
  setConservation: (value: number) => void;
  district: string;
  setDistrict: (value: string) => void;
  evaluationType: number;
  setEvaluationType: (value: number) => void;
  goal: number;
  setGoal: (value: number) => void;
  judicialDistrict: string;
  setJudicialDistrict: (value: string) => void;
  parkingLots: number;
  setParkingLots: (value: number) => void;
  propertyFunction: number;
  setPropertyFunction: (value: number) => void;
  propertyType: number;
  setPropertyType: (value: number) => void;
  propertyUse: number;
  setPropertyUse: (value: number) => void;
  registerNumber: string;
  setRegisterNumber: (value: string) => void;
  registrationUf: number;
  setRegistrationUf: (value: number) => void;
  setRequiresInspection: (value: boolean) => void;
  rooms: number;
  setRooms: (value: number) => void;
  solicitor: string;
  setSolicitor: (value: string) => void;
  suites: number;
  setSuites: (value: number) => void;
  toilets: number;
  setToilets: (value: number) => void;
  totalArea: number;
  setTotalArea: (value: number) => void;
  uf: number;
  setUf: (value: number) => void;
  zone: number;
  setZone: (value: number) => void;
  propertyData: WorkOrderData | undefined;
  registerFileName: string;
  iptuFileName: string;
  createdAt: string;
  downloadIptu: string;
  downloadRegister: string;
  referenceNumber: number;
  newProperty: boolean;
  searchMap: boolean;
  setSearchMap: (value: boolean) => void;
  setIsAddressEdited: (value: boolean) => void;
  pinPlace: LatLngLiteral;
  setPinPlace: (value: LatLngLiteral) => void;
  submitLoading: boolean;
  removeRequestLoading: boolean;
}

const useEntrance = (): UseEntranceHook => {
  const [address, setAddress] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [age, setAge] = useState(0);
  const [buildingStandard, setBuildingStandard] = useState(0);
  const [builtArea, setBuiltArea] = useState(0);
  const [cep, setCep] = useState('');
  const [cepError, setCepError] = useState(false);
  const [city, setCity] = useState('');
  const [client, setClient] = useState('');
  const [clientType, setClientType] = useState(0);
  const [complement, setComplement] = useState('');
  const [concept, setConcept] = useState(0);
  const [conservation, setConservation] = useState(0);
  const [district, setDistrict] = useState('');
  const [evaluationType, setEvaluationType] = useState(0);
  const [goal, setGoal] = useState(0);
  const [iptuFile, setIptuFile] = useState<File | string>();
  const [judicialDistrict, setJudicialDistrict] = useState('');
  const [parkingLots, setParkingLots] = useState(0);
  const [propertyFunction, setPropertyFunction] = useState(0);
  const [propertyType, setPropertyType] = useState(0);
  const [propertyUse, setPropertyUse] = useState(0);
  const [registerFile, setRegisterFile] = useState<File | string>();
  const [registerNumber, setRegisterNumber] = useState('');
  const [registrationUf, setRegistrationUf] = useState(0);
  const [requiresInspection, setRequiresInspection] = useState(true);
  const [rooms, setRooms] = useState(0);
  const [solicitor, setSolicitor] = useState('');
  const [suites, setSuites] = useState(0);
  const [toilets, setToilets] = useState(0);
  const [totalArea, setTotalArea] = useState(0);
  const [uf, setUf] = useState<RegistrationUf>(0);
  const [zone, setZone] = useState(0);

  const [propertyData, setPropertyData] = useState<WorkOrderData>();
  const [registerFileName, setRegisterFileName] = useState('');
  const [iptuFileName, setIptuFileName] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [downloadIptu, setDownloadIptu] = useState('');
  const [downloadRegister, setDownloadRegister] = useState('');
  const [referenceNumber, setReferenceNumber] = useState(0);
  const [newProperty, setNewProperty] = useState(true);

  const [isAddressEdited, setIsAddressEdited] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [searchMap, setSearchMap] = useState(true);
  const [pinPlace, setPinPlace] = useState<LatLngLiteral>({
    lat: -23.56162,
    lng: -46.65591,
  });
  const [removeRequestLoading, setRemoveRequestLoading] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { iptuLink, setIptuLink, registerLink, setRegisterLink } =
    useAttachment();
  const { getErrorMessage } = useErrorMessage();
  const { osId, checkPathname, navigateHome } = useGeneral();
  const { handleSearchCep } = useSearchCep();

  const getDataCallback = useCallback(async () => {
    if (checkPathname('/edit')) {
      setNewProperty(false);
      try {
        const response = await GetWorkOrder(osId);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data || response.data.status !== Status.ENTRANCE) {
          throw new Error('Algo deu errado, tente novamente.');
        }
        setPropertyData(response.data);
        setAddress(response.data.street);
        setAddressNumber(response.data.number);
        setAge(response.data.age || 0);
        setBuildingStandard(response.data.constructive_standard || 0);
        setBuiltArea(response.data.constructed_area || 0);
        setCep(response.data.zip_code);
        setCity(response.data.city);
        setClient(response.data.client_name);
        setClientType(response.data.client_kind);
        setComplement(response.data.address_complement);
        setConcept(response.data.communities_kind);
        setConservation(response.data.preservation_state || 0);
        setDistrict(response.data.district);
        setEvaluationType(response.data.evaluation_type);
        setGoal(response.data.report_goal);
        setDownloadIptu(response.data.iptu_document || '');
        setJudicialDistrict(response.data.judicial_district);
        setParkingLots(response.data.parking_spaces || 0);
        setPropertyFunction(response.data.report_function);
        setPropertyType(response.data.real_estate_kind);
        setPropertyUse(response.data.use);
        setDownloadRegister(response.data.real_estate_registry || '');
        setRegisterNumber(response.data.registration_number);
        setRegistrationUf(response.data.uf);
        setRequiresInspection(response.data.requires_inspection);
        setRooms(response.data.bedrooms || 0);
        setSuites(response.data.suites || 0);
        setToilets(response.data.bathrooms || 0);
        setTotalArea(response.data.total_area);
        setUf(ufAbbreviationToEnum[response.data.state] ?? 0);
        setZone(response.data.zone);
        setReferenceNumber(response.data.reference_number);
        setCreatedAt(response.data.created_at);
        if (response.data.latitude && response.data.longitude) {
          setPinPlace({
            lat: Number(response.data.latitude),
            lng: Number(response.data.longitude),
          });
        }
        if (response.data.requester_name) {
          setSolicitor(response.data.requester_name);
        }
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error, true));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }
    } else {
      setNewProperty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iptuLink, registerLink]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback, iptuLink, registerLink]);

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>,
    fileType: string
  ): Promise<void> => {
    if (!e.target.files?.item(0) || !validateFiles(e.target.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem ou pdf');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const file = e.target.files[0];

    if (checkPathname('/edit')) {
      const formData = new FormData();
      if (fileType === 'register') {
        formData.append('real_estate_registry', file);
      }
      if (fileType === 'iptu') {
        formData.append('iptu_document', file);
      }

      try {
        const response = await editWorkOrder(osId, formData);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setSnackbarMessage('Dados alterados com sucesso!');
        setErrorMessage(false);
        setOpenSnackbar(true);
        if (fileType === 'register') {
          setRegisterLink(file.name);
        }
        if (fileType === 'iptu') {
          setIptuLink(file.name);
        }
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
      }
      return;
    }

    if (fileType === 'register') {
      setRegisterFileName(file.name);
      setRegisterFile(file);
    }
    if (fileType === 'iptu') {
      setIptuFileName(file.name);
      setIptuFile(file);
    }
  };

  const verifyCep = async (cep: string): Promise<void> => {
    const addressData = await handleSearchCep(cep);
    if (addressData) {
      setCep(addressData.cep);
      setUf(ufAbbreviationToEnum[addressData.uf] ?? 0);
      setCity(addressData.city);
      setDistrict(addressData.district);
      setAddress(addressData.address);
      setCepError(false);
    } else {
      setCepError(true);
    }
    setIsAddressEdited(true);
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    if (cepError) {
      setSnackbarMessage('Adicione um CEP válido.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (isAddressEdited) {
      setSnackbarMessage('Pesquise a localização no mapa antes de salvar.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (
      propertyType !== PropertyType.LOT &&
      (buildingStandard === 0 || conservation === 0)
    ) {
      setSnackbarMessage('Os dados de seleção são obrigatórios.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (
      clientType === 0 ||
      concept === 0 ||
      evaluationType === 0 ||
      goal === 0 ||
      propertyFunction === 0 ||
      propertyType === 0 ||
      propertyUse === 0 ||
      registrationUf === 0 ||
      uf === 0
    ) {
      setSnackbarMessage('Os dados de seleção são obrigatórios.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const formData = new FormData();
    formData.append('street', address);
    formData.append('number', addressNumber);
    formData.append('zip_code', cep);
    formData.append('city', city);
    formData.append('client_name', client);
    formData.append('client_kind', clientType.toString());
    formData.append('address_complement', complement);
    formData.append('communities_kind', concept.toString());
    formData.append('district', district);
    formData.append('evaluation_type', evaluationType.toString());
    formData.append('report_goal', goal.toString());
    formData.append('judicial_district', judicialDistrict);
    formData.append('report_function', propertyFunction.toString());
    formData.append('real_estate_kind', propertyType.toString());
    formData.append('use', propertyUse.toString());
    formData.append('registration_number', registerNumber);
    formData.append('requester_name', solicitor);
    formData.append('uf', registrationUf.toString());
    formData.append('requires_inspection', requiresInspection.toString());
    formData.append('total_area', totalArea.toString());
    formData.append('state', ufAbbreviation[uf]);
    formData.append('zone', zone.toString());
    formData.append('latitude', pinPlace.lat.toString());
    formData.append('longitude', pinPlace.lng.toString());

    if (propertyType !== PropertyType.LOT) {
      formData.append('constructed_area', builtArea.toString());
      formData.append('bedrooms', rooms.toString());
      formData.append('bathrooms', toilets.toString());
      formData.append('suites', suites.toString());
      formData.append('parking_spaces', parkingLots.toString());
      formData.append('age', age.toString());
      formData.append('constructive_standard', buildingStandard.toString());
      formData.append('preservation_state', conservation.toString());
    }

    if (registerFile) {
      formData.append('real_estate_registry', registerFile);
    }
    if (iptuFile) {
      formData.append('iptu_document', iptuFile);
    }

    setSubmitLoading(true);

    if (checkPathname('/edit')) {
      try {
        const response = await editWorkOrder(osId, formData);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setSnackbarMessage('Dados alterados com sucesso!');
        setErrorMessage(false);
        setOpenSnackbar(true);
        setSubmitLoading(false);
        navigateHome();
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
        setSubmitLoading(false);
      }
    } else {
      try {
        const response = await createWorkOrder(formData);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        setSnackbarMessage(
          `Imóvel cadastrado com sucesso! Número de OS ${response.data?.reference_number}`
        );
        setErrorMessage(false);
        setOpenSnackbar(true);
        setSubmitLoading(false);
        navigateHome();
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setErrorMessage(true);
        setOpenSnackbar(true);
        setSubmitLoading(false);
      }
    }
  };

  const handleRemoveRequest = useCallback(async () => {
    const requestId = propertyData?.hive_request?.id;
    if (!requestId) {
      setSnackbarMessage('Algo deu errado, tente novamente.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    setRemoveRequestLoading(true);

    try {
      const response = await removeRequest(requestId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }
      setSnackbarMessage('Atribuição removida com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      navigateHome();
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setRemoveRequestLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyData]);

  return {
    handleSubmit,
    verifyCep,
    handleFileUpload,
    handleRemoveRequest,
    address,
    setAddress,
    addressNumber,
    setAddressNumber,
    age,
    setAge,
    buildingStandard,
    setBuildingStandard,
    builtArea,
    setBuiltArea,
    cep,
    setCep,
    cepError,
    city,
    setCity,
    client,
    setClient,
    clientType,
    setClientType,
    complement,
    setComplement,
    concept,
    setConcept,
    conservation,
    setConservation,
    district,
    setDistrict,
    evaluationType,
    setEvaluationType,
    goal,
    setGoal,
    judicialDistrict,
    setJudicialDistrict,
    parkingLots,
    setParkingLots,
    propertyFunction,
    setPropertyFunction,
    propertyType,
    setPropertyType,
    propertyUse,
    setPropertyUse,
    registerNumber,
    setRegisterNumber,
    registrationUf,
    setRegistrationUf,
    setRequiresInspection,
    rooms,
    setRooms,
    solicitor,
    setSolicitor,
    suites,
    setSuites,
    toilets,
    setToilets,
    totalArea,
    setTotalArea,
    uf,
    setUf,
    zone,
    setZone,
    propertyData,
    registerFileName,
    iptuFileName,
    createdAt,
    downloadIptu,
    downloadRegister,
    referenceNumber,
    newProperty,
    searchMap,
    setSearchMap,
    setIsAddressEdited,
    pinPlace,
    setPinPlace,
    submitLoading,
    removeRequestLoading,
  };
};

export default useEntrance;
